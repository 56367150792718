import { useMemo } from 'react'
import { getCookie } from 'utils/client/cookie'
import { getGETQueries } from 'utils/client/url'
/**
 * @type {Object.<string, PromoEvent>}
 */
const promoEvents = process.env.localization.promoEvents

/**
 * Возвращает текущее промо событие
 * @param locale
 * @returns {PromoEvent|undefined}
 */
export default function useCurrentPromoEvent(locale) {
  const isWindowDefined = typeof window !== 'undefined'
  return useMemo(
    () => {
      const query = isWindowDefined ? getGETQueries() : {}
      const promo_test = getCookie('promo_test') || query.promo_test
      return Object.values(promoEvents).find(event => {
        if (promo_test) {
          return event.code.toLowerCase() === promo_test.toLowerCase() && event.countries.includes(locale)
        } else {
          const start = new Date(event.schedule.start)
          const end = new Date(event.schedule.end)
          const now = new Date()
          return start <= now && now <= end && event.countries.includes(locale)
        }
      })
    },
    [locale, isWindowDefined],
  )
}
