import { em, math } from 'polished'
import styled, { css } from 'styled-components'

import { TextCartDescr } from 'styles/components/typography'

const verticalInnerPadding = ({
  theme: {
    typography: {
      textMediumMd,
      textMediumLh,
      textSmallDescrMd,
      textSmallDescrLh,
    },
  },
}) => {
  const heightOfLineCartTextMd = math(`${textMediumMd} * ${textMediumLh}`)
  const heightOfLineDiscountTextMd = math(
    `${textSmallDescrMd} * ${textSmallDescrLh}`,
  )

  return math(
    `(${heightOfLineCartTextMd} - ${heightOfLineDiscountTextMd}) * 0.35`,
  )
}

const labelWithFilling = css`
  color: ${p => p.theme.colors.white};
  background: ${p => p.theme.colors.grass};
  border-radius: ${p => p.theme.blocks.blockBorderRadius};
  padding: ${p => math(`${verticalInnerPadding(p)} * 0.54`)} ${em(10)}
    ${verticalInnerPadding};
`

const LabelString = styled.span`
  line-height: ${p => p.theme.typography.textSmallDescrLh};
`

export const Value = styled(LabelString)`
  &:before {
    content: '-';
    display: inline;
  }

  ${p => p.theme.mq.upLg} {
    &:before {
      content: '${props => (props.isShortLabel ? '-' : '')}';
    }
  }
`

export const Text = styled(LabelString)`
  display: none;

  ${p => p.theme.mq.upLg} {
    display: ${props => (props.isShortLabel ? 'none' : 'inline')};
  }
`

export const Box = styled(TextCartDescr)`
  position: relative;
  top: ${p => (p.isWithoutShift ? 0 : '-0.5em')};
  display: inline-block;
  color: ${p => p.theme.colors.grass};
  vertical-align: top;
  line-height: 0;
  white-space: nowrap;
  ${p => p.isWithFilling && labelWithFilling}

  ${p => p.theme.mq.upMd} {
    position: static;
    ${labelWithFilling}
  }
`
