import { em, math } from 'polished'
import styled from 'styled-components'

import { H4 } from 'styles/components/typography'

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${p => p.theme.mq.upMd} {
    max-width: ${em(324)};
  }

  ${p => p.theme.mq.upLg} {
    max-width: none;
  }
`

export const ContentWrapper = styled.div`
  width: 100%;
  padding-top: ${em(40)};

  ${p => p.theme.mq.downMd} {
    margin-left: -${p => math(`${p.theme.indents.baseIndentMd}/2`)};
    margin-right: -${p => math(`${p.theme.indents.baseIndentMd}/2`)};
  }
`

export const H4Styled = styled(H4)`
  max-width: ${p => em(170, p.theme.typography.h4Px)};
  height: ${p => p.theme.typography.h4Lh * 2}em;
`

export const TitleAndCost = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ControlsAndButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -${em(30)};
`

export const ControlsAndButtonContent = styled.div`
  margin-bottom: ${em(30)};
  line-height: 0;

  ${props =>
    props.isFirst &&
    `
    margin-right: ${em(30)};
  `}
`
