import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'react-final-form'

import TextFieldRoundedInner from './TextFieldRoundedInner'

const TextFieldRounded = props => {
  const { name, ...remainingProps } = props

  return (
    <Field
      {...remainingProps}
      name={name}
      type={remainingProps.type}
      component={TextFieldRoundedInner}
    />
  )
}

TextFieldRounded.propTypes = {
  /** Name of input */
  name: PropTypes.string.isRequired,

  /** Force error text when there is error from server */
  forceSubmitError: PropTypes.string,

  /** Custom placeholder for input tag with custom behavior (moving up) */
  placeholder: PropTypes.string,

  /** Error messages from server for whole form */
  submitErrors: PropTypes.array,

  /** Type of html input tag */
  type: PropTypes.oneOf(['text', 'number', 'tel', 'email']),

  /** Function to implement rules. It has to return error message. You may read about it in Introduction article */
  validate: PropTypes.func,

  /** Flag to hide bottom indent for error */
  withoutErrorIndent: PropTypes.bool,

  /** Is input disabled */
  isDisabled: PropTypes.bool,
}

TextFieldRounded.defaultProps = {
  forceSubmitError: '',
  type: 'text',
  withoutErrorIndent: false,
}

export default TextFieldRounded
