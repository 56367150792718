import styled from 'styled-components'
import { em } from 'polished'

import { Text } from 'shared/Typography'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${em(50)} ${em(20)} ${em(0)};
  height: 100%;

  ${({ theme }) => theme.mq.upMd} {
    max-width: ${em(760)};
    padding: ${em(50)} ${em(60)} ${em(0)};
  }
`

export const Title = styled(Text)`
  margin-bottom: ${em(15, 22)};

  span {
    color: var(--color-P50);
  }

  ${({ theme }) => theme.mq.upMd} {
    margin-bottom: ${em(20, 35)};
  }
`

export const Description = styled(Text)`

  ${({ theme }) => theme.mq.upMd} {
  }
`

export const Image = styled.img`
  max-width: 100%;
  margin-top: auto;

  ${({ theme }) => theme.mq.upMd} {
    max-width: ${em(500)};
    align-self: flex-end;
    margin-right: -30px;
    margin-top: 0;
  }
`
