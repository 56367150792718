import styled from 'styled-components'
import { em } from 'polished'

import { Text } from 'shared/Typography'

export const Container = styled.a`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${em(20)} ${em(20)} ${em(50)};
  border-radius: 15px;
  background-color: var(--color-W100);
  cursor: pointer;
  ${({ theme }) => theme.mq.upMd} {
    padding: ${em(10)} ${em(10)} ${em(20)};
  }
`

export const ImageContainer = styled.div`
  position: relative;
  margin-bottom: ${em(20)};

  ${({ theme }) => theme.mq.upMd} {
    margin-bottom: ${em(10)};
  }
`

export const Image = styled.img`
  display: block;
  width: 100%;
  border-radius: ${em(10)} ${em(10)} ${em(10)} 0px;
`

export const ImageText = styled(Text)`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: ${em(4, 11)} ${em(15, 11)};
  background-color: var(--color-D100);
  color: var(--color-W100);
  font-size: ${em(11)};
  line-height: ${em(22, 11)};
  letter-spacing: 2px;

  ${({ theme }) => theme.mq.upMd} {
    font-size: ${em(8)};
    line-height: ${em(22, 8)};
    padding: ${em(0)} ${em(5, 8)};
  }
`

export const Title = styled(Text)`
  margin-bottom: ${em(15, 22)};
  color: var(--color-D100);

  ${({ theme }) => theme.mq.upMd} {
    margin-bottom: ${em(10, 18)};
    line-height: ${em(22, 18)};
  }
`

export const DateAndTime = styled(Text)`
  color: var(--color-D50);

  span {
    display: inline-block;
    margin-left: ${em(5)};
    margin-right: ${em(5)};
  }

  ${({ theme }) => theme.mq.upMd} {
    max-width: ${em(368, 18)};
    font-size: ${em(14)};
    line-height: ${em(22, 14)};
  }
`

export const Views = styled(Text)`
  position: absolute;
  bottom: -30px;
  left: 0;
  display: flex;
  align-items: center;
  gap: ${em(5)};
  color: var(--color-W30);

  ${({ theme }) => theme.mq.upMd} {
  }
`
