import PropTypes from 'prop-types'
import React from 'react'

import useInViewCommon from 'hooks/useInViewCommon'
import { BoxBase } from 'styles/components/layout'
import { Text } from 'styles/components/typography'

import { Content, BoxText, DynamicImageStyled, IconImg } from './styles'

const BannerSimple = ({ text, icon, img }) => {
  const [ref, inView] = useInViewCommon()

  return (
    <BoxBase color={'white'} ref={ref}>
      <Content>
        <BoxText>
          <Text dangerouslySetInnerHTML={{ __html: text }} />
        </BoxText>
        {inView && icon && <IconImg src={icon} />}
        {inView && img && (
          <DynamicImageStyled
            imageSizes={{
              xs: 499,
              md: 525,
            }}
            image={img}
            imageType={'png'}
            alt={"distributer's logo"}
          />
        )}
      </Content>
    </BoxBase>
  )
}

BannerSimple.propTypes = {
  text: PropTypes.node.isRequired,
  icon: PropTypes.string,
  img: PropTypes.string,
}

export default React.memo(BannerSimple)
