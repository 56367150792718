import { em } from 'polished'
import styled, { css } from 'styled-components'

import { Bold, Text } from 'styles/components/typography'
import { h2, textBaseMd } from 'styles/mixins_styled/typography'
import { Text as UIText } from 'shared/Typography'

export const TextP = styled.p`
  ${h2};
  min-height: ${p => p.theme.typography.h2Lh * 5}em;
  word-break: break-word;
  color: ${p => (p.isTextWhite ? p.theme.colors.white : null)};

  ${p => p.theme.mq.upMd} {
    min-height: 0;
    color: ${p => (p.isTextWhite ? p.theme.colors.whiteOp7 : null)};
    ${textBaseMd};
    margin-bottom: 0!important;
  }
`

export const Article = styled.article`
  display: block;
  position: relative;
  padding: 0 ${p => p.theme.indents.baseIndentXs};

  ${p => p.theme.mq.upMd} {
    flex-grow: 0;
    flex-shrink: 1;
    padding: ${p => p.theme.indents.baseIndentXs};
  }

  ${p => p.theme.mq.upLg} {
    flex-grow: 1;
    flex-basis: 0;
    &:not(:last-child) {
      border-right: 1px solid ${p => p.theme.colors.blackOp07};
    }
  }


  ${({ itemsTotal }) =>
    itemsTotal === 3 &&
    css`
      ${p => p.theme.mq.upMd} {
        flex-basis: auto;
        width: calc(100% / 3);
        &:not(:last-child) {
          border-right: 1px solid ${p => p.theme.colors.blackOp07};
        }
      }
    `}

  ${({ itemsTotal }) =>
    itemsTotal % 4 === 0 &&
    css`
      ${p => p.theme.mq.upMd} {
        flex-basis: calc(100% / 2);
        border-bottom: 1px solid ${p => p.theme.colors.blackOp07};
        &:not(:nth-child(2n)) {
          border-right: 1px solid ${p => p.theme.colors.blackOp07};
        }
        &:last-child,
        &:nth-last-child(2) {
          border-bottom: none;
        }
      }
      ${p => p.theme.mq.upLg} {
        border-bottom: none !important;
      }
    `}

  ${({ itemsTotal }) =>
    itemsTotal % 5 === 0 &&
    css`
      ${p => p.theme.mq.upMd} {
        flex-basis: calc(100% / 3);
        &:not(:nth-child(3n)) {
          border-right: 1px solid ${p => p.theme.colors.blackOp07};
        }
        &:not(:nth-child(n + 4)) {
          border-bottom: 1px solid ${p => p.theme.colors.blackOp07};
        }
      }

      ${p => p.theme.mq.upLg} {
        border-bottom: none !important;
        &:last-child {
          border-right: none;
        }
      }
    `}

  ${({ itemsTotal }) =>
    itemsTotal === 6 &&
    css`
      ${p => p.theme.mq.upMd} {
        flex-basis: auto;
        width: calc(100% / 3);
        &:not(:nth-child(3n)) {
          border-right: 1px solid ${p => p.theme.colors.whiteOp05};
        }
        &:not(:nth-last-child(-n + 3)) {
          border-bottom: 1px solid ${p => p.theme.colors.whiteOp05};
        }
        &:not(:nth-child(n + 3)) {
          padding-bottom: ${p => p.theme.indents.baseIndentXs};
        }
        &:nth-child(n + 3) {
          padding-top: ${p => p.theme.indents.baseIndentXs};
        }
      }
    `}
`

export const TextStyled = styled(Text)`
  text-transform: uppercase;
  color: ${p =>
    p.isTextWhite ? p.theme.colors.whiteOp4 : p.theme.colors.muted};
`

export const BoldStyled = styled(Bold)`
  display: none;

  ${p => p.theme.mq.upMd} {
    display: block;
    color: ${p => (p.isTextWhite ? p.theme.colors.white : null)};
    margin-bottom: ${em(12)};
  }
`

export const IconWrapper = styled.div`
  width: ${em(110)};
  height: ${em(79)};

  ${p => p.theme.mq.upMd} {
    width: ${em(100)};
    height: ${em(72)};
  }
`

export const ImageIcon = styled.img`
  margin-bottom: ${em(8)};
  height: ${em(79)};

  ${p => p.theme.mq.upMd} {
    height: ${em(93)};
  }
`

export const ReportsCount = styled(UIText)`
  display: none;
  ${p => p.theme.mq.upMd} {
    display: block;
  }
`
