import React from 'react'

import BannerSimple from 'components/_special/BannerSimple'
import { IT, TR } from 'constants/countries'
import { LocalizationContext } from 'contexts/Localization'

const PARTNERS_LOGOS = (paths, locale) =>
  ({
    [IT]: { icon: `${paths.cdn_global}/distribution/biorep_italy.svg` },
    [TR]: { img: `${paths.cdn_global}/distribution/gentest_institute_turkey` },
  }[locale])

const DistributionBannerContainer = () => {
  const { dict, paths, locale } = React.useContext(
    LocalizationContext,
  )

  if (![IT, TR].includes(locale)) {
    return null
  }

  return (
    <BannerSimple
      text={dict.distribution_banner}
      {...PARTNERS_LOGOS(paths, locale)}
    />
  )
}

export default React.memo(DistributionBannerContainer)
