import PropTypes from 'prop-types'
import React from 'react'

import { Container, Title, Description } from './styles'

function CardAdvertTop10({
  // title,
  // description,
  // reportText,
  // icon,
}) {
  const title = 'Top-10 superfoods to make your gut bugs happy'
  const description = 'Receive a list of best foods to nourish your gut weekly'
  return (
    <Container>
      <Title
        size={['35/40', '35/40', '25/30']}
        weight={'medium'}
        dangerouslySetInnerHTML={{ __html: title }}
      />

      <Description
        size={['18/24', '18/24', '16/20']}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </Container>
  )
}

CardAdvertTop10.propTypes = {
  // title: PropTypes.string.isRequired,
  // description: PropTypes.string.isRequired,
  // reportText: PropTypes.string.isRequired,
  // icon: PropTypes.string.isRequired,
}

export default CardAdvertTop10
