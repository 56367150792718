import styled, { css } from 'styled-components'
import { em } from 'polished'

const backgrounds = {
  'primary_black': 'var(--color-D100)',
  'primary_white': 'var(--color-W100)',
  'primary_violet': 'var(--color-V50)',
  'outlined_black': 'transparent',
  'outlined_white': 'transparent',
  'outlined_violet': 'transparent',
}

const disabledBackgrounds = {
  'primary_black': 'var(--color-G100)',
  'primary_white': 'var(--color-W100)',
  'primary_violet': 'var(--color-V100)',
  'outlined_black': 'transparent',
  'outlined_white': 'transparent',
  'outlined_violet': 'transparent',
  'text_black': 'transparent',
  'text_white': 'transparent',
  'text_violet': 'transparent',
}

const colors = {
  'primary_black': 'var(--color-W100)',
  'primary_white': 'var(--color-D100)',
  'primary_violet': 'var(--color-W100)',
  'outlined_black': 'var(--color-D100)',
  'outlined_white': 'var(--color-W100)',
  'outlined_violet': 'var(--color-D100)',
  'text_black': 'var(--color-D100)',
  'text_white': 'var(--color-D100)',
  'text_violet': 'var(--color-D100)',
}

const borderColors = {
  'outlined_black': 'var(--color-D100)',
  'outlined_white': 'var(--color-W30)',
  'outlined_violet': 'var(--color-D100)',
}

const hoverColors = {
  'primary_black': 'var(--color-W50)',
  'primary_white': 'var(--color-D50)',
  'primary_violet': 'var(--color-W50)',
  'outlined_black': 'var(--color-D50)',
  'outlined_white': 'var(--color-W50)',
  'outlined_violet': 'var(--color-D50)',
  'text_black': 'var(--color-D50)',
  'text_white': 'var(--color-D50)',
  'text_violet': 'var(--color-D50)',
}

const disabledColors = {
  'primary_black': 'var(--color-D30)',
  'primary_white': 'var(--color-D30)',
  'primary_violet': 'var(--color-W30)',
  'outlined_black': 'var(--color-D30)',
  'outlined_white': 'var(--color-D30)',
  'outlined_violet': 'var(--color-D30)',
  'text_black': 'var(--color-D30)',
  'text_white': 'var(--color-D30)',
  'text_violet': 'var(--color-D30)',
}

export const Button = styled.button`
  &.button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${p => p.block ? '100%' : 'auto'};
    height: ${({ size }) => size === 'desktop' ? `${em(50)}` : `${em(40)}`};
    padding: ${({ size }) => size === 'desktop' ? `0 ${em(30)}` : `0 ${em(15)}`};
    font-family: ${p => p.theme.typography.baseFont};
    font-size: ${em(16)};
    font-weight: ${({ variant }) => variant === 'text' ? 500 : 400};
    white-space: nowrap;
    background-color: ${p => backgrounds[`${p.variant}_${p.color}`]};
    border-radius: 100px;
    color: ${p => colors[`${p.variant}_${p.color}`]} !important;
    transition: all .2s ease-in-out;

    &.outlined {
      background-color: transparent;
      border-width: 1px;
      border-style: solid;
      border-color: ${p => borderColors[`${p.variant}_${p.color}`]};
    }

    &:not(.disabled):hover {
      color: ${p => hoverColors[`${p.variant}_${p.color}`]} !important;
      transition: all 0.2s ease-in;
    }

    &.disabled {
      background-color: ${p => disabledBackgrounds[`${p.variant}_${p.color}`]};
      color: ${p => disabledColors[`${p.variant}_${p.color}`]} !important;
      cursor: default;
      border-color: ${({ variant }) => variant === 'outlined' ? 'var(--color-D30)' : 'none'};
    }

    &.loading {
      position: relative;
      cursor: default;

      span:first-child {
        visibility: collapse;
      }

      span:last-child {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      ${({ color, variant }) =>
        (
          color === 'white' || variant === 'outlined' || variant === 'text') && css`
          svg {
            color: var(--color-D10);
            fill: var(--color-D100);
          }
        `}
    }
`

export const Link = styled.a`
  display: inline-block;
`
