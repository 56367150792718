import PropTypes from 'prop-types'
import React from 'react'
import { ThemeContext } from 'styled-components'

import ButtonRoundedBase from 'components/ButtonRoundedBase'

import {
  decreaseWidthToLeftAndHideText,
  decreaseWidthToRightAndHideText,
  decreaseWidthToCenterAndHideText,
  showLoader,
  showLoaderProcess,
  hideLoader,
  showSuccessIcon,
  showFailedIcon,
} from './animations'

const ANIM_LEFT = 'left'
const ANIM_CENTER = 'center'
const ANIM_RIGHT = 'right'

const Preloader = React.forwardRef((styles, ref) => (
  <svg
    style={{
      display: 'block',
      ...styles,
    }}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="-60 -60 320 320"
  >
    <path
      fill="none"
      stroke="#FFFFFF"
      strokeWidth="8"
      strokeOpacity=".2"
      strokeMiterlimit="10"
      d="M100.416,2.001C154.35,2.225,198,46.015,198,100c0,54.124-43.876,98-98,98S2,154.124,2,100S45.876,2,100,2"
    />
    <path
      ref={ref}
      className="path"
      fill="none"
      stroke="#FFFFFF"
      strokeWidth="15"
      strokeMiterlimit="10"
      strokeDasharray="1000"
      strokeDashoffset="1000"
      d="M100.416,2.001C154.35,2.225,198,46.015,198,100c0,54.124-43.876,98-98,98S2,154.124,2,100S45.876,2,100,2"
    />
  </svg>
))

/**
 * Component of Button with rounded corners
 * @param {object} props — properties of Component
 * @return {Component} Component — ButtonAsyncAction
 */
const ButtonAsyncActionSt = ({
  isLoading,
  isSucceed,
  animAlign,
  onClick,
  children,
  successCb,
  failureCb,
  isDisabled,
  bgClassName,
  blackoutClassName,
  blackoutColor,
  wrapperClassName,
  size,
  className,
}) => {
  const loaderRef = React.useRef(null)
  const loadingTimeline = React.useRef(null)

  const {
    buttons: { hActionBtnLarge, hActionBtnMedium, hActionBtn, hActionBtnSmall },
  } = React.useContext(ThemeContext)

  const btnSize = React.useMemo(
    () =>
      size === 'large'
        ? hActionBtnLarge
        : size === 'medium'
        ? hActionBtnMedium
        : size === 'small'
        ? hActionBtnSmall
        : hActionBtn,
    [size, hActionBtn, hActionBtnSmall, hActionBtnMedium, hActionBtnLarge],
  )

  const [alignedAnimation, setAlignedAnimation] = React.useState(() => ({
    decrease:
      animAlign === ANIM_CENTER
        ? decreaseWidthToCenterAndHideText(blackoutColor)
        : animAlign === ANIM_RIGHT
        ? decreaseWidthToRightAndHideText(blackoutColor)
        : decreaseWidthToLeftAndHideText(blackoutColor),
  }))
  const [prevLoading, setPrevLoading] = React.useState(false)
  /*
   * Different conditions:
   * 0 — awaiting of first click
   * 1 — decreasing animation
   * 2 — (content changed) loader appearance
   * 3 — loader
   * 4 - loader hiding
   * 5 — async action status
   */
  const [condition, setCondition] = React.useState(0)

  const loadingCb = React.useCallback(() => {
    setCondition(4)
  }, [])

  React.useEffect(() => {
    if (isLoading) {
      setCondition(prevCondition => (!prevCondition ? 1 : prevCondition))
    } else {
      if (loadingTimeline.current) {
        // If response comes from server we set here a duration to end loading state
        loadingTimeline.current
          .duration(0.6)
          .eventCallback('onComplete', () => {
            loadingCb()
          })
      }
    }

    setPrevLoading(isLoading)
  }, [isLoading, loadingCb])

  React.useEffect(() => {
    setAlignedAnimation({
      decrease:
        animAlign === ANIM_CENTER
          ? decreaseWidthToCenterAndHideText(blackoutColor)
          : animAlign === ANIM_RIGHT
          ? decreaseWidthToRightAndHideText(blackoutColor)
          : decreaseWidthToLeftAndHideText(blackoutColor),
    })
  }, [animAlign, blackoutColor])

  React.useEffect(() => {
    return () => {
      if (loadingTimeline.current) {
        loadingTimeline.current.kill()
        loadingTimeline.current = null
      }
    }
  }, [])

  React.useEffect(() => {
    // Async action ended or
    // Async action ended during loader appearance animation
    // (we already have prevLoading - false and is getting condition 3 right now)
    if (!prevLoading && condition === 3) {
      if (loadingTimeline.current) {
        // If response comes from server we set here a duration to end loading state
        loadingTimeline.current
          .duration(0.6)
          .eventCallback('onComplete', () => {
            loadingCb()
          })
      }
    }
  }, [prevLoading, condition, loadingCb])

  const clickAction = React.useCallback(() => {
    let asyncStart = true

    if (onClick && typeof onClick === 'function') {
      asyncStart = onClick()
    }

    if (asyncStart) {
      setCondition(1)
    }
  }, [onClick])

  const decreasingCb = React.useCallback(() => {
    setCondition(2)
  }, [])

  const appearanceCb = React.useCallback(() => {
    loadingTimeline.current = showLoaderProcess(loaderRef.current)

    setCondition(3)
  }, [])

  const fadeoutCb = React.useCallback(() => {
    setCondition(5)
  }, [])

  const preloaderStyles = React.useMemo(
    () =>
      animAlign === 'left'
        ? {
            width: btnSize,
            height: btnSize,
          }
        : {},
    [animAlign, btnSize],
  )

  const btnProps = {
    as: 'button',
    size,
    wrapperClassName: `${wrapperClassName} ${className}`,
    wrapperProps: {},
    notHoverable: true,
    isDisabled,
  }

  switch (condition) {
    case 0: {
      btnProps.wrapperProps.onClick = clickAction
      btnProps.notHoverable = false
      if (bgClassName) {
        btnProps.bgClassName = 'bg--black'
      }
      if (blackoutClassName) {
        btnProps.blackoutClassName = 'bg--purple'
      }
      break
    }
    case 1: {
      btnProps.animTimeline = alignedAnimation.decrease
      btnProps.animTimelineCb = decreasingCb
      break
    }
    case 2: {
      btnProps.animTimeline = showLoader
      btnProps.animTimelineCb = appearanceCb
      break
    }
    case 4: {
      btnProps.animTimeline = hideLoader
      btnProps.animTimelineCb = fadeoutCb
      break
    }
    case 5: {
      if (isSucceed) {
        btnProps.animTimeline = showSuccessIcon
        btnProps.animTimelineCb = successCb
        btnProps.icon = 'btn_check'
        btnProps.iconColor = 'white'
      } else {
        btnProps.animTimeline = showFailedIcon
        btnProps.animTimelineCb = failureCb
        btnProps.icon = 'btn_close'
        btnProps.iconColor = 'white'
      }
      break
    }
  }

  return (
    <ButtonRoundedBase {...btnProps}>
      {condition > 1 && condition < 5 ? (
        <Preloader {...{ ref: loaderRef, styles: preloaderStyles }} />
      ) : condition === 5 ? null : (
        children
      )}
    </ButtonRoundedBase>
  )
}

ButtonAsyncActionSt.propTypes = {
  /** Align of button's animation */
  animAlign: PropTypes.oneOf([ANIM_LEFT, ANIM_CENTER, ANIM_RIGHT]),

  /** Title of button */
  children: PropTypes.node,

  /** Callback when failed animation ended */
  failureCb: PropTypes.func,

  /** Flag to control disable state */
  isDisabled: PropTypes.bool,

  /** Flag for server action state */
  isLoading: PropTypes.bool,

  /** Flag to show success of async action */
  isSucceed: PropTypes.bool,

  /** Callback when successful animation ended */
  successCb: PropTypes.func,

  /** Click on the button callback */
  onClick: PropTypes.func,

  /** Button background class name */
  bgClassName: PropTypes.string,

  /** ClassName with color for blackout tag */
  blackoutClassName: PropTypes.string,

  blackoutColor: PropTypes.string,

  /** Size of component */
  size: PropTypes.oneOf(['slim', 'base', 'medium', 'large']),

  /** Class names wrapper component */
  wrapperClassName: PropTypes.string,

  /** Class name */
  className: PropTypes.string,
}

ButtonAsyncActionSt.defaultProps = {
  isLoading: false,
  animAlign: ANIM_LEFT,
  isDisabled: false,
}

export default ButtonAsyncActionSt
