import { css } from 'styled-components'

/*
 * There are some specific for an util's logic:
 * 1. we have to render &format=webp|originl depends on is webp available for the browser
 * - otherwise cloudflare cache may cache wrong format for one url: webp for safari — it's fail
 * - so different "format" flags means different urls for cache
 * 2. RIGHT WAY: we have to compile styles with and without webp
 * and use right one in depends on some global class: webp for example
 * 3. WRONG way: we could choose right value for "format" flag programmatically — it's WRONG decision.
 * Availability of webp is known only on the client side, so our condition will compile
 * different results for class on server and client sides.
 * In this case we will get error like: "Prop `className` did not match.
 * Server: "styles__PersonBox-sc-1opbnoi-4 cugDeB" Client: "styles__PersonBox-sc-1opbnoi-4 igrozt""
 */

const viewports = {
  xs: '',
  md: 'upMd',
  lg: 'upLg',
}

function getImgUrl({
  imageType,
  image,
  breakpoint,
  breakpoints,
  namesByBreakpoints,
  isRetina = false,
  isWebp = false,
  compression = {},
}) {
  const k = isRetina ? 2 : 1
  const postfix = namesByBreakpoints ? `_${breakpoint}` : ''
  const format = isWebp ? 'webp' : 'original'
  const completeCompression = { xs: 80, md: 80, lg: 80, ...compression }
  const url = `${image}${postfix}.${imageType}?w=${breakpoints[breakpoint] *
    k}&format=${format}&q=${completeCompression[breakpoint]}`

  return `url(${url})`
}

export const setBackground = (
  imageType,
  image,
  breakpoints,
  namesByBreakpoints = true,
  compression = {},
) => {
  const urlParams = { imageType, image, breakpoints }

  return Object.keys(breakpoints).map(breakpoint => {
    if (breakpoint in viewports && !!viewports[breakpoint]) {
      return css`
        ${p => p.theme.mq[viewports[breakpoint]]} {
          background-image: ${getImgUrl({
            ...urlParams,
            breakpoint,
            namesByBreakpoints,
            compression,
          })};

          .webp & {
            background-image: ${getImgUrl({
              ...urlParams,
              breakpoint,
              namesByBreakpoints,
              isWebp: true,
              compression,
            })};
          }

          ${p => p.theme.mq.isRetina} {
            background-image: ${getImgUrl({
              ...urlParams,
              breakpoint,
              namesByBreakpoints,
              isRetina: true,
              compression,
            })};

            .webp & {
              background-image: ${getImgUrl({
                ...urlParams,
                breakpoint,
                namesByBreakpoints,
                isRetina: true,
                isWebp: true,
                compression,
              })};
            }
          }
        }
      `
    }

    return css`
      background-image: ${getImgUrl({
        ...urlParams,
        breakpoint,
        namesByBreakpoints,
        compression,
      })};

      .webp & {
        background-image: ${getImgUrl({
          ...urlParams,
          breakpoint,
          namesByBreakpoints,
          isWebp: true,
          compression,
        })};
      }

      ${p => p.theme.mq.isRetina} {
        background-image: ${getImgUrl({
          ...urlParams,
          breakpoint,
          namesByBreakpoints,
          isRetina: true,
          compression,
        })};

        .webp & {
          background-image: ${getImgUrl({
            ...urlParams,
            breakpoint,
            namesByBreakpoints,
            isRetina: true,
            isWebp: true,
            compression,
          })};
        }
      }
    `
  })
}
