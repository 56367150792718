import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { em } from 'polished'
import { CSSTransition } from 'react-transition-group'
import { enableBodyScroll, disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

import ButtonClose from 'components/ButtonClose'
import { ResizeContext } from 'contexts/Resize'
import Portal from 'shared/Portal'

export const Dialog = ({ isModalOpen, setIsModalOpen, fullscreen, children }) => {
  const ref = useRef(null)
  const { mobile } = React.useContext(ResizeContext)

  useEffect(() => {
    if (isModalOpen) {
      disableBodyScroll(ref.current)
    } else {
      enableBodyScroll(ref.current)
    }
  }, [isModalOpen, disableBodyScroll])

  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [])

  return (
    <CSSTransition
      in={isModalOpen}
      timeout={200}
      classNames='modal'
      unmountOnExit
    >
      <Portal ref={ref}>
        <StyledModal onClick={() => setIsModalOpen(false)}>
          <ModalContent onClick={(e) => e.stopPropagation()} fullscreen={fullscreen}>
            {children}
          </ModalContent>

          <ModalButtonClose>
            <ButtonClose
              handleClick={() => setIsModalOpen(false)} color={mobile ? 'black' : 'white'}
              size={mobile ? 'medium' : 'normal'}
            />
          </ModalButtonClose>
        </StyledModal>
      </Portal>
    </CSSTransition>
  )
}

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-D50);
  z-index: 501;
`

const ModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-height: ${p => p.fullscreen ? '100%' : 'calc(100vh - 100px)'};
  height: ${p => p.fullscreen ? '100%' : 'auto'};
  border-radius: ${p => p.fullscreen ? '0' : `${em(20)}`};
  background-color: #fff;
  overflow-y: auto;
`

const ModalButtonClose = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: ${em(10)};
  right: ${em(10)};
  filter: drop-shadow(0px 10px 12px rgba(0, 0, 0, 0.07));
  border-radius: 100%;
  background-color: var(--color-W100);
  width: ${em(40)};
  height: ${em(40)};

  ${({ theme }) => theme.mq.upMd} {
    top: ${em(20)};
    right: ${em(20)};
    border-radius: 0;
    background-color: transparent;
    filter: none;
  }
`

Dialog.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  fullscreen: PropTypes.bool,
  children: PropTypes.any,
}
