import PropTypes from 'prop-types'
import React from 'react'
import { Field, Form, FormSpy } from 'react-final-form'
import { ThemeContext } from 'styled-components'

import {
  composeValidators,
  errorCustom,
  errorFromDict,
  isEmail,
  required,
} from 'forms/validations'
import { subscribeEmail } from 'utils/api'
import { gtmEvent } from 'utils/client/analitics'

import {
  FormBox,
  FieldBox,
  BtnContent,
  BtnTitle,
  ButtonAsyncActionStStyled,
  TextFieldRoundedStyled,
} from './styles'

const SUBSCRIBE_FORM = 'subscribe_ebook'

const SubscribeEbookForm = ({ errorsDict, texts, onSubmited, isMobile }) => {
  const [attempt, setAttempt] = React.useState(0)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isSucceed, setIsSucceed] = React.useState(null)
  const [isInvalid, setIsInvalid] = React.useState(true)
  const isSubmitted = isSucceed || isLoading

  const { colors } = React.useContext(ThemeContext)

  const handleSubmit = React.useCallback(
    ({ email }) => {
      if (isLoading) {
        return
      }

      setIsLoading(true)

      subscribeEmail({
        email,
        list: '56528cb6cc',
        tags: ['(LM) MB EBOOK LP MAIN'],
      })
        .then(resp => {
          if (
            typeof resp === 'object' &&
            'subscribed' in resp &&
            resp.subscribed === true
          ) {
            setIsLoading(false)
            setIsSucceed(true)
            gtmEvent({
              event: 'Block Ebook Subscribe — Leave Email — Success',
            })
            onSubmited && onSubmited()
          } else {
            setIsLoading(false)
            setIsSucceed(false)
            gtmEvent({
              event: 'Block Ebook Subscribe — Leave Email — Error',
              description: resp.error,
            })
          }
        })
        .catch(err => {
          setIsLoading(false)
          setIsSucceed(false)
          gtmEvent({
            event: 'Block Ebook Subscribe — Leave Email — Error',
            description: JSON.stringify(err?.response),
          })
        })
    },
    [isLoading, onSubmited],
  )

  const handleRemoteSubmit = React.useCallback(() => {
    document
      .getElementById(SUBSCRIBE_FORM)
      .dispatchEvent(new Event('submit', { cancelable: true }))

    return !isInvalid
  }, [isInvalid])

  // Reset button
  React.useEffect(() => {
    let timer = null

    if (isLoading === false && isSucceed === false) {
      timer = setTimeout(() => {
        setIsLoading(false)
        setIsSucceed(null)
        setIsInvalid(true)
        setAttempt(attempt + 1)
      }, 5000)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [attempt, isLoading, isSucceed])

  return (
    <FormBox>
      <FieldBox>
        <Form
          onSubmit={handleSubmit}
          render={({ handleSubmit, submitting }) => (
            <form id={SUBSCRIBE_FORM} onSubmit={handleSubmit}>
              <TextFieldRoundedStyled
                autoComplete="off"
                placeholder={texts.placeholder}
                name="email"
                isDisabled={isSubmitted}
                validate={composeValidators([
                  errorCustom(errorsDict.email_required, required),
                  errorFromDict(errorsDict, isEmail),
                ])}
              />

              <Field
                name="submit"
                type="submit"
                component="input"
                hidden={true}
                disabled={submitting}
              />

              <FormSpy
                subscription={{
                  invalid: true,
                  touched: true,
                }}
                onChange={({ invalid, touched }) =>
                  !!touched.email && setIsInvalid(invalid)
                }
              />
            </form>
          )}
        />
      </FieldBox>

      <ButtonAsyncActionStStyled
        key={attempt}
        onClick={handleRemoteSubmit}
        isLoading={isLoading}
        isSucceed={isSucceed}
        animAlign={isMobile ? 'center' : 'left'}
        bgClassName="bg--black"
        blackoutColor={colors.lilac}
      >
        <BtnContent>
          <BtnTitle
            as="div"
            dangerouslySetInnerHTML={{ __html: texts.button }}
          />
        </BtnContent>
      </ButtonAsyncActionStStyled>
    </FormBox>
  )
}

SubscribeEbookForm.propTypes = {
  texts: PropTypes.object.isRequired,
  errorsDict: PropTypes.object.isRequired,
  onSubmited: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
}

export default React.memo(SubscribeEbookForm)
