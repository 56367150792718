import PropTypes from 'prop-types'
import React from 'react'

import { Navigation, NavigationItem, NavigationWrapper } from './styles'

const TopNavigation = ({ data, activeIndex, isWhite, ...props }) => {
  const itemsRefs = React.useRef([])
  const parentRef = React.useRef(null)
  const [offsetX, setOffsetX] = React.useState(0)
  const leftMargin = React.useRef(0)

  React.useEffect(() => {
    const bbox = parentRef.current.getBoundingClientRect()

    leftMargin.current = bbox.left
  }, [])

  React.useEffect(() => {
    const bbox = itemsRefs.current[activeIndex - 1].getBoundingClientRect()

    setOffsetX(prevOffset => {
      return prevOffset - bbox.left + leftMargin.current
    })
  }, [activeIndex])

  return (
    <Navigation {...props}>
      <NavigationWrapper
        ref={parentRef}
        style={{ transform: `translate(${offsetX}px, 0)` }}
      >
        {data.map((item, id) => (
          <NavigationItem
            key={id}
            ref={node => (itemsRefs.current[id] = node)}
            isActive={id + 1 === activeIndex}
            isWhite={isWhite}
            dangerouslySetInnerHTML={{ __html: item }}
          />
        ))}
      </NavigationWrapper>
    </Navigation>
  )
}

TopNavigation.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string),
  activeIndex: PropTypes.number,
  isWhite: PropTypes.bool,
}

export default React.memo(TopNavigation)
