import { em } from 'polished'
import styled from 'styled-components'

import { BoxBase } from 'styles/components/layout'

export const BoxBaseStyled = styled(BoxBase)`
  padding-top: ${em(40)};
  padding-bottom: ${em(10)};

  ${p => p.theme.mq.upMd} {
    padding-top: ${em(40)};
    padding-bottom: ${em(40)};
  }
`
