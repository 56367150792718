import styled from 'styled-components'
import { em } from 'polished'

import { Text } from 'shared/Typography'
import { BoxBase } from 'styles/components/layout'

export const Wrapper = styled.div`
  background: linear-gradient(180deg, rgba(238, 238, 238, 0.45) 0%, rgba(248, 247, 245, 0) 100%);
`

export const Container = styled(BoxBase)`
  padding-top: ${em(40)};
  padding-bottom: ${em(60)};

  ${({ theme }) => theme.mq.upLg} {
    padding-top: ${em(80)};
    padding-bottom: ${em(80)};
  }
`

export const Title = styled(Text)`
  margin-bottom: 10px;
  text-align: center;

  span {
    color: var(--color-V50);
  }

  ${({ theme }) => theme.mq.upMd} {
    margin-bottom: 15px;
  }
`

export const Description = styled(Text)`
  margin-bottom: 30px;
  text-align: center;

  ${({ theme }) => theme.mq.upMd} {
    margin-bottom: 60px;
  }
`

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${em(30)} ${em(20)};
  margin-bottom: ${em(60)};

  ${({ theme }) => theme.mq.upLg} {
    grid-template-columns: repeat(5, 1fr);
    gap: ${em(15)};
    margin-bottom: ${em(40)};
  }
`

export const AdvertContainer = styled.div`
  display: flex;
  gap: ${em(20)};
  flex-direction: column;

  ${({ theme }) => theme.mq.upLg} {
    gap: ${em(40)};
    flex-direction: row;
  }
`
