import PropTypes from 'prop-types'
import React from 'react'
import { css } from 'styled-components'

import ButtonRoundedSt from 'components/ButtonRoundedSt'
import Icon from 'components/Icon'
import { LocalizationContext } from 'contexts/Localization'
import useInViewCommon from 'hooks/useInViewCommon'
import { RowIndented } from 'styles/components/layout'
import { H2, Text } from 'styles/components/typography'

const OTHER_PROJECTS = [
  'partners',
  // 'clinic',
  'solo'
]

import {
  BoxBaseStyled,
  ColStyled,
  ContentWrapper,
  TextBox,
  H2Styled,
  DynamicImageStyled,
  IconBox,
} from './styles'

const OtherProjects = ({ data }) => {
  const {
    dict,
    cdnPaths: { home },
  } = React.useContext(LocalizationContext)
  const coverUrl = `${home}/other_projects`
  const [ref, inView] = useInViewCommon()

  return (
    <BoxBaseStyled>
      <H2Styled dangerouslySetInnerHTML={{ __html: data.title }} />
      <RowIndented ref={ref} indent={{ xs: 0, md: 0, lg: 20 }}>
        {OTHER_PROJECTS.map(project => (
          <ColStyled key={project} width={{ xs: 1, md: 1 / 2, lg: 1 / 2 }}>
            <ContentWrapper
              isSolo={project === 'solo'}
              isPro={project === 'partners'}
              path={`${coverUrl}/${project}`}
              ext="png"
            >
              {project === 'clinic' && (
                <IconBox>
                  <Icon id="atlas_clinic" />
                </IconBox>
              )}
              {project === 'partners' && (
                <IconBox>
                  <Icon id="partners" />
                </IconBox>
              )}
              <H2
                as="h3"
                dangerouslySetInnerHTML={{ __html: data[project].title }}
              />
              <TextBox isSolo={project === 'solo'}>
                <Text
                  dangerouslySetInnerHTML={{ __html: data[project].text }}
                />
              </TextBox>
              <div>
                <ButtonRoundedSt
                  bgStyles={css`
                    background-color: ${p => p.theme.colors.black};
                  `}
                  to={dict.links[project]}
                >
                  {dict.buttons.learn_more}
                </ButtonRoundedSt>
              </div>
              {inView && project !== 'partners' &&  (
                <DynamicImageStyled
                  isSolo={project === 'solo'}
                  imageSizes={{ xs: 320, md: 540 }}
                  quality={{
                    mobile2x: 100,
                    mobile3x: 100,
                    uhd: 100,
                    retina: 100,
                    tablet3x: 100,
                    tablet2x: 100,
                  }}
                  image={`${coverUrl}/${project}`}
                  imageType="png"
                  alt={data[project].title}
                  namesByBreakpoints
                />
              )}
            </ContentWrapper>
          </ColStyled>
        ))}
      </RowIndented>
    </BoxBaseStyled>
  )
}

OtherProjects.propTypes = {
  data: PropTypes.object.isRequired,
}

export default OtherProjects
