import { em } from 'polished'
import styled from 'styled-components'

export const BtnClose = styled.button`
  width: ${em(40)};
  height: ${em(40)};
  cursor: pointer;
  z-index: 6;
  transition: ${({
    theme: {
      animation: { timeStandard },
    },
  }) => `transform ${timeStandard}, opacity ${timeStandard}`};

  ${p => p.theme.mq.upMd} {
    width: ${em(35)};
    height: ${em(35)};
  }

  ${p => p.theme.mq.upLg} {
    width: ${em(40)};
    height: ${em(40)};
  }

  ${({ transitionState }) =>
    (transitionState === 'entering' ||
      transitionState === 'exiting' ||
      transitionState === 'exited') &&
    `opacity: 0;`}

  ${({ transitionState }) => transitionState === 'entered' && `opacity: 1;`}

  ${p => p.theme.mq.hover}{
    &:hover{
      transform: rotateZ(-180deg);
      opacity: 0.4;
    }
  }
`

export const BtnCloseLarge = styled(BtnClose)`
  width: ${em(30)};
  height: ${em(30)};

  ${p => p.theme.mq.upMd} {
    width: ${em(50)};
    height: ${em(50)};
  }

  ${p => p.theme.mq.upLg} {
    width: ${em(50)};
    height: ${em(50)};
  }
`

export const BtnCloseMedium = styled(BtnClose)`
  width: ${em(24)};
  height: ${em(24)};

  ${p => p.theme.mq.upMd} {
    width: ${em(30)};
    height: ${em(30)};
  }

  ${p => p.theme.mq.upLg} {
    width: ${em(30)};
    height: ${em(30)};
  }
`

export const BtnCloseSmall = styled(BtnClose)`
  width: ${em(15)};
  height: ${em(15)};

  ${p => p.theme.mq.upMd} {
    width: ${em(20)};
    height: ${em(20)};
  }

  ${p => p.theme.mq.upLg} {
    width: ${em(20)};
    height: ${em(20)};
  }
`
