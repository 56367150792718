import PropTypes from 'prop-types'
import React from 'react'
import { css } from 'styled-components'

import ButtonRoundedSt from 'components/ButtonRoundedSt'

import { ButtonWrapper } from './styles'

const Button = ({ link, text }) => (
  <ButtonWrapper>
    <ButtonRoundedSt
      as="Link"
      asPath={link.as}
      to={link.to}
      bgStyles={css`
        background-color: ${p => p.theme.colors.white};
      `}
      blackoutStyles={css`
        background-color: ${p => p.theme.colors.lilacDark};
      `}
      textStyles={css`
        color: ${p => p.theme.colors.black};

        ${p => p.theme.mq.hover} {
          &:hover {
            color: ${p => p.theme.colors.white};
          }
        }
      `}
    >
      {text}
    </ButtonRoundedSt>
  </ButtonWrapper>
)

Button.propTypes = {
  link: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
}

export default React.memo(Button)
