import PropTypes from 'prop-types'
import React, { Component } from 'react'

import Modal from 'components/Modal'
import { LocalizationConsumer } from 'contexts/Localization'
import { ResizeContext } from 'contexts/Resize'

import TraitsListPopupContent from './components/TraitsListPopupContent'

class TraitsList extends Component {
  constructor(props) {
    super(props)

    this.modalRef = React.createRef()
  }

  render() {
    const { texts, isOpen, handleClose } = this.props
    const viewport = this.context

    return (
      <LocalizationConsumer>
        {({ locale, paths }) => (
          <Modal
            modalRef={this.modalRef}
            modalOpen={isOpen}
            isMobile={viewport.mobile}
            handleCloseModal={handleClose}
            locale={locale}
            isRounded={true}
          >
            <TraitsListPopupContent
              modalRef={this.modalRef}
              locale={locale}
              paths={paths}
              texts={texts}
              isOpen={isOpen}
            />
          </Modal>
        )}
      </LocalizationConsumer>
    )
  }
}

TraitsList.contextType = ResizeContext

TraitsList.propTypes = {
  texts: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  locale: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
}

export default TraitsList
