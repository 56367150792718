import { em } from 'polished'
import styled from 'styled-components'

export const TitleBox = styled.div`
  display: inline-block;
  max-width: ${em(960)};

  ${p => p.theme.mq.upXl} {
    max-width: 80%;
  }
`

export const TextBox = styled.div`
  display: inline-block;
  max-width: ${em(465)};
  margin-bottom: ${em(60)};

  ${p => p.theme.mq.upXl} {
    max-width: 43%;
  }
`
