import { em } from 'polished'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  z-index: 1;
`

export const Dot = styled.div`
  position: relative;
  pointer-events: all;
  width: ${em(30)};
  height: ${em(30)};
  margin: 0 ${em(5)};

  ${p => p.theme.mq.hover} {
    cursor: ${p => (p.isActive ? 'default' : 'pointer')};
  }

  &:before {
    content: '';
    display: ${p => (p.isActive ? 'none' : 'block')};
    width: ${em(8)};
    height: ${em(8)};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: currentColor;
    border-radius: 50%;
    transform: scale(1);
    transition: color ease 150ms, transform ease 150ms;
    opacity: 0.82;
  }

  &:hover {
    &:before {
      transform: scale(2);
    }
  }
`

export const Svg = styled.svg`
  display: ${p => (p.isActive ? 'block' : 'none')};

  circle {
    stroke: currentColor;
    transition: stroke ease 150ms, stroke-dasharray linear 50ms;
  }
`

export const Spinner = styled.circle`
  //stroke-dasharray: 152;
  stroke-dashoffset: -152;
  transform: rotateZ(-90deg);
  transform-origin: center;
`
