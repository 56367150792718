import styled from 'styled-components'
import { em } from 'polished'

import bg_desktop from './assets/background_desktop.png'
import bg_mob from './assets/background_mob.png'

import { Text } from 'shared/Typography'
import { BoxBase } from 'styles/components/layout'

export const Wrapper = styled.div`
  background-color: var(--color-G50);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;
  background-image: url(${bg_mob});

  ${({ theme }) => theme.mq.upMd} {
    background-size: auto 500px;
  }

  ${({ theme }) => theme.mq.upLg} {
    background-image: url(${bg_desktop});
    background-position: right bottom;
    background-size: contain;
  }
`

export const Container = styled(BoxBase)`
  padding-top: ${em(40)};
  padding-bottom: ${em(40)};

  ${({ theme }) => theme.mq.upLg} {
    padding-top: ${em(80)};
    padding-bottom: ${em(68)};
  }
`

export const Title = styled(Text)`
  max-width: ${em(250, 25)};
  margin-bottom: 10px;

  span {
    color: var(--color-V50);
  }

  ${({ theme }) => theme.mq.upLg} {
    max-width: ${em(480, 50)};
    margin-bottom: ${em(20, 50)};
  }
`

export const Description = styled(Text)`
  margin-bottom: ${em(10, 16)};


  ${({ theme }) => theme.mq.upMd} {
    max-width: ${em(540, 22)};
    margin-bottom: ${em(30, 22)};
  }
`

export const FiberContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${em(26)};

  ${({ theme }) => theme.mq.upLg} {
    flex-direction: row;
  }
`

export const FiberText = styled(Text)`
  display: flex;
  flex-shrink: 0;

  ${({ theme }) => theme.mq.upMd} {
    max-width: ${em(193)};
    line-height: ${em(22, 16)};
  }
`

export const FiberImage = styled.img`
  flex-shrink: 0;
  max-width: ${em(170)};

  ${({ theme }) => theme.mq.downMd} {
    margin-left: ${em(-20)};
  }

  ${({ theme }) => theme.mq.upMd} {
    max-width: ${em(420)};
    margin-top: ${em(-15)};
  }
`
