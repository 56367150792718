import { connect } from 'react-redux'

import { TRAITS_LIST } from 'constants/textBunchesNames'
import withLocalization from 'containers/withLocalization'

import Component from './TraitsList'

function mapStateToProps(state) {
  return {
    location: state.location,
  }
}

const LocalizedComponent = withLocalization(Component, TRAITS_LIST)

export default connect(mapStateToProps, null)(LocalizedComponent)
