import PropTypes from 'prop-types'
import React from 'react'

import useInViewCommon from 'hooks/useInViewCommon'

const RenderInView = ({ children, display }) => {
  const [ref, inView] = useInViewCommon()

  return (
    <div style={{ display }} ref={ref}>
      {inView ? children : null}
    </div>
  )
}

RenderInView.propTypes = {
  children: PropTypes.node.isRequired,
  display: PropTypes.string,
}

RenderInView.defaultProps = {
  display: 'inline-block',
}

export default RenderInView
