import { em } from 'polished'
import styled from 'styled-components'

import { Text } from 'shared/Typography'

import bg from './assets/bg.png'
import bg_mob from './assets/bg_mob.png'

export const Container = styled.div`
  width: 100%;
  padding: ${em(30)} ${em(20)} ${em(20)};
  border-radius: ${em(30)};
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${bg_mob});

  ${({ theme }) => theme.mq.upMd} {
    padding: ${em(40)} ${em(40)} ${em(20)};
    background-image: url(${bg});
  }
`

export const Title = styled(Text)`
  color: var(--color-W100);
  text-align: center;
  margin-bottom: ${em(20, 25)};

  ${({ theme }) => theme.mq.upMd} {
    max-width: ${em(442, 35)};
    margin-bottom: ${em(20, 35)};
    text-align: left;
  }
`

export const List = styled.ul`
  padding: 0;
  list-style: none;
  margin-bottom: ${em(30)};
  max-width: ${em(205)};

  ${({ theme }) => theme.mq.upMd} {
    max-width: ${em(300)};
    margin-bottom: ${em(56)};
  }
`

export const ListItem = styled.li`
  display: flex;
  color: var(--color-W100);

  div {
    ${({ theme }) => theme.mq.downMd} {
      line-height: ${em(18, 14)};
    }
  }

  &:not(:last-of-type) {
    margin-bottom: ${em(15)};
  }

  &::before {
    content: '';
    display: inline-block;
    flex-shrink: 0;
    width: ${em(5)};
    height: ${em(5)};
    margin-top: ${em(10)};
    margin-right: ${em(10)};
    border-radius: 100%;
    background-color: var(--color-V50);
  }

  ${({ theme }) => theme.mq.upMd} {
    &:not(:last-of-type) {
      margin-bottom: ${em(25)};
    }
  }
`

export const Links = styled.div`
  display: flex;
  gap: ${em(15)};

  img {
    display: block;
    max-width: 100%;
    cursor: pointer;

    ${({ theme }) => theme.mq.upMd} {
      max-width: ${em(122)};
    }
  }
`
