import PropTypes from 'prop-types'
import React from 'react'

import { Content } from './styles'

const Background = ({ state, children }) => {
  return <Content state={state}>{children}</Content>
}

Background.propTypes = {
  state: PropTypes.oneOf(['entering', 'entered', 'exiting', 'exited']),
  children: PropTypes.node,
}

export default Background
