import PropTypes from 'prop-types'
import React from 'react'

import { LocalizationContext } from 'contexts/Localization'

const CostContainer = ({
  isCartInProgress,
  children,
  productType,
  isSubscription,
}) => {
  const {
    sales,
    currentCountry: { intlCode },
    specialDistribution,
  } = React.useContext(LocalizationContext)

  if (isCartInProgress || specialDistribution || sales?.withoutSales) {
    return null
  }

  const salesConfig = sales.goods[productType]
  const { price, priceWebsite } =
    isSubscription && salesConfig.subscription
      ? salesConfig.subscription
      : salesConfig

  return children({
    currency: sales.currency,
    intlCode,
    cost: priceWebsite,
    realCost: price,
  })
}

CostContainer.propTypes = {
  /** Function to render cost node */
  children: PropTypes.func.isRequired,

  /** !!! Pass this prop when render this HOC component
   * Type of product (dna, biome.. look at constants/sales.js ) */
  productType: PropTypes.string.isRequired,

  /** Is it some process around a cart happening */
  isCartInProgress: PropTypes.bool,

  /** !!! Pass this prop when render this HOC component
   * Flag: is it subscription */
  isSubscription: PropTypes.bool,
}

CostContainer.defaultProps = {
  isSubscription: false,
}

export default CostContainer
