import PropTypes from 'prop-types'
import React from 'react'

import { GOODS_TYPES } from 'constants/sales'

import { Wrapper, DynamicImageStyled, DiscountLabelStyled } from './styles'

const goodsProps = boxesPath => ({
  [GOODS_TYPES.dna]: {
    image: `${boxesPath}/dna-left-new`,
  },
  [GOODS_TYPES.biome]: {
    image: `${boxesPath}/biome-left-new`,
  },
  [GOODS_TYPES.wgs]: {
    image: `${boxesPath}/wgs-left-new`,
  },
})

const UpsaleCardNarrowBox = ({
  productType,
  title,
  discount,
  boxesPath,
  isVisible,
  locale,
}) => {
  if (!isVisible) return null

  return (
    <Wrapper>
      {isVisible && (
        <>
          <DynamicImageStyled
            imageSizes={{
              xs: 900,
              md: 1305,
            }}
            alt={title}
            fitOption="none"
            imageType="png"
            {...goodsProps(boxesPath)[productType]}
          />
          {discount > 0 && (
            <DiscountLabelStyled
              text={''}
              value={discount}
              isShortLabel={true}
              isWithFilling={true}
              locale={locale}
              productType={productType}
            />
          )}
        </>
      )}
    </Wrapper>
  )
}

UpsaleCardNarrowBox.propTypes = {
  productType: PropTypes.oneOf([
    GOODS_TYPES.dna,
    GOODS_TYPES.biome,
    GOODS_TYPES.wgs,
  ]).isRequired,
  boxesPath: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  title: PropTypes.string,
  discount: PropTypes.number,
  locale: PropTypes.string,
}

UpsaleCardNarrowBox.defaultProps = {
  discount: 0,
}

export default React.memo(UpsaleCardNarrowBox)
