import { fill } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import useInViewCommon from 'hooks/useInViewCommon'
import useSwiper from 'hooks/useSwiper'
import useSwiperLoader from 'hooks/useSwiperLoader'

import Publication from '../Publication'

import { Wrapper, ControlSliderArrowsStyled, Slide } from './styles'

const SLIDER_PER_VIEW = 3
const SLIDER_OPTS = {
  noSwiping: true,
  preloadImages: false,
  slidesPerView: SLIDER_PER_VIEW,
  slidesPerGroup: SLIDER_PER_VIEW,
}

const PublicationsSlider3Items = ({ list, logos }) => {
  const { swiper, activeIndex, onGoLeft, onGoRight, updateSwiper } = useSwiper()
  const [supplementedData, updateData] = React.useState([])
  const [ref, inView] = useInViewCommon()
  const { Swiper, swiperLoaded } = useSwiperLoader({ inView })

  React.useEffect(() => {
    if (list.length) {
      const remainder = list.length % SLIDER_PER_VIEW
      const remainderAmount =
        remainder === 0 ? list.length : SLIDER_PER_VIEW - remainder
      const supplementedArray = fill(new Array(remainderAmount), {})

      updateData(list.concat(supplementedArray))
    }
  }, [list])

  React.useEffect(() => {
    if (swiper && supplementedData.length) {
      swiper.update()
    }
  }, [swiper, supplementedData])

  return (
    <Wrapper ref={ref}>
      {swiperLoaded ? (
        <Swiper {...SLIDER_OPTS} getSwiper={updateSwiper}>
          {supplementedData.map((item, key) => {
            let content = null

            if ('content' in item) {
              content = (
                <Publication data={item} logo={logos[key]} inView={inView} />
              )
            }

            return <Slide key={`publication.${key}`}>{content}</Slide>
          })}
        </Swiper>
      ) : (
        supplementedData.slice(0, 3).map((item, key) => {
          let content = null

          if ('content' in item) {
            content = (
              <Publication data={item} logo={logos[key]} inView={inView} />
            )
          }

          return <Slide key={`publication.${key}`}>{content}</Slide>
        })
      )}
      {list.length > 3 && swiperLoaded ? (
        <ControlSliderArrowsStyled
          amount={Math.ceil(logos.length / SLIDER_OPTS.slidesPerGroup)}
          activeIndex={Math.ceil(activeIndex / SLIDER_OPTS.slidesPerGroup)}
          isLightColor={true}
          handleLeft={onGoLeft}
          handleRight={onGoRight}
        />
      ) : null}
    </Wrapper>
  )
}

PublicationsSlider3Items.propTypes = {
  list: PropTypes.array.isRequired,
  logos: PropTypes.array.isRequired,
}

export default PublicationsSlider3Items
