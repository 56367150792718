import { em } from 'polished'
import styled, { css } from 'styled-components'

import { Row, Col } from 'styles/components/layout'

export const RowStyled = styled(Row)`
  ${p => p.theme.mq.upLg} {
    margin-left: -${p => p.theme.indents.baseIndentMd};
    margin-right: -${p => p.theme.indents.baseIndentMd};

    ${p =>
      p.isThreeGoods &&
      css`
        justify-content: space-between;
        margin-left: 0;
        margin-right: 0;
      `}
  }
`

export const ColStyled = styled(Col)`
  display: flex;
  justify-content: center;
  min-width: ${em(300)};
  max-width: 70%;
  margin-bottom: ${props => (props.isSpecialDistribution ? em(20) : em(60))};

  &:last-child {
    margin-bottom: 0;
  }

  ${p => p.theme.mq.upMd} {
    margin-bottom: 0;
  }

  ${props =>
    props.isThirdBlock &&
    css`
      ${p => p.theme.mq.upMd} {
        max-width: 100%;
        margin-top: ${em(60)};
      }

      ${p => p.theme.mq.upLg} {
        display: block;
        max-width: none;
        margin-top: 0;
      }
    `}

  ${p => p.theme.mq.upLg} {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
`
