import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { StyledText } from './styles'
import { ResizeContext } from 'contexts/Resize'

const Text = React.forwardRef((props, ref) => {
  const {
    size,
    as,
    weight,
    transform,
    truncate,
    nowrap,
    decoration,
    className,
    children,
    ...restProps
  } = props
  const { mobile, tablet } = useContext(ResizeContext)

  let currentSize

  if (Array.isArray(size)) {
    currentSize = size[0]

    if (tablet) {
      currentSize = size[1]
    }

    if (mobile) {
      currentSize = size[2]
    }
  } else {
    currentSize = size
  }

  return (
    <StyledText
      ref={ref}
      size={currentSize}
      as={as}
      weight={weight}
      transform={transform}
      truncate={truncate}
      decoration={decoration}
      nowrap={nowrap}
      className={className}
      {...restProps}>
      {children}
    </StyledText>
  )
})

Text.defaultProps = {
  size: '14/20',
  weight: 'regular',
  as: 'div',
}

Text.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  as: PropTypes.string,
  weight: PropTypes.oneOf(['bold', 'medium', 'regular']),
  transform: PropTypes.oneOf(['lowercase', 'capitalize', 'uppercase']),
  decoration: PropTypes.oneOf(['line-through', 'underline']),
  truncate: PropTypes.bool,
  nowrap: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any,
}

export { Text }
