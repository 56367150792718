import PropTypes from 'prop-types'
import React from 'react'

import Icon from 'components/Icon'
import { Text } from 'styles/components/typography'

import {
  Wrapper,
  StarsBox,
  Title,
  Description,
  AuthorBox,
  IconBox,
  Header,
  DateOfPublish,
} from './styles'

const formatDate = dateString => {
  const date = new Date(dateString)
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date)
  let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date)
  let day = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(date)

  return `${month} ${day}, ${year}`
}

const TrustpilotReview = ({ title, text, consumer, stars, createdAt }) => {
  return (
    <Wrapper>
      <Header>
        <StarsBox>
          <img
            alt={`TrustScore ${stars} out of 5`}
            src={`https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-${stars}.svg`}
          />
        </StarsBox>
        <DateOfPublish
          dangerouslySetInnerHTML={{ __html: formatDate(createdAt) }}
        />
      </Header>
      <Title dangerouslySetInnerHTML={{ __html: title }} />
      <Description dangerouslySetInnerHTML={{ __html: text }} />
      <AuthorBox>
        <IconBox>
          <Icon id="btn_profile" color="white" />
        </IconBox>
        <Text
          dangerouslySetInnerHTML={{
            __html: consumer?.displayName,
          }}
        />
      </AuthorBox>
    </Wrapper>
  )
}

TrustpilotReview.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  createdAt: PropTypes.string,
  consumer: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
  }),
  stars: PropTypes.number.isRequired,
}

export default React.memo(TrustpilotReview)
