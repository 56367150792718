import lifestyle from './lifestyle.png'
import health from './health.png'
import digestion from './digestion.png'
import iconViews from './iconViews.svg'

export const imageMap = {
  lifestyle,
  health,
  digestion,
  iconViews,
}
