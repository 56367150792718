import first from './1.png'
import second from './2.png'
import third from './3.png'
import fourth from './4.png'
import fifth from './5.png'
import video from './video.png'
import first_mob from './1_mob.png'
import second_mob from './2_mob.png'
import third_mob from './3_mob.png'
import fourth_mob from './4_mob.png'
import fifth_mob from './5_mob.png'
import video_mob from './video_mob.png'

export const imgMap = {
  1: first,
  2: second,
  3: third,
  4: fourth,
  5: fifth,
  6: video,
}

export const imgMapMob = {
  1: first_mob,
  2: second_mob,
  3: third_mob,
  4: fourth_mob,
  5: fifth_mob,
  6: video_mob,
}
