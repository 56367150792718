import { em, math } from 'polished'
import styled from 'styled-components'

import { Col } from 'styles/components/layout'
import { white, blackOp1 } from 'styles/theme/colors'

export const TraitsListOuter = styled.div``

export const TraitsListTabs = styled.div`
  background-color: ${white};
  height: ${em(163)};
  position: sticky;
  top: 0;
  z-index: 10;
  padding-top: ${em(50)};

  ${p => p.theme.mq.upMd} {
    padding-top: 0;
    height: ${em(176)};
    overflow: hidden;
    top: -${em(15)};
    border-top-left-radius: ${p => p.theme.blocks.blockBorderRadiusMedium};
    border-top-right-radius: ${p => p.theme.blocks.blockBorderRadiusMedium};
  }

  ${p => p.theme.mq.upLg} {
    top: -${em(13)};
    height: ${em(146)};
  }
`

export const TraitsListTabsInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  border-top: 1px solid ${blackOp1};
  border-bottom: 1px solid ${blackOp1};

  ${p => p.theme.mq.upMd} {
    border-top: none;
    border-bottom: ${em(1.5)} solid ${blackOp1};
  }
`

export const TraitsListContent = styled.div`
  padding-left: ${p => p.theme.indents.baseIndentXs};
  padding-right: ${p => p.theme.indents.baseIndentXs};

  ${p => p.theme.mq.upMd} {
    padding-left: ${p => p.theme.indents.baseIndentMd};
    padding-right: ${p => p.theme.indents.baseIndentMd};
  }

  ${p => p.theme.mq.upLg} {
    padding-left: ${p => math(`${p.theme.indents.baseBoxIndentLg} * 3 / 4`)};
    padding-right: ${p => math(`${p.theme.indents.baseBoxIndentLg} * 3 / 4`)};
  }
`

export const ColStyled = styled(Col)`
  padding-bottom: ${em(60)};
`
