import PropTypes from 'prop-types'
import React, { Component } from 'react'

import PublicationsMobile from './components/PublicationsMobile'
import PublicationsSlider3Items from './components/PublicationsSlider3Items'

/**
 * Publications component with switching 3 items in slider for desktop (tablet) and
 * single item for mobile
 *
 * @return {component} Publication - stateless component of Publication
 */
class Publications extends Component {
  state = {
    list: undefined,
    logos: undefined,
    prevLogos: undefined,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.list &&
      (nextProps.list !== prevState.list ||
        nextProps.logos !== prevState.prevLogos)
    ) {
      const { logos, list } = nextProps
      let resultLogos = []

      if (typeof logos === 'string') {
        resultLogos = list.map(item => `${logos}/${item.logo}.svg`)
      } else {
        resultLogos = logos
      }

      return {
        prevLogos: logos,
        logos: resultLogos,
        list: list,
      }
    }

    return null
  }

  renderSlider3Items = () => {
    const { list, logos } = this.state

    if (list && list.length) {
      return <PublicationsSlider3Items list={list} logos={logos} />
    }

    return null
  }

  render() {
    const { bgClassName, isMobile } = this.props
    const { list, logos } = this.state

    return (
      <div className={bgClassName}>
        {isMobile ? (
          <PublicationsMobile list={list} logos={logos} />
        ) : (
          this.renderSlider3Items()
        )}
      </div>
    )
  }
}

Publications.propTypes = {
  /** Class name for publications container */
  bgClassName: PropTypes.string,

  /** Flag to control view */
  isMobile: PropTypes.bool,

  /** List of publications */
  list: PropTypes.array,

  /** Array of URLs to load all logos (for styleguide) or path to static folder with publications */
  logos: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

Publications.defaultProps = {
  bgClassName: 'bg--purple',
  isMobile: false,
}

export default Publications
