import styled from 'styled-components'

import DiscountLabel from 'components/DiscountLabel'
import DynamicImage from 'components/DynamicImage'
import { RU } from 'constants/countries'

export const Wrapper = styled.div`
  position: relative;

  ${p => p.theme.mq.upLg} {
    width: 100%;
  }
`

export const DynamicImageStyled = styled(DynamicImage)`
  display: block;
  position: relative;
  pointer-events: none;
  max-width: 100%;
  margin: 0 auto;

  ${p => p.theme.mq.upMd} {
    max-width: 100%;
  }
`

export const DiscountLabelStyled = styled(DiscountLabel)`
  position: absolute;
  right: 0;
  top: -0.5em;
  font-size: ${p => p.theme.typography.fontSize};
  line-height: ${p => p.theme.typography.baseLh};

  ${({ productType, locale }) =>
    productType === 'biome' &&
    (locale === RU
      ? `right: 4%;`
      : `top: -0.25em;
       right: 1.5%;
      `)}

  ${p => p.theme.mq.upMd} {
    ${({ locale }) =>
      locale === 'RU'
        ? `top: -6%;
         right: -9%;`
        : `top: -6%;
         right: -7%;`}

    ${({ productType, locale }) =>
      productType === 'biome' &&
      (locale === RU
        ? `top: -3%;
         right: -4%;
        `
        : `top: -5.5%;
         right: -5.5%;
        `)}
  }

  ${p => p.theme.mq.upLg} {
    top: -0.5em;
  }
`
