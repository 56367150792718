import { em } from 'polished'
import styled, { css } from 'styled-components'

import { RU } from 'constants/countries'
import { Text } from 'styles/components/typography'

export const ColInner = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: ${p => p.theme.colors.grayOp06NonTransp};
  border-radius: ${p => p.theme.blocks.blockBorderRadiusMedium};
  padding: ${em(27)} ${em(20)} ${em(20)};

  ${p => p.theme.mq.upMd} {
    padding: ${em(29)} ${em(20)} ${em(40)};
  }

  ${p => p.theme.mq.upLg} {
    height: 100%;
  }
`

export const HeaderBox = styled.div`
  ${p => p.theme.mq.upMd} {
    margin: 0 auto;
  }
`

export const TextStyled = styled(Text)`
  ${p => p.theme.mq.upLg} {
    padding: 0 14%;
    flex-grow: 2;
  }
`

export const ImageWrapper = styled.div`
  margin: ${em(10)} auto ${em(30)};
  width: 100%;

  ${p => p.theme.mq.upMd} {
    margin: ${em(30)} auto ${em(40)};
    padding: 0 ${em(20)};
  }

  ${p => p.theme.mq.upLg} {
    padding: 0;
  }

  ${({ locale }) =>
    locale === RU &&
    css`
      max-width: ${em(253)};

      ${p => p.theme.mq.upMd} {
        max-width: ${em(424)};
      }
    `}

  ${({ locale }) =>
    locale !== RU &&
    css`
      max-width: ${em(350)};

      ${p => p.theme.mq.upMd} {
        max-width: ${em(420)};
      }
    `}
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  max-width: ${em(490)};
  margin: 0 auto;

  .button {
    width: ${p => (p.locale === RU ? '100%' : null)};
  }
`

export const ButtonBox = styled.div`
  width: ${em(226)};
  margin-bottom: ${p => (p.withoutMargin ? null : em(20))};

  ${p => p.theme.mq.upLg} {
    margin-bottom: 0;
  }
`
