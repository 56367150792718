import { em } from 'polished'
import styled, { css } from 'styled-components'

import UnstyledUl from 'styles/components/UnstyledUl'

import { bulletWidth, bulletWidthMd } from './components/ListItem/styles'

export const Ul = styled(UnstyledUl)`
  color: ${({
    textColor,
    theme: {
      colors: { white, black },
    },
  }) => (textColor === 'white' ? white : black)};

  & > & {
    margin-left: ${em(bulletWidth)};

    ${p => p.theme.mq.upMd} {
      margin-left: ${em(bulletWidthMd)};
    }
  }

  ${p =>
    p.leftAlignment &&
    p.leftAlignment === 'text' &&
    css`
      margin-left: -${em(bulletWidth)};

      ${p => p.theme.mq.upMd} {
        margin-left: -${em(bulletWidthMd)};
      }
    `}
`
