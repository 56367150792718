import { em } from 'polished'
import styled, { css } from 'styled-components'

export const ModalOutter = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  z-index: 1050;
  transition: opacity ${p => p.theme.animation.timeStandard};
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  ${({ transitionState }) =>
    (transitionState === 'entering' ||
      transitionState === 'exiting' ||
      transitionState === 'exited') &&
    `opacity: 0;`}

  ${({ transitionState }) => transitionState === 'entered' && `opacity: 1;`}
`

export const ModalInnerWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;

  ${p =>
    p.position === 'bottom' &&
    `
    bottom: 0;
    top: initial;
    > div:nth-child(2) {
      border-radius: 40px 40px 0 0;
    }
  `};
  ${p =>
    p.position === 'right' &&
    `
    bottom: 0;
    right: 0;
    max-width: 65%;
    > div:nth-child(2) {
      border-radius: 0;
    }
  `};
`

export const ModalInner = styled.div`
  z-index: 1060;
  background-color: ${p => p.theme.colors.white};
  position: relative;
  min-height: 100%;
  margin: 0;

  ${p => p.theme.mq.upMd} {
    min-height: auto;
    margin: ${p => (p.position === 'right' ? '0' : '${em(40)}')}
      ${({ isRounded }) =>
        isRounded &&
        css`
          border-radius: ${p => p.theme.blocks.blockBorderRadiusMedium};
        `};
  }

  ${p => p.theme.mq.upLg} {
    margin: ${p => (p.position === 'right' ? '0' : '${em(60)}')};
  }

  ${p =>
    p.position === 'right' &&
    `
    height: 100%;
  `};
`

export const BtnCloseWrapper = styled.div`
  position: fixed;
  z-index: 10000;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;

  @supports (position: sticky) {
    position: sticky;
    width: auto;
    display: inline-block;
    top: 0;
    float: right;
  }

  @supports (-ms-ime-align: auto) {
    position: fixed;
  }
`
export const BtnCloseWrapperInside = styled.div`
  position: absolute;
  z-index: 10000;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: right;
  align-items: center;
  > div {
    width: ${em(26)};
    height: ${em(26)};
  }

  ${p =>
    p.position === 'right' &&
    `
    right: initial;
    left: -66px;
    border-radius: 50%;
    padding: 10px;
    background-color: white;
  `};
`

export const BtnCloseInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${em(50)};
  height: ${em(50)};
  overflow: hidden;

  ${p => p.theme.mq.upMd} {
    width: ${em(40)};
    height: ${em(40)};
  }

  ${p => p.theme.mq.upLg} {
    width: ${em(60)};
    height: ${em(60)};
  }

  @supports (-ms-ime-align: auto) {
    margin-right: 15px;
  }
`
