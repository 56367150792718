import PropTypes from 'prop-types'
import React, { Component } from 'react'

import ButtonRounded from 'components/ButtonRounded'
import { LocalizationConsumer } from 'contexts/Localization'

import TraitsListItems from '../TraitsListItems'

import { ButtonBox, TraitsListCollapserWrapper } from './styles'

class TraitsListCollapser extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    modalRef: PropTypes.object,
  }

  constructor(props) {
    super(props)

    this.containerRef = React.createRef()
    this.timer = null
    this.state = {
      itemsLong: props.items,
      itemsShort: props.items.slice(0, 20),
      itemsIsOpen: false,
    }
  }

  handleOnClick = () => {
    const { modalRef = { current: null } } = this.props
    const { itemsIsOpen } = this.state

    if (itemsIsOpen) {
      if (modalRef && modalRef.current) {
        const y0 = modalRef.current.scrollTop
        const h0 = this.containerRef.current.getBoundingClientRect().height
        this.setState({ itemsIsOpen: false }, () => {
          const h1 = this.containerRef.current.getBoundingClientRect().height
          modalRef.current.scrollTop = y0 - (h0 - h1)
        })
      } else {
        this.setState({ itemsIsOpen: false })
      }
    } else {
      const y0 = modalRef.current.scrollTop
      this.setState({ itemsIsOpen: true }, () => {
        modalRef.current.scrollTop = y0
      })
    }
  }

  render() {
    const { itemsLong, itemsShort, itemsIsOpen } = this.state
    const currList = itemsIsOpen ? itemsLong : itemsShort

    return (
      <TraitsListCollapserWrapper>
        <TraitsListItems items={currList} containerRef={this.containerRef} />

        <LocalizationConsumer>
          {({ dict }) => (
            <ButtonBox withBg={!itemsIsOpen} s>
              <ButtonRounded
                as="button"
                bgClassName="bg--transp bg--border-gray"
                blackoutClassName="bg--purple"
                textClassName="text"
                wrapperProps={{ onClick: this.handleOnClick }}
              >
                {itemsIsOpen ? dict.buttons.close : dict.buttons.open_all}
              </ButtonRounded>
            </ButtonBox>
          )}
        </LocalizationConsumer>
      </TraitsListCollapserWrapper>
    )
  }
}

export default TraitsListCollapser
