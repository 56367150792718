import React from 'react'

import IconSecurityLocker from 'components/_special/IconSecurityLocker'
import List, { ListItem } from 'components/List'
import { H2 } from 'styles/components/typography'
import { LocalizationContext } from 'contexts/Localization'

import { SectionWrapperStyled, TextStyled } from './styles'

const dictionaryFieldName = 'protection'

export const Protection = () => {
  const { dict } = React.useContext(LocalizationContext)
  const texts = dict[dictionaryFieldName]

  return (
    <SectionWrapperStyled classNameInner='row py-4 py-lg-0 align-items-center inherited-height'>
      <div className='col-12 col-md-6'>
        <IconSecurityLocker />
      </div>
      <div className='col-12 col-md-6 mt-4 mt-md-0'>
        <H2 dangerouslySetInnerHTML={{ __html: texts.title }} />
        <TextStyled dangerouslySetInnerHTML={{ __html: texts.description }} />
        <List leftAlignment='text'>
          {texts.list.map((item, id) =>
            item ? (
              <ListItem key={`protection.${id}`} bulletColor='dark-blue'>
                {item}
              </ListItem>
            ) : null,
          )}
        </List>
      </div>
    </SectionWrapperStyled>
  )
}
