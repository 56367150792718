import { em } from 'polished'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  margin: 0 -${p => p.theme.indents.baseIndentXs};
  padding-top: ${em(20)};

  ${p => p.theme.mq.upMd} {
    height: auto;
    padding-top: 0;
  }
`

export const Inner = styled.div`
  display: block;

  ${p => p.theme.mq.upMd} {
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
  }

  ${({ isNoWrapContent }) =>
    isNoWrapContent &&
    css`
      ${p => p.theme.mq.upLg} {
        flex-wrap: nowrap;
      }
    `}
`
