import { em } from 'polished'
import styled from 'styled-components'

import ControlSliderArrows from 'components/ControlSliderArrows'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  visibility: hidden;
  margin: 0 -${em(31)};

  ${p => p.theme.mq.upMd} {
    visibility: visible;
    padding-bottom: ${em(105)};
  }

  .swiper-wrapper {
    height: auto !important;
  }
`

export const ControlSliderArrowsStyled = styled(ControlSliderArrows)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`

export const Slide = styled.div`
  min-height: ${em(251)};
  padding: 0 ${em(31)};

  ${p => p.theme.mq.upMd} {
    min-height: ${em(251)};
    height: auto !important;
  }
`
