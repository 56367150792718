import PropTypes from 'prop-types'
import React from 'react'

import { GOODS_TYPES } from 'constants/sales'
import CartSaleNarrowContainer from 'containers/CartSaleNarrowContainer'
import { BoxBase } from 'styles/components/layout'

import { RowStyled, ColStyled } from './styles'

const UpsaleCards = ({ isVisible, productsList, specialDistribution }) => (
  <BoxBase>
    <RowStyled
      isThreeGoods={productsList.length > 2}
      justifyContent="space-around"
    >
      {productsList.map((productType, i) => (
        <ColStyled
          key={productType}
          isThirdBlock={i === 2}
          isSpecialDistribution={specialDistribution}
          width={{ xs: 1, md: 1 / 2, lg: 0.26 }}
        >
          <CartSaleNarrowContainer
            productType={productType}
            isVisible={isVisible}
          />
        </ColStyled>
      ))}
    </RowStyled>
  </BoxBase>
)

UpsaleCards.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  specialDistribution: PropTypes.bool.isRequired,
  productsList: PropTypes.arrayOf(
    PropTypes.oneOf([GOODS_TYPES.dna, GOODS_TYPES.wgs, GOODS_TYPES.biome]),
  ).isRequired,
}

export default React.memo(UpsaleCards)
