import CSSPlugin from 'gsapAlias/CSSPlugin'
import TimelineLite from 'gsapAlias/TimelineLite'
import { grass, COLOR_FAILED, lilacDark } from 'styles/theme/colors'

// eslint-disable-next-line no-unused-vars
const plugins = [CSSPlugin]

const BASE_DURATION = 2
const TEXT_ANIMATION = BASE_DURATION / 6
const BUTTON_RESIZE = BASE_DURATION / 3
const LOADER_TIME = 30

/*
 * Sequence of animations:
 * Aligned left
 * 1. Decrease width to left side of button and hide text
 * 2. Show loader
 * 3. Fill loader path
 * 4. Hide loader
 * 5. Show success/error status icon
 * Aligned center: different 1st step; rest the same
 * 1. Decrease width to center of button and hide text
 */

export const decreaseWidthToLeftAndHideText = color => ({
  wrapper,
  bg,
  blackout,
  text,
}) => {
  const timeline = new TimelineLite()
  const { width, height } = wrapper.getBoundingClientRect()

  timeline
    .set(text, {
      y: 0,
      opacity: 1,
    })
    .set(bg, {
      scaleX: 1,
      scaleY: 1,
      width: width,
      backgroundColor: color || lilacDark,
    })
    .set(blackout, {
      scaleX: 1,
      scaleY: 1,
      opacity: 0,
    })
    .to(text, TEXT_ANIMATION, {
      y: 15,
      opacity: 0,
    })
    .to(bg, BUTTON_RESIZE, {
      width: height,
    })
    .set(text, {
      width: height,
    })
    .set(wrapper, {
      display: 'block',
      width: height,
    })

  return timeline
}

export const decreaseWidthToRightAndHideText = color => ({
  wrapper,
  bg,
  blackout,
  text,
}) => {
  const timeline = new TimelineLite()
  const { width, height } = wrapper.getBoundingClientRect()

  timeline
    .set(text, {
      y: 0,
      opacity: 1,
    })
    .set(bg, {
      scaleX: 1,
      scaleY: 1,
      width: width,
      backgroundColor: color || lilacDark,
    })
    .set(wrapper, {
      display: 'block',
      x: 0,
      width: width,
    })
    .set(blackout, {
      scaleX: 1,
      scaleY: 1,
      opacity: 0,
    })
    .to(text, TEXT_ANIMATION, {
      y: 15,
      opacity: 0,
    })
    .to(bg, BUTTON_RESIZE, {
      width: height,
      x: width - height,
    })
    .set(text, {
      width: height,
    })
    .set(wrapper, {
      display: 'block',
      paddingLeft: width - height,
    })

  return timeline
}

export const decreaseWidthToCenterAndHideText = color => ({
  wrapper,
  bg,
  blackout,
  text,
}) => {
  const timeline = new TimelineLite()
  const { height, width } = wrapper.getBoundingClientRect()
  const btnCenter = (width - height) / 2

  timeline
    .set(text, {
      y: 0,
      opacity: 1,
    })
    .set(bg, {
      scaleX: 1,
      scaleY: 1,
      width: width,
      backgroundColor: color || lilacDark,
      x: 0,
    })
    .set(wrapper, {
      display: 'block',
      x: 0,
      width: width,
    })
    .set(blackout, {
      scaleX: 1,
      scaleY: 1,
      width: width,
      opacity: 0,
    })
    .to(text, TEXT_ANIMATION, {
      y: 15,
      opacity: 0,
    })
    .to(bg, BUTTON_RESIZE, {
      width: height,
      x: btnCenter,
    })
    .set(text, {
      width: height,
    })
    .set(wrapper, {
      display: 'block',
      paddingLeft: btnCenter,
      paddingRight: btnCenter,
    })

  return timeline
}

export const showLoader = ({ text }) => {
  const timeline = new TimelineLite()

  timeline
    .set(text, {
      y: 0,
      opacity: 0,
    })
    .to(text, TEXT_ANIMATION, {
      opacity: 1,
    })

  return timeline
}

export const showLoaderProcess = loader => {
  const timeline = new TimelineLite({
    onComplete: function() {
      this.restart()
    },
  })

  timeline
    .set(loader, {
      strokeDashoffset: 1000,
    })
    .to(loader, LOADER_TIME, {
      strokeDashoffset: 370,
    })

  return timeline
}

export const hideLoader = ({ text }) => {
  const timeline = new TimelineLite()

  timeline
    .set(text, {
      opacity: 1,
    })
    .to(text, TEXT_ANIMATION, {
      opacity: 0,
    })

  return timeline
}

export const showSuccessIcon = ({ bg, text }) => {
  const timeline = new TimelineLite()

  timeline
    .set(text, {
      width: 'auto',
      opacity: 0,
      y: 15,
    })
    .to(text, TEXT_ANIMATION, {
      opacity: 1,
      y: 0,
    })
    .to(
      bg,
      TEXT_ANIMATION,
      {
        backgroundColor: grass,
      },
      `-=${TEXT_ANIMATION}`,
    )

  return timeline
}

export const showFailedIcon = ({ bg, text }) => {
  const timeline = new TimelineLite()

  timeline
    .set(text, {
      width: 'auto',
      opacity: 0,
      y: 15,
    })
    .to(text, TEXT_ANIMATION, {
      opacity: 1,
      y: 0,
    })
    .to(
      bg,
      TEXT_ANIMATION,
      {
        backgroundColor: COLOR_FAILED,
      },
      `-=${TEXT_ANIMATION}`,
    )

  return timeline
}
