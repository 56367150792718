import React from 'react'

import Icon from 'components/Icon'
import { RU, JP } from 'constants/countries'
import { LocalizationContext } from 'contexts/Localization'
import { ResizeContext } from 'contexts/Resize'
import useInViewCommon from 'hooks/useInViewCommon'
import { H2Like, Text } from 'styles/components/typography'

import media from './assets/media.mp4'
import CuttingEdgeMedia from './components/CuttingEdgeMedia'
import {
  SectionWrapperStyled,
  Content,
  TitleBox,
  Line,
  TextBox,
  TextInner,
  IconWrapper,
  ImgIso,
} from './styles'

const dictionaryFieldName = 'cutting_edge'

export const CuttingEdge = () => {
  const {
    locale,
    dict,
    paths: { cdn_global },
  } = React.useContext(LocalizationContext)
  const { mobile } = React.useContext(ResizeContext)
  const [ref, inView] = useInViewCommon()
  const texts = dict[dictionaryFieldName]

  const mobileTitle = React.useMemo(
    () => texts.title.replace('&nbsp;', ' '),
    [texts.title],
  )

  return (
    <SectionWrapperStyled
      isOverflowHidden={true}
      ref={ref}
      className='text--white'
      classNameInner='w-100'
      withOverlay={true}
      beforeElement={inView ? <CuttingEdgeMedia src={media} /> : null}
    >
      <Content>
        <TitleBox>
          <H2Like
            as='h3'
            dangerouslySetInnerHTML={{
              __html: mobile ? mobileTitle : texts.title,
            }}
          />
        </TitleBox>
        <Line />
        <TextBox>
          <TextInner>
            <Text dangerouslySetInnerHTML={{ __html: texts.description }} />
          </TextInner>
          {locale !== RU && (
            <IconWrapper width={60} height={60}>
              {inView && (
                <ImgIso
                  image={`${cdn_global}/iso/iso`}
                  imageType='png'
                  imageSizes={{
                    xs: 120,
                    md: 120,
                    lg: 120,
                  }}
                  alt='iso'
                  quality={{
                    mobile2x: 100,
                    mobile3x: 100,
                    tablet2x: 100,
                    tablet3x: 100,
                    retina: 100,
                    uhd: 100,
                  }}
                />
              )}
            </IconWrapper>
          )}
          <IconWrapper width={49} height={35}>
            <Icon id='ce' />
          </IconWrapper>
          {locale !== RU && locale !== JP && (
            <IconWrapper width={65} height={35}>
              <Icon id='ivd' />
            </IconWrapper>
          )}
        </TextBox>
      </Content>
    </SectionWrapperStyled>
  )
}
