import customFormatter from './customFormatter'

const getLocaleMapped = locale => {
  if (locale === 'be') {
    return 'en'
  }

  if (locale === 'us') {
    return 'en-US'
  }

  return locale
}

const hasIntl = typeof window !== 'undefined' && 'Intl' in window
export default function createNumberFormatter({
  locale,
  style = 'currency',
  currency,
} = {}) {
  const localeMapped = getLocaleMapped(locale)
  if (hasIntl) {
    return new Intl.NumberFormat(localeMapped, {
      style,
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  }
  return customFormatter(localeMapped, { style, currency })
}
