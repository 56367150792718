import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import Swiper from 'react-id-swiper'

import { ResizeContext } from 'contexts/Resize'

import BlogCard from './components/BlogCard'
import { Wrapper, Container, Title, Description, Texts, Cards, ButtonStyled } from './styles'


const Blog = ({ texts, blogLink }) => {
  const { title, description, button, card: cards } = texts
  const { mobile } = useContext(ResizeContext)

  const params = {
    initialSlide: 0,
    slidesPerView: 1.125,
    spaceBetween: 20,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      dynamicBullets: true,
    },
  }

  return (
    <Wrapper>
      <Container>
        <Texts>
          <Title
            size={['35/40', '35/40', '35/40']}
            weight={'medium'}
            dangerouslySetInnerHTML={{ __html: title }}
          />

          <Description
            size={['18/28', '18/28', '16/20']}
            dangerouslySetInnerHTML={{ __html: description }}
          />

          <ButtonStyled
            variant={'outlined'}
            color={'white'}
            href={blogLink}
            block={mobile}
            target={'_blank'}
          >
            {button}
          </ButtonStyled>
        </Texts>

        {mobile ? (
          <Cards>
            <Swiper {...params}>
              {cards.map(({ title, section, time, date, views }) => (
                <div key={section}>
                  <BlogCard
                    section={section}
                    title={title}
                    time={time}
                    date={date}
                    views={views}
                  />
                </div>
              ))}
            </Swiper>
          </Cards>
        ) : (
          <Cards>
            {cards.map(({ title, section, time, date, views, link }) => (
              <BlogCard
                key={section}
                section={section}
                title={title}
                time={time}
                date={date}
                views={views}
                link={link}
              />
            ))}
          </Cards>
        )}

      </Container>
    </Wrapper>
  )
}

Blog.propTypes = {
  texts: PropTypes.object.isRequired,
  blogLink: PropTypes.string.isRequired,
}

export default Blog
