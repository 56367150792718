import PropTypes from 'prop-types'
import React from 'react'

import bacteria from './assets/bacteria_police.svg'
import locker from './assets/locker.svg'
import lockerLight from './assets/locker_light.svg'
import { Wrapper, Circle, Icon } from './styles'

const ICONS = {
  locker,
  bacteria,
  lockerLight,
}

/**
 * Uncontrollable component of Security Locker, that you can render somewhere
 * or render with animation (TODO: animations)
 *
 * @return {function} IconSecurityLocker
 */
const IconSecurityLocker = ({ icon, iconBgColor }) => {
  return (
    <Wrapper>
      <Circle bgColor={iconBgColor} />
      <Icon background={ICONS[icon]} />
    </Wrapper>
  )
}

IconSecurityLocker.propTypes = {
  /** Possible icons */
  icon: PropTypes.oneOf(Object.keys(ICONS)),

  /** Circle background color */
  iconBgColor: PropTypes.string,
}

IconSecurityLocker.defaultProps = {
  icon: Object.keys(ICONS)[0],
}

export default IconSecurityLocker
