import { em } from 'polished'
import styled from 'styled-components'

import DynamicImage from 'components/DynamicImage'
import SectionWrapper from 'components/SectionWrapper'

const verticalLineHeight = em(140)

export const SectionWrapperStyled = styled(SectionWrapper)`
  width: 100%;

  ${p => p.theme.mq.upMd} {
    display: flex;
    align-items: center;
    min-height: ${p => p.theme.blocks.heightBlock630Md};
  }
`

export const Content = styled.div`
  position: relative;
  padding-top: ${em(40)};
  padding-bottom: ${em(40)};

  ${p => p.theme.mq.upMd} {
    display: flex;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }
`

export const TitleBox = styled.div`
  max-width: 80%;

  ${p => p.theme.mq.upMd} {
    position: relative;
    top: ${em(-7)};
    flex: 0 1 auto;
    padding-right: ${em(20)};
  }
`

export const Line = styled.div`
  display: block;
  height: ${verticalLineHeight};
  width: 1px;
  background: ${p => p.theme.colors.whiteOp6};
  margin-top: ${em(20)};
  margin-bottom: ${em(20)};
  margin-left: ${em(10)};

  ${p => p.theme.mq.upMd} {
    height: 1px;
    width: 100%;
    flex: 2 1 100%;
    margin-left: 0;
  }
`

export const TextBox = styled.div`
  ${p => p.theme.mq.upMd} {
    flex: 1 0 40%;
    margin-top: ${em(5)};
    padding-left: ${em(20)};
  }
  ${p => p.theme.mq.upLg} {
    flex-basis: 50%;
  }
`

export const TextInner = styled.div`
  margin-bottom: ${em(20)};

  ${p => p.theme.mq.upMd} {
    max-width: ${em(400)};
  }
`

export const ImgIso = styled(DynamicImage)`
  display: block;
  width: 100%;
  height: auto;
`

export const IconWrapper = styled.div`
  width: ${p => em(p.width)};
  height: ${p => em(p.height)};
  display: inline-block;
  margin-right: ${em(20)};
  vertical-align: middle;
`
