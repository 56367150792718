import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import React from 'react'
import { useInView } from 'react-intersection-observer'

import { LocalizationContext } from 'contexts/Localization'
import { MenuMainContext } from 'contexts/MenuMain'
import { ResizeContext } from 'contexts/Resize'

const SFeaturesMobile = dynamic(() => import('./components/SFeaturesMobile'))
const SFeaturesTablet = dynamic(() => import( './components/SFeaturesTablet'))
const SFeaturesDesktop = dynamic(() =>
  import('./components/SFeaturesDesktop')
)

function SpecialFeatures({ data, mediaPath }) {
  const { mobile, tablet, breakpoint } = React.useContext(ResizeContext)
  const { language, locale, specialDistribution } = React.useContext(
    LocalizationContext,
  )
  const { menuNode } = React.useContext(MenuMainContext)
  const [ref, inView] = useInView({
    threshold: 0,
    rootMargin: '50% 0px',
  })
  const menuHeight = menuNode?.getBoundingClientRect().height

  const photos = React.useMemo(() => {
    if (mediaPath !== '') {
      return {
        hcc: {
          src: `${mediaPath}/1`,
        },
        rm: {
          src: `${mediaPath}/2`,
        },
        fr: {
          src: `${mediaPath}/3`,
        },
        uh: {
          src: `${mediaPath}/4`,
        },
        ma: {
          src: `${mediaPath}/5`,
          mobileSrc: `${mediaPath}/hand-mobile`,
          downloadSrc: {
            ios: `${mediaPath}/app-store-icon.svg`,
            android: `${mediaPath}/play-market-icon.svg`,
          },
        },
      }
    }
  }, [mediaPath])

  const content = React.useMemo(() => {
    if (mobile) {
      return (
        <SFeaturesMobile
          data={data}
          photos={photos}
          inView={inView}
          breakpoint={breakpoint}
          locale={locale}
          specialDistribution={specialDistribution}
        />
      )
    } else if (tablet) {
      return (
        <SFeaturesTablet
          data={data}
          photos={photos}
          language={language}
          inView={inView}
          breakpoint={breakpoint}
          mobile={mobile}
          locale={locale}
          specialDistribution={specialDistribution}
        />
      )
    } else {
      return (
        <SFeaturesDesktop
          {...{
            inView,
            data,
            photos,
            menuNode,
            menuHeight,
            breakpoint,
            mobile,
            locale,
            specialDistribution,
          }}
        />
      )
    }
  }, [
    mobile,
    tablet,
    data,
    photos,
    inView,
    breakpoint,
    locale,
    specialDistribution,
    language,
    menuNode,
    menuHeight,
  ])

  return <div ref={ref}>{content}</div>
}

SpecialFeatures.propTypes = {
  data: PropTypes.object.isRequired,
  mediaPath: PropTypes.string,
}

export default SpecialFeatures
