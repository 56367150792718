import { em } from 'polished'
import styled from 'styled-components'

import { RowIndented, BoxBase } from 'styles/components/layout'
import { Text, H2Like } from 'styles/components/typography'
import { h4Md } from 'styles/mixins_styled/typography'

export const RowIndentedStyled = styled(RowIndented)`
  position: relative;
  overflow: hidden;
  border-radius: ${p => p.theme.blocks.blockBorderRadiusMedium};
  background: ${p => p.theme.colors.purpleHeartDark};
`

export const BoxBaseStyled = styled(BoxBase)`
  // padding-top: ${p => em(p.theme.indents.baseIndentXs)};
  //
  // ${p => p.theme.mq.upMd} {
  //   padding-top: ${p => em(p.theme.indents.baseIndentMd)};
  // }
`

export const TitleBox = styled.div`
  color: ${p => p.theme.colors.white};
  padding: ${em(50)} 0 ${em(30)};
  margin: 0 auto;
  max-width: ${em(420)};
  text-align: center;

  ${p => p.theme.mq.upMd} {
    padding: ${em(30)} 0 ${em(43)};
    max-width: none;
  }

  ${p => p.theme.mq.upLg} {
    padding: ${em(40)} 0 ${em(40)};
    max-width: ${em(583)};
    text-align: left;
    margin: 0;
  }
`

export const H2LikeStyled = styled(H2Like)`
  font-size: ${p => p.theme.typography.h3};
  line-height: ${p => p.theme.typography.h3Lh};
  margin-bottom: ${p => em(14, p.theme.typography.h3px)};

  ${p => p.theme.mq.upMd} {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: ${p => em(7, p.theme.typography.h2MdPx)};
    max-width: ${p => em(483, p.theme.typography.h2MdPx)};
  }

  ${p => p.theme.mq.upLg} {
    margin-left: 0;
    margin-right: 0;
  }
`

export const Subtitle = styled(Text)`
  opacity: 0.5;

  ${p => p.theme.mq.upMd} {
    ${h4Md};
    font-family: ${p => p.theme.typography.baseFont};
    line-height: 1.6;
    margin-bottom: 0;
    opacity: 0.4;
  }
`

export const ImageBox = styled.div`
  position: relative;
  width: ${em(156)};
  height: ${em(103)};
  user-select: none;
  pointer-events: none;
  margin-top: ${em(30)};

  ${p => p.theme.mq.upMd} {
    width: ${em(338)};
    height: ${em(232)};
    margin-top: ${em(35)};
  }

  ${p => p.theme.mq.upLg} {
    width: ${em(477)};
    height: auto;
    right: -${em(45)};
    margin-top: 0;
  }
`

export const CloseBox = styled.div`
  position: absolute;
  top: ${em(10)};
  right: ${em(10)};

  ${p => p.theme.mq.upMd} {
    top: ${em(20)};
    right: ${em(20)};
  }
`
