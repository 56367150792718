import PropTypes from 'prop-types'
import React from 'react'

import SliderMobile from 'components/SliderMobile'

import FeaturesPlateItem from './components/FeaturesPlateItem'
import { Wrapper, Inner } from './styles'

const ICONS = {
  health: 'color_health',
  health_wgs: 'color_health_wgs',
  nutrition: 'color_nutrition',
  sports: 'color_sports',
  ancestry: 'color_ancestry',
  personal_traits: 'color_person',
  personal_traits_wgs: 'color_person_wgs',
  foods: 'color_cart',
  bacterial_composition: 'color_immunity',
  clinic_dna: 'color_capsula',
}

/*
 * We are going to use this component on About DNA page.
 * It will be called 'FeaturesPlate'.
 * So if you have to customize it — please use '.features-plate' base css class
 */
const FeaturesPlate = ({ data, mobile, isNoWrapContent, isTextWhite }) => {
  const content = mobile ? (
    <SliderMobile isWhite={isTextWhite} className="features-plate__swiper">
      {data.list.map(
        (item, id) =>
          item.code && (
            <div key={`item.${id}`}>
              <FeaturesPlateItem
                {...{ isTextWhite, item }}
                item={item}
                icon={ICONS[item.code]}
              />
            </div>
          ),
      )}
    </SliderMobile>
  ) : (
    data.list.map(
      (item, id) =>
        item.code && (
          <FeaturesPlateItem
            {...{ isTextWhite, item }}
            icon={ICONS[item.code]}
            key={`item.${id}`}
            itemsTotal={data.list.length}
          />
        ),
    )
  )

  return (
    <Wrapper>
      <Inner {...{ isNoWrapContent }}>{content}</Inner>
    </Wrapper>
  )
}

FeaturesPlate.propTypes = {
  data: PropTypes.object.isRequired,
  mobile: PropTypes.bool.isRequired,
  isNoWrapContent: PropTypes.bool,
  isTextWhite: PropTypes.bool,
}

FeaturesPlate.defaultProps = {
  isNoWrapContent: true,
  isTextWhite: false,
}

export default FeaturesPlate
