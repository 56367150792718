import { em } from 'polished'
import styled, { css } from 'styled-components'

import { Text } from 'shared/Typography'

const IconWidth = {
  1: '90px',
  2: '90px',
  3: '74px',
  4: '96px',
  5: '85px',
}

const IconWidthMob = {
  1: '55px',
  2: '55px',
  3: '50px',
  4: '65px',
  5: '80px',
}

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${em(45)} ${em(0)} ${em(20)};
  border-radius: 20px;
  background: var(--color-W100);
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
  text-align: center;

  ${({ theme }) => theme.mq.upLg} {
    padding: ${em(70)} ${em(14)} ${em(36)};
  }

  ${({ theme }) => theme.mq.downLg} {
    ${p =>
      p.isLast &&
      css`
        grid-column-start: 1;
        grid-column-end: 3;
        align-items: flex-start;
        text-align: left;
        padding-left: ${em(20)};
        padding-top: ${em(20)};

        & > div {
          max-width: ${em(238, 14)};
        }

        img {
          right: 14px;
        }
      `}
  }
`

export const Title = styled(Text)`
  margin-bottom: ${em(5, 16)};

  ${({ theme }) => theme.mq.upMd} {
    margin-bottom: ${em(10, 18)};
  }
`

export const Description = styled(Text)`
  max-width: ${em(149, 14)};

  ${({ theme }) => theme.mq.upMd} {
    max-width: ${em(180, 16)};
    line-height: ${em(22, 16)};
  }

  ${({ theme }) => theme.mq.upXl} {
    max-width: none;
  }
`

export const Report = styled(Text)`
  ${({ theme }) => theme.mq.upMd} {
    line-height: ${em(22, 16)};
  }
`

export const Icon = styled.img`
  display: block;
  position: absolute;
  top: 0;
  transform: translateY(-30%);
  width: ${p => IconWidthMob[p.number]};

  ${({ theme }) => theme.mq.upLg} {
    top: ${em(-6)};
    width: ${p => IconWidth[p.number]};
  }
`
