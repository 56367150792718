import PropTypes from 'prop-types'
import React from 'react'

import Card from './components/Card'
import CardAdvertTop10 from './components/CardAdvertTop10'
import CardAdvertApp from './components/CardAdvertApp'
import { Wrapper, Container, Title, Description, CardsContainer, AdvertContainer } from './styles'

const GutBacteria = ({ texts, sharedPath, className }) => {
  const { title, description, cards } = texts

  return (
    <Wrapper className={className}>
      <Container>
        <Title
          forwardedAs={'h2'} size={['50/60', '50/60', '25/30']} weight={'medium'}
          dangerouslySetInnerHTML={{ __html: title }}
        />

        <Description
          size={['22/30', '22/30', '16/20']}
          dangerouslySetInnerHTML={{ __html: description }}
        />

        <CardsContainer>
          {cards.map((card, index) => (
            <Card
              key={card.title}
              number={index + 1}
              title={card.title}
              description={card.description}
              reportText={card.reportText}
              icon={card.icon}
              sharedPath={sharedPath}
              isLast={index === cards.length - 1}
            />
          ))}
        </CardsContainer>

        <AdvertContainer>
          <CardAdvertTop10 />

          <CardAdvertApp />
        </AdvertContainer>
      </Container>
    </Wrapper>
  )
}

GutBacteria.propTypes = {
  texts: PropTypes.object.isRequired,
  sharedPath: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default GutBacteria
