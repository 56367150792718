import { useMemo } from 'react'
import useCurrentPromoEvent from 'hooks/useCurrentPromoEvent'

/**
 * @type {Slides}
 */
const slides = process.env.localization.slides

/**
 * Возвращает слайды либо под промо, либо дефолтные
 * @param {string} country
 * @returns {Slides}
 */
export default function useSlides(country) {
  const currentEvent = useCurrentPromoEvent(country)
  return useMemo(() => {
    let currentSlides = []
    if (currentEvent) {
      currentSlides = slides.filter(
        slide =>
          slide.event === currentEvent.code &&
          slide.countries.includes(country),
      )
    }
    if (currentSlides.length === 0) {
      currentSlides = slides.filter(
        slide => slide.type === 'default' && slide.countries.includes(country),
      )
    }
    return currentSlides
  }, [currentEvent, country])
}
