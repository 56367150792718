import PropTypes from 'prop-types'
import React from 'react'

import Icon from 'components/Icon'
import Link from 'components/Link'
import selectStyledText from 'utils/styled/selectStyledText'

import './LinkInlineArrowShort.scss'

/**
 * Component of inline link with text and short arrow
 * @return Component — LinkInlineArrowShort
 */

const TYPOGRAPHY_SIZES = {
  cart: 'cart',
  base: 'base',
  h2: 'lg',
  h1: 'xl',
}

const LinkInlineArrowShort = ({
  size,
  className,
  classNameArrow,
  isWhite,
  children,
  to,
  asPath,
  as,
  isHoverable,
  isHoverInverted,
}) => {
  const linkHoverClassNames = isHoverInverted ? 'link--inverted' : 'link'
  const realLinkHoverClassName = isHoverable ? linkHoverClassNames : 'no-hover'
  const iconColor = isWhite ? 'white' : 'black'
  const arrowSizeClassNames = `link-inline-short__arrow--${size}`
  const strokeWidth =
    [TYPOGRAPHY_SIZES.base, TYPOGRAPHY_SIZES.cart].indexOf(size) > -1
      ? '1.2'
      : '2'
  const TextComponent = selectStyledText(TYPOGRAPHY_SIZES[size])
  let textSizesClassNames = ''
  let innerContent = ''

  if (isWhite) {
    textSizesClassNames += 'link-inline-short__text--white'
  }

  if (typeof children === 'object') {
    innerContent = (
      <TextComponent
        as="span"
        className={`link-inline-short__text ${textSizesClassNames}`}
      >
        {children}
      </TextComponent>
    )
  } else {
    innerContent = (
      <TextComponent
        as="span"
        dangerouslySetInnerHTML={{ __html: children }}
        className={`link-inline-short__text ${textSizesClassNames}`}
      />
    )
  }

  const inner = (
    <div className="link-inline-short__content">
      {innerContent}
      &nbsp;
      <div
        className={`link-inline-short__arrow ${arrowSizeClassNames} ${classNameArrow}`}
      >
        <Icon id="arrow_short" color={iconColor} strokeWidth={strokeWidth} />
      </div>
    </div>
  )

  if (as === 'Link' && to) {
    return (
      <Link
        to={to}
        asPath={asPath}
        className={`link-inline-short ${realLinkHoverClassName} ${className}`}
      >
        {inner}
      </Link>
    )
  }

  if (as === 'a' && to) {
    return (
      <a
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        className={`link-inline-short ${realLinkHoverClassName} ${className}`}
      >
        {inner}
      </a>
    )
  }

  return (
    <span
      className={`link-inline-short ${realLinkHoverClassName} ${className}`}
    >
      {inner}
    </span>
  )
}

LinkInlineArrowShort.propTypes = {
  /** Link inline content */
  children: PropTypes.node.isRequired,

  /** Kind of wrapper component */
  as: PropTypes.oneOf(['a', 'Link', 'span']),

  /** ClassName for root tag */
  className: PropTypes.string,

  /** ClassName for arrow (for outer control of mouse enter/leave reaction) */
  classNameArrow: PropTypes.string,

  /** Flag to control is it on or off reaction on user's mouse enter/leave */
  isHoverable: PropTypes.bool,

  /** inverted hover effect (fade in from lower opacity value) */
  isHoverInverted: PropTypes.bool,

  /** Change default color of arrow to white */
  isWhite: PropTypes.bool,

  /** Size of arrow equal typography sizes */
  size: PropTypes.oneOf(Object.keys(TYPOGRAPHY_SIZES)),

  /** Location */
  to: PropTypes.string,

  /** Location for client side nextjs router */
  asPath: PropTypes.string,
}

LinkInlineArrowShort.defaultProps = {
  as: 'Link',
  className: '',
  classNameArrow: '',
  size: 'base',
  isHoverable: true,
  isHoverInverted: false,
  isWhite: false,
}

export default LinkInlineArrowShort
