import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Spinner } from 'shared/Spinner'

import { Button as ButtonStyled, Link } from './styles'

export const Button = ({
  variant,
  color,
  size,
  disabled,
  loading,
  block,
  href,
  target,
  className,
  isDangerouslySet = true,
  children,
  ...rest
}) => {
  const button = (
    <ButtonStyled
      className={classNames('button', {
        [className]: !href,
        'loading': loading,
        'disabled': disabled,
        'outlined': variant === 'outlined',
      })}
      variant={variant}
      color={color}
      size={size}
      disabled={disabled}
      block={block}
      {...rest}
    >
      {isDangerouslySet && <span dangerouslySetInnerHTML={{ __html: children }} />}
      {!isDangerouslySet && children}

      {loading && (
        <Spinner />
      )}
    </ButtonStyled>
  )

  if (href) {
    return (
      <Link className={className} href={href} target={target}>
        {button}
      </Link>
    )
  }

  return button
}

Button.propTypes = {
  /** Variant of button */
  variant: PropTypes.oneOf(['primary', 'outlined', 'text']),

  /** Color of button */
  color: PropTypes.oneOf(['black', 'violet', 'white']),

  /** Button sizes */
  size: PropTypes.oneOf(['desktop', 'mobile']),

  /** Disabled button */
  disabled: PropTypes.bool,

  /** Loading button */
  loading: PropTypes.bool,

  /** Full width button */
  block: PropTypes.bool,

  /** Classname of button */
  className: PropTypes.string,

  /** href of link */
  href: PropTypes.string,

  /** target of button */
  target: PropTypes.string,

  /** Full width button */
  isDangerouslySet: PropTypes.bool,

  /** Content of button */
  children: PropTypes.any,
}

Button.defaultProps = {
  variant: 'primary',
  color: 'black',
  size: 'desktop',
}
