import React from 'react'
import { css } from 'styled-components'

import { LocalizationContext } from 'contexts/Localization'
import ButtonRoundedSt from 'components/ButtonRoundedSt'
import useInViewCommon from 'hooks/useInViewCommon'
import { Row, Col } from 'styles/components/layout'
import { H2, Text } from 'styles/components/typography'

import { BoxBaseStyled, ContentWrapper, TextCol, ButtonBox } from './styles'

const dictionaryFieldName = 'blog_section'

export const BlogSection = () => {
  const [ref, inView] = useInViewCommon()
  const { dict, paths: { shared } } = React.useContext(LocalizationContext)

  const link = dict?.links.blog
  const texts = dict[dictionaryFieldName]

  if (!link || !texts?.title) return null

  return (
    <BoxBaseStyled
      ref={ref}
      inView={inView}
      coverUrl={`${shared}/blog/pills_transparent`}
    >
      <Row alignItems="center">
        <Col width={{ xs: 1, md: 1 / 2, lg: 1 / 2 }}>
          <H2 dangerouslySetInnerHTML={{ __html: texts.title }} />
          <ContentWrapper>
            <TextCol>
              <Text dangerouslySetInnerHTML={{ __html: texts.description }} />
            </TextCol>
            <ButtonBox>
              <ButtonRoundedSt
                as="a"
                to={link}
                bgStyles={css`
                  background-color: ${p => p.theme.colors.black};
                `}
              >
                {texts.button}
              </ButtonRoundedSt>
            </ButtonBox>
          </ContentWrapper>
        </Col>
      </Row>
    </BoxBaseStyled>
  )
}
