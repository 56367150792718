import styled from 'styled-components'
import { em } from 'polished'

import { Text } from 'shared/Typography'

import { imgMap, imgMapMob } from './assets'
import { Button } from 'shared/Button'

const imageSizes = {
  desktop: {
    1: '60%',
    2: '83%',
    3: '80%',
    4: '40%',
    5: '53%',
    6: 'cover',
  },
  tablet: {
    1: '30%',
    2: '42%',
    3: '40%',
    4: '20%',
    5: '30%',
    6: 'cover',
  },
  mobile: {
    1: '108px auto',
    2: '165px auto',
    3: '165px auto',
    4: '85px auto',
    5: '120px auto',
    6: 'cover',
  },
}

const textSizesMb = {
    1: '153px',
    2: '153px',
    3: '177px',
    4: '153px',
    5: '188px',
    6: '153px',
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: ${p => p.isLast ? 'space-between' : 'flex-start'};
  height: auto;
  padding: ${em(15)};
  background-color: var(--color-G50);
  border-radius: 30px;
  background-image: ${p => `url(${imgMapMob[p.number]})`};
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: ${p => imageSizes.mobile[p.number]};

  ${({ theme }) => theme.mq.upMd} {
    background-image: ${p => `url(${imgMap[p.number]})`};
    background-size: ${p => imageSizes.tablet[p.number]};
    height: ${em(260)};
    padding: ${em(20)};
  }

  ${({ theme }) => theme.mq.upLg} {
    background-size: ${p => imageSizes.desktop[p.number]};
  }
`

export const Number = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${em(24, 14)};
  width: ${em(24, 14)};
  background-color: var(--color-D100);
  border-radius: 100%;
  margin-bottom: ${em(10, 14)};

  ${({ theme }) => theme.mq.downMd} {
    line-height: unset;
  }

  ${({ theme }) => theme.mq.upMd} {
    height: ${em(30, 16)};
    width: ${em(30, 16)};
  }
`

export const Title = styled(Text)`
  max-width: ${p => textSizesMb[p.number]};
  margin-bottom: ${p => p.isLast ? `${em(20)}` : '0'};

  ${({ theme }) => theme.mq.upMd} {
    max-width: 100%;
  }
`

export const LinkButton = styled(Button)`
  span {
    display: flex;
    align-items: center;
    gap: ${em(10)};
  }
`

export const YoutubeVideo = styled.div`
  width: ${em(320)};
  height: ${em(220)};
  overflow: hidden;

  ${({ theme }) => theme.mq.upMd} {
    width: ${em(760)};
    height: ${em(520)};
  }
`
