import styled from 'styled-components'
import { em } from 'polished'

import { BoxBase } from 'styles/components/layout'
import { Text } from 'shared/Typography'

export const Wrapper = styled.div`
  background-color: var(--color-W100);
`

export const Container = styled(BoxBase)`
  padding-top: ${em(40)};
  padding-bottom: ${em(60)};

  ${({ theme }) => theme.mq.upMd} {
    padding-top: ${em(80)};
    padding-bottom: ${em(80)};
  }
`

export const Title = styled(Text)`
  margin-bottom: ${em(20, 35)};
  text-align: center;

  ${({ theme }) => theme.mq.upMd} {
    margin-bottom: ${em(20, 50)};
  }
`

export const AccordionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${em(10)};
`
