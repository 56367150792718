import PropTypes from 'prop-types'
import React from 'react'

import DynamicImage from 'components/DynamicImage'
import Icon from 'components/Icon'
import { ResizeContext } from 'contexts/Resize'
import useInViewCommon from 'hooks/useInViewCommon'
import { biome } from 'routes'
import { Col } from 'styles/components/layout'
import { H2 } from 'styles/components/typography'

import Button from './Button'
import {
  ImageWrapper,
  TextStyled,
  ColStyled,
  IconWrapper,
  RowIndentedStyled,
} from './styles'

function BiomeCardWide({ locale, mediaPath, dict }) {
  const [ref, inView] = useInViewCommon()
  const viewport = React.useContext(ResizeContext)

  const testProps = React.useMemo(
    () => ({
      title: dict.kits.biome.title,
      text: dict.kits.about_tests.biome.text,
      image: {
        path: `${mediaPath}/biome-right-v1`,
        size: { xs: 350, md: 641, lg: 782 },
      },
      button: {
        text: dict.buttons.about_biome,
        link: {
          as: biome.as(locale),
          to: biome.link(locale),
        },
      },
    }),
    [
      dict.buttons.about_biome,
      dict.kits.about_tests.biome.text,
      dict.kits.biome.title,
      locale,
      mediaPath,
    ],
  )

  return (
    <RowIndentedStyled ref={ref}>
      <ColStyled width={{ xs: 1, md: 1, lg: 5 / 12 }}>
        <H2 as="h3" dangerouslySetInnerHTML={{ __html: testProps.title }} />
        <TextStyled dangerouslySetInnerHTML={{ __html: testProps.text }} />
        {!viewport.mobile && <Button {...testProps.button} />}
      </ColStyled>
      <Col width={{ xs: 1, md: 1, lg: 7 / 12 }}>
        <ImageWrapper>
          {inView && (
            <>
              <DynamicImage
                imageSizes={testProps.image.size}
                image={testProps.image.path}
                imageType="png"
                alt={testProps.title}
                namesByBreakpoints={false}
              />
              <IconWrapper>
                <Icon id="color_bacteria_cloud_hello" />
              </IconWrapper>
            </>
          )}
        </ImageWrapper>
        {viewport.mobile && <Button {...testProps.button} />}
      </Col>
    </RowIndentedStyled>
  )
}

BiomeCardWide.propTypes = {
  locale: PropTypes.string.isRequired,
  mediaPath: PropTypes.string.isRequired,
  dict: PropTypes.object.isRequired,
}

export default React.memo(BiomeCardWide)
