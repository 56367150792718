import PropTypes from 'prop-types'
import React from 'react'

import ButtonClose from 'components/ButtonClose'
import { ResizeContext } from 'contexts/Resize'
import SubscribeEmailForm from 'forms/SubscribeEmailForm'
import { Col } from 'styles/components/layout'
import { gtmEvent } from 'utils/client/analitics'
import LocalStorage from 'utils/client/localStorage'
import { Text } from 'shared/Typography'

import { rollUp } from './animations'
import {
  RowIndentedStyled,
  Title,
  Description,
  Disclaimer,
  CloseBox,
  BoxBaseStyled,
  ButtonSendAgain,
  ImageDesktop,
  ImageMob,
} from './styles'

const blockLSId = 'abg_email_subscription_closed'

const SubscribeEmail = ({ texts, errorsDict, sharedPath, countryCode }) => {
  const { mobile } = React.useContext(ResizeContext)
  const boxRef = React.useRef()
  const lsRef = React.useRef()
  const [isClose, setClose] = React.useState(null)
  const [isNodeRemoved, removeNode] = React.useState(false)
  const [isSucceed, setIsSucceed] = React.useState(false)

  const closeForNextTime = React.useCallback(() => {
    lsRef.current?.setItem(blockLSId, true)
  }, [])

  const handleClose = React.useCallback(() => {
    closeForNextTime()
    setClose(true)
    gtmEvent({
      event: 'Block Email Subscribe — Closed',
    })
  }, [closeForNextTime])

  React.useEffect(() => {
    lsRef.current = new LocalStorage()
    const isOpened = !lsRef.current.getItem(blockLSId)

    if (isOpened) {
      setClose(false)
      gtmEvent({
        event: 'Block Email Subscribe — Showed',
      })
    }
  }, [])

  React.useEffect(() => {
    if (isClose && boxRef.current) {
      const tm = rollUp(boxRef.current, () => {
        removeNode(true)
      })

      return () => tm.kill()
    }
  }, [isClose])

  if (isClose === null || isNodeRemoved) {
    return null
  }

  return (
    <div ref={boxRef}>
      <BoxBaseStyled>
        <RowIndentedStyled>
          <Col width={{ xs: 1, md: 10 / 12, lg: isSucceed ? 4 / 12 : 6 / 12 }}>
            <Title
              className='h-color-W100'
              forwardedAs='h2'
              weight='medium'
              size={['30/35', '30/35', '20/25']}
              dangerouslySetInnerHTML={{ __html: isSucceed ? texts.success_title : texts.title }}
              isSucceed={isSucceed}
            />

            <Description
              forwardedAs='p'
              size={['18/24', '18/24', '16/20']}
              dangerouslySetInnerHTML={{ __html: isSucceed ? texts.success_description : texts.description }}
            />

            {!isSucceed && (
              <SubscribeEmailForm
                key={countryCode}
                isSucceed={isSucceed}
                setIsSucceed={setIsSucceed}
                texts={texts}
                errorsDict={errorsDict}
                countryCode={countryCode}
                onSubmited={closeForNextTime}
                isMobile={mobile}
              />
            )}


            {isSucceed && (
              <ButtonSendAgain
                onClick={() => setIsSucceed(false)}
              >
                <Text
                  className='h-color-W100'
                  decoration='underline'
                  size={['18/24', '18/24', '16/20']}
                  dangerouslySetInnerHTML={{ __html: texts.send_again }}
                />
              </ButtonSendAgain>
            )}

            {!isSucceed && (
              <Disclaimer
                size='12/14'
                className=''
                dangerouslySetInnerHTML={{ __html: texts.disclaimer }}
              />
            )}
          </Col>

          <ImageDesktop src={`${sharedPath}/mail_desktop.png`} />

          <ImageMob src={`${sharedPath}/mail_mob.png`} />

          <CloseBox>
            <ButtonClose handleClick={handleClose} />
          </CloseBox>
        </RowIndentedStyled>
      </BoxBaseStyled>
    </div>
  )
}

SubscribeEmail.propTypes = {
  texts: PropTypes.object.isRequired,
  errorsDict: PropTypes.object.isRequired,
  sharedPath: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
}

export default SubscribeEmail
