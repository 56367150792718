import { em } from 'polished'
import styled from 'styled-components'

import { Col, BoxBase } from 'styles/components/layout'

export const BoxBaseStyled = styled(BoxBase)`
  overflow: hidden;
  padding-top: ${p => em(p.theme.indents.baseIndentXs)};

  ${p => p.theme.mq.upMd} {
    padding-top: ${p => em(p.theme.indents.baseIndentMd)};
  }
`

export const ColStyled = styled(Col)`
  margin-bottom: ${p => p.theme.indents.baseIndentXs};

  ${p => p.theme.mq.upMd} {
    margin-bottom: ${p => p.theme.indents.baseIndentMd};
  }

  ${p => p.theme.mq.upLg} {
    margin-bottom: 0;
  }
`
