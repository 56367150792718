import PropTypes from 'prop-types'
import React from 'react'

import { ResizeContext } from 'contexts/Resize'

import { Wrapper, Container, Title, Description, FiberText, FiberImage, FiberContainer } from './styles'
import fiber from './assets/fiber.png'
import fiberMob from './assets/fb_mob.png'

const BuzzBugs = ({ texts, className }) => {
  const { title, description, fiberText } = texts
  const { mobile } = React.useContext(ResizeContext)

  return (
    <Wrapper className={className}>
      <Container>
        <Title
          forwardedAs={'h2'} size={['50/60', '50/60', '25/30']} weight={'medium'}
          dangerouslySetInnerHTML={{ __html: title }}
        />

        <Description
          size={['22/30', '22/30', '16/20']}
          dangerouslySetInnerHTML={{ __html: description }}
        />

        <FiberContainer>
          <FiberText
            size={['16/20', '16/20', '14/20']}
            dangerouslySetInnerHTML={{ __html: fiberText }}
          />

          <FiberImage src={mobile ? fiberMob : fiber} alt={'fiber_img'} />
        </FiberContainer>

      </Container>
    </Wrapper>
  )
}

BuzzBugs.propTypes = {
  texts: PropTypes.object.isRequired,
  className: PropTypes.string,
}

export default BuzzBugs
