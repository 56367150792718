import { em } from 'polished'
import styled from 'styled-components'

import { Text } from 'shared/Typography'

import bg from './assets/bg.png'
import bg_mob from './assets/bg_mob.png'

export const Container = styled.div`
  width: 100%;
  padding: ${em(30)} ${em(20)} ${em(280)};
  background-color: var(--color-Y30);
  border-radius: ${em(30)};
  background-repeat: no-repeat;
  background-position: bottom center;
  background-image: url(${bg_mob});
  background-size: contain;

  ${({ theme }) => theme.mq.upMd} {
    padding: ${em(40)} ${em(49)} ${em(376)};
    background-image: url(${bg});
    background-size: 55%;
  }

   ${({ theme }) => theme.mq.upLg} {
    padding: ${em(40)} ${em(49)} ${em(376)};
    background-image: url(${bg});
    background-size: contain;
  }

  @media(min-width: 1333px) {
    background-size: 80%;
  }
`

export const Title = styled(Text)`
  text-align: center;
  margin-bottom: ${em(10, 25)};

  ${({ theme }) => theme.mq.upMd} {
    margin-bottom: ${em(20, 35)};
  }
`

export const Description = styled(Text)`
  text-align: center;

`

export const Image = styled.img`

  ${({ theme }) => theme.mq.upMd} {
    max-width: ${em(442)};
  }
`
