import { em } from 'polished'
import styled from 'styled-components'

import { Text } from 'shared/Typography'

export const Wrapper = styled.div`
  background-color: var(--color-G50);
  background-image: ${p => `url(${p.boxesPath}/mob.png)`};
  background-repeat: no-repeat;
  background-size: cover;

  ${p => p.theme.mq.upMd} {
    background-image: none;
    background-color: transparent;
  }
`

export const Container = styled.div`
  position: relative;
  padding: ${em(40)} ${em(0)} ${em(90)};
  background-repeat: no-repeat;
  background-size: contain;

  ${p => p.theme.mq.upMd} {
    background-color: var(--color-G50);
    border-radius: ${em(30)};
    background-image: ${p => `url(${p.boxesPath}/desktop.png)`};
    background-size: 92%;
  }

  ${p => p.theme.mq.upLg} {
    padding: ${em(60)} ${em(60)} ${em(110)};
    background-size: contain;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  ${p => p.theme.mq.upMd} {

  }

  ${p => p.theme.mq.upLg} {
    flex-direction: row;
    justify-content: flex-end;
    gap: ${em(46)};
  }
`

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${em(20)};
  margin-bottom: ${em(30)};

  ${p => p.theme.mq.upMd} {
    width: 100%;
    max-width: 50%;
  }

  ${p => p.theme.mq.upLg} {
    width: auto;
    max-width: none;
    gap: ${em(31)};
    margin-bottom: ${em(0)};
  }
`

export const Title = styled(Text)`
  display: flex;
  flex-direction: column;

  ${p => p.theme.mq.upMd} {
    max-width: ${em(323, 50)};
  }
`

export const Description = styled(Text)`
  display: flex;
  flex-direction: column;
  color: var(--color-D50);

  ${p => p.theme.mq.upMd} {
    max-width: ${em(294, 18)};
    line-height: ${em(26, 18)};
    margin-left: ${em(9, 18)};
  }
`

export const PriceAndButtons = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: flex-start;
  flex-direction: column;
  width: 100%;

  ${p => p.theme.mq.upMd} {
    flex-direction: row;
    align-items: center;
  }

  ${p => p.theme.mq.upLg} {
    width: auto;
    flex-direction: column;
    padding-top: ${em(15)};
  }
`

export const ControlAmountContainer = styled.div`
  display: flex;
  order: 2;
  gap: ${em(20)};

  ${p => p.theme.mq.downMd} {
    align-self: stretch;
  }

  ${p => p.theme.mq.upMd} {
    width: 100%;
    justify-content: center;
  }

  ${p => p.theme.mq.upLg} {
    width: auto;
    justify-content: flex-start;
    order: 2;
    gap: ${em(20)};
  }
`

export const Prices = styled.div`
  display: flex;
  flex-direction: column;
  order: 1;
  margin-bottom: ${em(210)};

  ${p => p.theme.mq.upMd} {
    width: 100%;
    order: 2;
    margin-bottom: 0;
  }

  ${p => p.theme.mq.upLg} {
    width: auto;
    align-items: center;
    gap: ${em(5)};
    margin-top: ${em(57)};
    margin-bottom: ${em(20)};
  }
`

export const OldPrice = styled.div`
  display: flex;
  align-items: center;
  gap: ${em(10)};
  color: var(--color-D30);
`

export const DiscountBadge = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${em(25.5)};
  padding: 0 ${em(5)};
  background-color: var(--color-P50);
  border-radius: ${em(4)};
  color: var(--color-W100);
`

export const Price = styled(Text)`
`

export const ButtonWrapper = styled.div`
  order: 3;

  ${p => p.theme.mq.downMd} {
    width: 100%;

    button {
      width: 100%;
    }
  }

  ${p => p.theme.mq.upMd} {
    width: 100%;
  }

  ${p => p.theme.mq.upLg} {
    width: auto;
  }
`

export const Subscribe = styled.div`
  position: absolute;
  right: -${em(20)};
  left: -${em(20)};
  padding: 0 ${em(20)};
  bottom: 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  justify-content: space-between;

  &:before {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    height: ${em(60)};
    background-color: var(--color-Y50);
    z-index: 1;

    ${p => p.theme.mq.upMd} {
      border-bottom-right-radius: ${em(30)};
      border-bottom-left-radius: ${em(30)};
      height: ${em(50)};
    }
  }

  ${p => p.theme.mq.upMd} {
    flex-direction: row;
    justify-content: center;
    gap: ${em(20)};
    right: 0;
    left: 0;
    padding: 0;
  }
`

export const IconPig = styled.img`
  max-width: ${em(75)};
  position: relative;
  z-index: 2;
`

export const ReadMore = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  z-index: 2;
  padding-bottom: ${em(10)};

  ${p => p.theme.mq.upMd} {
    flex-direction: row;
    align-items: center;
    gap: ${em(7)};
    padding-bottom: ${em(13)};
  }

  button:first-of-type {
    height: auto;
    padding: 0;
    font-weight: 700;
    text-decoration: underline;
  }

  button span {
    line-height: ${em(20)};
  }
`

export const SubscribeText = styled(Text)`
  span {
    color: var(--color-P50);
  }
`
