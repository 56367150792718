import PropTypes from 'prop-types'
import React from 'react'

import { Col, Row } from 'styles/components/layout'
import { Bold } from 'styles/components/typography'

import TraitsListCollapser from './components/TraitsListCollapser'
import TraitsListItems from './components/TraitsListItems'
import { ItemsWrapper } from './styles'

const TraitsListBlock = ({ items, modalRef }) => {
  return (
    <Row>
      {items.map((item, index) => (
        <Col width={{ xs: 1, md: 1 / 2 }} key={index}>
          <Bold as="h4" dangerouslySetInnerHTML={{ __html: item.title }} />
          {item.list.length > 90 ? (
            <TraitsListCollapser modalRef={modalRef} items={item.list} />
          ) : (
            <ItemsWrapper>
              <TraitsListItems items={item.list} />
            </ItemsWrapper>
          )}
        </Col>
      ))}
    </Row>
  )
}

TraitsListBlock.propTypes = {
  items: PropTypes.array.isRequired,
  modalRef: PropTypes.object,
}

export default TraitsListBlock
