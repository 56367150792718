import styled from 'styled-components'
import { em } from 'polished'

import { BoxBase } from 'styles/components/layout'

export const Container = styled(BoxBase)`
  display: flex;
  justify-content: center;
`

export const Brands = styled.div`
  display: flex;
  gap: ${em(36)};
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  ${({ theme }) => theme.mq.upLg} {
    flex-direction: row;
    align-items: flex-start;
    gap: ${em(50)};
  }

  ${({ theme }) => theme.mq.downLg} {
    & > a:nth-child(1) > img {
      max-width: ${em(114)};
    }

    & > a:nth-child(2) > img {
      max-width: ${em(90)};
    }

    & > a:nth-child(3) > img {
      max-width: ${em(52)};
    }

    & > a:nth-child(4) > img {
      max-width: ${em(250)};
    }

    & > a:nth-child(5) > img {
      max-width: ${em(95)};
    }

    & > a:nth-child(6) > img {
      max-width: ${em(166)};
    }
  }
`
