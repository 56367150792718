import { em } from 'polished'
import styled from 'styled-components'

const sizes = {
  '12/14': em(12),
  '14/20': em(14),
  '14/24': em(14),
  '16/20': em(16),
  '16/25': em(16),
  '18/24': em(18),
  '18/28': em(18),
  '20/25': em(20),
  '22/30': em(22),
  '25/30': em(25),
  '30/35': em(30),
  '30/40': em(30),
  '35/40': em(35),
  '50/60': em(50),
  '65/70': em(65),
  '80/90': em(80),
}

const lineHeights = {
  '12/14': 1.17,
  '14/20': 1.43,
  '14/24': 1.72,
  '16/20': 1.25,
  '16/25': 1.57,
  '18/24': 1.34,
  '18/28': 1.56,
  '20/25': 1.25,
  '22/30': 1.36,
  '25/30': 1.2,
  '30/35': 1.17,
  '30/40': 1.34,
  '35/40': 1.15,
  '50/60': 1.2,
  '65/70': 1.07,
  '80/90': 1.125,
}

const fontWeight = {
  bold: 700,
  medium: 500,
  regular: 400,
}

const transforms = {
  uppercase: 'uppercase',
  capitalize: 'capitalize',
  lowercase: 'lowercase',
}

const decorations = {
  underline: 'underline',
  'line-through': 'line-through',
}

export const StyledText = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ size }) => sizes[size]};
  line-height: ${({ size }) => lineHeights[size]};
  font-weight: ${({ weight }) => fontWeight[weight]};
  text-transform: ${({ transform }) => transforms[transform] ?? 'none'};
  text-decoration: ${({ decoration }) => decorations[decoration] ?? 'none'};
  white-space: ${({ nowrap }) => nowrap ? 'nowrap' : 'normal'};
  text-overflow: ${({ truncate }) => truncate ? 'ellipsis' : 'clip'};
  margin-bottom: 0;
`
