import PropTypes from 'prop-types'
import React from 'react'

import { Container, Title, Icon, Description, Report } from './styles'

function Card({
  title,
  description,
  reportText,
  icon,
  sharedPath,
  isLast,
  number,
}) {
  return (
    <Container isLast={isLast}>
      <Icon src={`${sharedPath}/icons/${icon}.png`} alt={`icon_${title}`} number={number} />

      <Title
        size={['18/24', '18/24', '16/20']}
        weight={'medium'}
        dangerouslySetInnerHTML={{ __html: title }}
      />

      <Description
        size={['16/20', '16/20', '14/20']}
        dangerouslySetInnerHTML={{ __html: description }}
      />

      <Report
        size={['16/22', '16/22', '14/20']}
        className={'h-color-D30'}
        dangerouslySetInnerHTML={{ __html: reportText }}
      />
    </Container>
  )
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  reportText: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  sharedPath: PropTypes.string.isRequired,
  isLast: PropTypes.bool.isRequired,
  number: PropTypes.number.isRequired,
}

export default Card
