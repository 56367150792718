import PropTypes from 'prop-types'
import React from 'react'

import htmlDecode from 'utils/htmlDecode'

import { Label, Input, ErrorMsg } from './styles.js'

const TextFieldRoundedInner = ({
  meta: { dirtySinceLastSubmit, touched, error },
  input: inputOptions,
  placeholder,
  submitErrors,
  forceSubmitError,
  isDisabled,
  ...inputProps
}) => {
  const placeholderDecoded = React.useMemo(() => htmlDecode(placeholder), [
    placeholder,
  ])
  const submitError =
    !dirtySinceLastSubmit && submitErrors
      ? submitErrors.find(error => error.field === inputOptions.name)
      : {}
  const needToShowError =
    !!(touched && error !== undefined) || !!(submitError && submitError.field)
  const submitErrorMessage =
    submitError && submitError.field
      ? forceSubmitError || submitError.message
      : null
  const errorMessage = error || submitErrorMessage

  return (
    <Label isError={needToShowError && !!errorMessage}>
      <Input
        placeholder={placeholderDecoded}
        disabled={isDisabled}
        {...inputOptions}
        {...inputProps}
      />

      {needToShowError && (
        <ErrorMsg dangerouslySetInnerHTML={{ __html: errorMessage }} />
      )}
    </Label>
  )
}

TextFieldRoundedInner.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  forceSubmitError: PropTypes.string,
  submitErrors: PropTypes.any,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
}

TextFieldRoundedInner.defaultProps = {
  isDisabled: false,
}

export default TextFieldRoundedInner
