import PropTypes from 'prop-types'
import React from 'react'

import {
  Container,
  Title,
  Description,
  Image,
} from './styles'
import bg from './assets/background.png'

const SubscriptionInfo = ({ texts }) => {
  const { title, description } = texts

  return (
    <Container>
      <Title
        forwardedAs={'h2'} size={['35/40', '35/40', '22/30']} weight={'medium'}
        dangerouslySetInnerHTML={{ __html: title }}
      />

      <Description
        size={['22/30', '22/30', '16/25']}
        dangerouslySetInnerHTML={{ __html: description }}
      />

      <Image src={bg} alt={'box_biota'} />
    </Container>
  )
}

SubscriptionInfo.propTypes = {
  texts: PropTypes.object.isRequired,
}

export default SubscriptionInfo
