import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { Dot, Wrapper, Svg, Spinner } from './styles'

const Pagination = ({ count, current, onChange, progress = 0 }) => {
  const dots = useMemo(() => Array(count).fill(null), [count])

  return (
    <Wrapper>
      {dots.map((_, index) => {
        const isActive = index === current
        const percent = isActive ? progress : 0

        return (
          <Dot
            key={index}
            onClick={() => onChange(index)}
            isActive={isActive}
            color="black"
          >
            <Svg
              isActive={isActive}
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              fill="none"
              viewBox="0 0 30 30"
            >
              <circle
                cx="15"
                cy="15"
                r="12"
                strokeWidth="6"
                opacity="0.4"
                strokeDasharray={152}
              />
              <Spinner
                cx="15"
                cy="15"
                r="12"
                strokeWidth="6"
                strokeDasharray={152 - (152 - 76) * percent}
              />
            </Svg>
          </Dot>
        )
      })}
    </Wrapper>
  )
}

Pagination.propTypes = {
  count: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default React.memo(Pagination)
