import { em } from 'polished'
import styled from 'styled-components'

import { BoxBase } from 'styles/components/layout'
import { Text } from 'shared/Typography'

export const Wrapper = styled(BoxBase)`
  ${p => p.theme.mq.downMd} {
    padding-left: 0;
    padding-right: 0;
  }

  ${p => p.theme.mq.upMd} {
    padding-bottom: ${em(80)};
  }
`

export const Container = styled.div`
  position: relative;
  min-height: ${em(650)};
  padding: ${em(40)} ${em(20)} ${em(30)};
  background-color: #141319;
  background-image: ${p => `url(${p.imagePath}/ebook_${p.countryCode}_mob.png)`};
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;

  ${p => p.theme.mq.upMd} {
    min-height: ${em(272)};
    border-radius: ${em(30)};
    background-color: #1D160E;
    background-image: ${p => `url(${p.imagePath}/ebook_${p.countryCode}.png)`};
    background-size: 40%;
    background-position: center right;
  }

  ${p => p.theme.mq.upLg} {
    background-size: contain;

  }
`

export const TitleBox = styled.div`
  margin: 0 auto ${em(30)};
  text-align: center;

  ${p => p.theme.mq.upMd} {
    text-align: left;
    margin: 0 0 ${em(54)};
  }
`

export const Title = styled(Text)`
  margin-bottom: ${em(10, 20)};
  color: var(--color-W100);

  span {
    color: var(--color-Y50);
  }

  ${p => p.theme.mq.upMd} {
    max-width: ${em(483, 35)};
    margin-bottom: ${em(20, 35)};
  }
`

export const Subtitle = styled(Text)`
  max-width: ${p => p.isSucceed ? `${em(356, 18)}` : '100%' };
  color: var(--color-W100);
`

export const CloseBox = styled.div`
  position: absolute;
  top: ${em(10)};
  right: ${em(10)};

  ${p => p.theme.mq.upMd} {
    top: ${em(20)};
    right: ${em(20)};
  }
`

export const ButtonSendAgain = styled.button`
  display: block;
  margin: ${em(10)} auto 0;
  text-align: center;
  cursor: pointer;
  align-self: center;

  ${p => p.theme.mq.upMd} {
    margin: auto 0 0;
    text-align: left;
  }
`

export const Disclaimer = styled(Text)`
  color: var(--color-W30);
  text-align: center;

  a {
    text-decoration: underline;
  }

  ${p => p.theme.mq.upMd} {
    max-width: ${em(550)};
    text-align: left;
  }
`
