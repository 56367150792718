import { em } from 'polished'
import styled from 'styled-components'

export const FormBox = styled.div`
  margin-bottom: 10px;

  ${p => p.theme.mq.upMd} {
    display: flex;
    margin-bottom: ${({ isInvalid }) => isInvalid ? `${em(25)}` : `${em(10)}`}
  }

  .subscribe-email-form__button {
    width: 100%;
    margin: 0 auto;

    ${p => p.theme.mq.upMd} {
      width: auto;
      margin: 0;
    }
  }
`

export const FieldBox = styled.div`
  margin-bottom: ${({ isInvalid }) => isInvalid ? `${em(25)}` : `${em(15)}`};

  ${p => p.theme.mq.upMd} {
    width: 100%;
    max-width: ${em(280)};
    margin-bottom: 0;
    margin-right: ${em(10)};
  }
`
