import PropTypes from 'prop-types'
import React from 'react'

import { GOODS_TYPES } from 'constants/sales'
import { Row } from 'styles/components/layout'

import TraitsListSections from '../TraitsListSections'

import { ColStyled, ButtonBox } from './styles'

const TraitsListMobile = props => {
  const { data, type, modalRef, cartButton } = props

  return (
    <>
      <Row>
        <ColStyled width={{ xs: 1 }}>
          <TraitsListSections
            modalRef={modalRef}
            sections={data[type].sections}
          />
        </ColStyled>
      </Row>
      <ButtonBox>{cartButton}</ButtonBox>
    </>
  )
}

TraitsListMobile.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.oneOf([GOODS_TYPES.dna, GOODS_TYPES.biome, GOODS_TYPES.wgs])
    .isRequired,
  modalRef: PropTypes.object,
  cartButton: PropTypes.element.isRequired,
}

export default TraitsListMobile
