import { em } from 'polished'
import styled from 'styled-components'

import { Bold, Text, TextDisclaimer } from 'styles/components/typography'

export const Wrapper = styled.div`
  padding-right: ${em(40)};

  // &:last-child{
  //   padding-right:
  // }

  ${p => p.theme.mq.upMd} {
    padding-right: 0;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StarsBox = styled.div`
  width: ${em(101)};
  margin-bottom: ${em(15)};
  flex-shrink: 0;

  & img {
    width: 100%;
    height: auto;
  }

  ${p => p.theme.mq.upMd} {
    width: ${em(117)};
    margin-bottom: ${em(20)};
  }
`

export const DateOfPublish = styled(TextDisclaimer)`
  display: none;
  opacity: 0.4;

  ${p => p.theme.mq.upLg} {
    display: block;
  }
`

export const Title = styled(Bold)`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const Description = styled(Text)`
  display: -webkit-box;
  min-height: ${p => 4 * p.theme.typography.baseLh}em;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const AuthorBox = styled.div`
  display: flex;
  align-items: center;
`

export const IconBox = styled.div`
  width: ${em(30)};
  height: ${em(30)};
  border-radius: 50%;
  background: ${p => p.theme.colors.mineShaft2};
  margin-right: ${em(12)};
  padding: ${em(6)};
  box-sizing: border-box;
`
