import { em } from 'polished'
import styled from 'styled-components'

import { Text } from 'styles/components/typography'

export const Wrapper = styled.div`
  position: relative;
  min-height: ${em(240)};
  padding-bottom: ${em(144)};
  margin-right: -${em(20)};
  padding-right: ${em(20)};
  overflow: hidden;

  .swiper-container {
    overflow: visible;
  }

  .trustpilot-mobile__arrows {
    position: absolute;
    bottom: ${em(10)};
    padding: 0 ${em(20)} 0 0;
    width: 100%;
  }
`

export const TextStyled = styled(Text)`
  display: block;
  color: ${p => p.theme.colors.blackOp4};
  position: absolute;
  bottom: ${p => em(64, p.theme.typography.fontSizeBasePx)};
  left: 0;
`
