import { em } from 'polished'
import styled from 'styled-components'

import DynamicImage from 'components/DynamicImage'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  border-radius: ${p => p.theme.blocks.blockBorderRadiusLarge};
  background: ${p => p.theme.colors.pictonBlue};
  margin-top: ${em(20)};
  padding: ${em(40)} ${em(20)};

  ${p => p.theme.mq.upMd} {
    margin-top: ${em(40)};
    padding-bottom: ${em(60)};
  }

  ${p => p.theme.mq.upLg} {
    padding-bottom: ${em(70)};
  }
`

export const BoxText = styled.div`
  max-width: ${em(380)};
  text-align: center;
  margin-bottom: ${em(40)};
`

export const DynamicImageStyled = styled(DynamicImage)`
  width: 100%;
  max-width: ${em(525)};
`

export const IconImg = styled.img`
  width: 100%;
  max-width: ${em(354)};
`
