import PropTypes from 'prop-types'
import React from 'react'

import ListItem from './components/ListItem'
import { Ul } from './styles'

/**
 * Component of List. It is wrapper for all list components
 * @return {Component} Component — ListItem
 */
const List = ({ children, textColor, leftAlignment, className }) => {
  return <Ul {...{ className, leftAlignment, textColor }}>{children}</Ul>
}

List.propTypes = {
  /** ListItems array */
  children: PropTypes.array,

  /** Object of left alignment */
  leftAlignment: PropTypes.oneOf(['text', false]),

  /** Class for list wrapper node */
  className: PropTypes.string,

  /** Color of text */
  textColor: PropTypes.oneOf(['dark', 'white']),
}
List.defaultProps = {
  children: '',
  leftAlignment: false,
  className: '',
  textColor: 'dark',
}

List.Item = ListItem

export default List
