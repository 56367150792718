import city_am from './city_AM.svg'
import express from './express.svg'
import cnbc from './cnbc.svg'
import telegraph from './telegraph.svg'
import mirror from './mirror.svg'
import theguardian from './the_guardian.svg'

export const brandIcons = {
  express,
  'city-am': city_am,
  cnbc,
  theguardian,
  mirror,
  telegraph,
}
