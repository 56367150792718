import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'
import Head from 'next/head'

import Publications from 'components/_special/Publications'
import StoriesSlider from 'components/StoriesSlider'
import WillLearn from 'components/WillLearn'
import { RU, UK, IE, US } from 'constants/countries'
import { getTrackingCode } from 'constants/retargeting'
import { COUNTRIES_WITHOUT_EMAIL_SUBSCRIPTION, GOODS_TYPES } from 'constants/sales'
import DistributionBannerContainer from 'containers/DistributionBannerContainer'
import { LayoutContext } from 'contexts/Layout'
import { LocalizationContext } from 'contexts/Localization'
import { ResizeConsumer } from 'contexts/Resize'
import { useTraitsList } from 'modules/Home/hooks/useTraitsList'
import { modalTraitsList } from 'routes'
import { LastBlock } from 'styles/components/layout'
import { OrderTestWidget } from 'widgets/OrderTestWidget'
import { HowItWorks } from 'widgets/HowItWorks'
import { CuttingEdge } from 'widgets/CuttingEdge'
import { BlogSection } from 'widgets/BlogSection'
import { Protection } from 'widgets/Protection'

import AboutTests from './components/AboutTests'
import OtherProjects from './components/OtherProjects'
import Carousel from './components/Carousel'
import SpecialFeatures from './components/SpecialFeatures'
import SubscribeEbook from './components/SubscribeEbook'
import UsSubscribeEbook from './components/UsSubscribeEbook'
import SubscribeEmail from './components/SubscribeEmail'
import TakeCare from './components/TakeCare'
import TraitsList from './components/TraitsList'
import GutBacteria from './components/GutBacteria'
import BuzzBugs from './components/BuzzBugs'
import FaqHome from './components/FaqHome'
import Blog from './components/Blog'
import PublicationsNew from './components/PublicationsNew'
import Wellbeing from './components/Wellbeing'

import { TrustpilotReviewsStyled } from './styles'

const Home = ({
  texts,
  language,
  publications,
  stories,
  carousel: carouselTranslations,
  locale,
  pathCdn,
  pathGlobal,
  pathShared,
  queryParams,
  router,
}) => {
  const {
    dict,
    dict: {
      links: { blog },
    },
    specialDistribution,
    withoutSales,
    currentCountry,
  } = React.useContext(LocalizationContext)
  const { setFooterBg } = React.useContext(LayoutContext)
  const { colors } = React.useContext(ThemeContext)
  const [carouselRenderSource, setCarouselRenderSource] = useState('server')
  const { closeTraitsModal, isTraitsOpened } = useTraitsList({
    locale,
    queryParams,
    router,
  })
  const server = process.env.server

  const isUk = locale === UK
  const isUs = locale === US

  const {
    biome: microbiome,
    wgs,
    dna,
    base_phrase: basePhrase,
    base_phrase_lg: basePhraseLg,
    testNames,
  } = texts.first.slider

  useEffect(() => {
    setFooterBg(colors.white)

    return () => {
      setFooterBg(null)
    }
  }, [colors.white, setFooterBg])

  const showEbookSubsciption = React.useMemo(
    () => locale === UK || locale === IE,
    [locale],
  )

  const showEmailSubscription = React.useMemo(
    () =>
      !showEbookSubsciption &&
      !specialDistribution &&
      !COUNTRIES_WITHOUT_EMAIL_SUBSCRIPTION.includes(locale),
    [locale, specialDistribution, showEbookSubsciption],
  )

  useEffect(() => {
    setCarouselRenderSource('client')
  }, [])

  return (
    <>
      {server.MERCHANT_ID && (
        <Head>
          <script
            type='text/javascript'
            dangerouslySetInnerHTML={{ __html: getTrackingCode('/', server.MERCHANT_ID) }}
          />
        </Head>
      )}

      <Carousel
        key={`Carousel-${carouselRenderSource}-render`}
        withoutSales={withoutSales}
        locale={locale}
        language={language}
        path={pathShared}
        translations={carouselTranslations}
        buttonTranslations={{
          outOfStock: dict.buttons.out_of_stock,
          buy: dict.buttons.buy,
        }}
        defaultContent={{
          microbiome,
          wgs,
          dna,
          basePhrase,
          basePhraseLg,
          testNames,
        }}
      />

      {isUs && (
        <>
          <GutBacteria texts={texts.gut_bacteria_section} sharedPath={pathShared} />

          <BuzzBugs texts={texts.buzz_bugs_section} />

          <Wellbeing texts={texts.wellbeing_section} commonTexts={dict} />

          <HowItWorks className='mb-6' texts={dict.how_it_works_section} />

          <PublicationsNew publications={publications} />

          <FaqHome texts={texts.faq} />

          <UsSubscribeEbook
            texts={texts.subscribe_ebook}
            errorsDict={dict.errors}
            sharedPath={pathShared}
            countryCode={currentCountry.code}
          />

          <Blog texts={texts.blog_new} blogLink={blog} />

          <OrderTestWidget
            className='py-md-6'
            productType={GOODS_TYPES.biome}
          />
        </>
      )}

      {!isUs && (
        <>
          {isUk && <TrustpilotReviewsStyled />}

          {showEbookSubsciption && !isUk && (
            <div className='mt-2 mt-md-4'>
              <SubscribeEbook
                texts={texts.subscribe_ebook}
                errorsDict={dict.errors}
                sharedPath={pathShared}
                countryCode={currentCountry.code}
              />
            </div>
          )}

          {showEmailSubscription && (
            <SubscribeEmail
              texts={texts.subscribe_email}
              errorsDict={dict.errors}
              sharedPath={pathShared}
              countryCode={currentCountry.code}
            />
          )}

          <AboutTests />

          {showEbookSubsciption && isUk && (
            <div className='mb-2 mb-md-4 mb-lg-0 mt-lg-4'>
              <SubscribeEbook
                texts={texts.subscribe_ebook}
                errorsDict={dict.errors}
                sharedPath={pathShared}
                countryCode={currentCountry.code}
              />
            </div>
          )}

          <div className='mb-4 mb-md-6'>
            <ResizeConsumer>
              {({ mobile, tablet }) => (
                <WillLearn
                  data={texts.will_learn}
                  mobile={mobile}
                  tablet={tablet}
                  link={modalTraitsList.link(locale)}
                  asPath={modalTraitsList.as(locale)}
                />
              )}
            </ResizeConsumer>
          </div>

          <div className='pb-1 pb-md-0'>
            <SpecialFeatures
              data={texts.features}
              mediaPath={`${pathCdn}/features_150921`}
            />
          </div>

          <CuttingEdge />

          {locale === RU && <OtherProjects data={texts.other_projects} />}

          <BlogSection />

          <Protection />

          <ResizeConsumer>
            {({ mobile }) =>
              stories &&
              stories.list && (
                <StoriesSlider
                  slides={stories.list}
                  isMobile={mobile}
                  path={pathGlobal}
                  locale={locale}
                />
              )
            }
          </ResizeConsumer>

          {publications && publications.list && (
            <ResizeConsumer>
              {({ mobile }) => (
                <Publications
                  isMobile={mobile}
                  list={publications.list}
                  logos={`${pathShared}/publications_logo`}
                />
              )}
            </ResizeConsumer>
          )}

          <LastBlock>
            <TakeCare data={texts.take_care} />

            <DistributionBannerContainer />
          </LastBlock>

          <TraitsList isOpen={isTraitsOpened} handleClose={closeTraitsModal} />
        </>
      )}
    </>
  )
}

Home.propTypes = {
  language: PropTypes.string.isRequired,
  pathCdn: PropTypes.string.isRequired,
  pathGlobal: PropTypes.string.isRequired,
  pathShared: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  texts: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  queryParams: PropTypes.object,
  publications: PropTypes.object,
  stories: PropTypes.object,
  carousel: PropTypes.object,
}

export default Home
