import PropTypes from 'prop-types'
import React from 'react'
import { Field, Form, FormSpy } from 'react-final-form'

import {
  composeValidators,
  errorCustom,
  errorFromDict,
  isEmail,
  required,
} from 'forms/validations'
import TextFieldRounded from 'components/_form/TextFieldRounded'
import { subscribeEmail } from 'utils/api'
import { gtmEvent } from 'utils/client/analitics'

import {
  FormBox,
  FieldBox,
  ButtonStyled,
} from './styles'

const SUBSCRIBE_FORM = 'subscribe_ebook'

const SubscribeEbookForm = ({
  errorsDict,
  texts,
  onSubmited,
  isMobile,
  isSucceed,
  setIsSucceed,
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [isInvalid, setIsInvalid] = React.useState(false)
  const isSubmitted = isSucceed || isLoading

  const handleSubmit = React.useCallback(
    ({ email }) => {
      if (isLoading) {
        return
      }

      setIsLoading(true)

      subscribeEmail({
        email,
        list: '56528cb6cc',
        tags: ['(LM) MB EBOOK LP MAIN'],
      })
        .then(resp => {
          if (
            typeof resp === 'object' &&
            'subscribed' in resp &&
            resp.subscribed === true
          ) {
            setIsLoading(false)
            setIsSucceed(true)
            gtmEvent({
              event: 'Block Ebook Subscribe — Leave Email — Success',
            })
            onSubmited && onSubmited()
          } else {
            setIsLoading(false)
            setIsSucceed(false)
            gtmEvent({
              event: 'Block Ebook Subscribe — Leave Email — Error',
              description: resp.error,
            })
          }
        })
        .catch(err => {
          setIsLoading(false)
          setIsSucceed(false)
          gtmEvent({
            event: 'Block Ebook Subscribe — Leave Email — Error',
            description: JSON.stringify(err?.response),
          })
        })
    },
    [isLoading, onSubmited],
  )

  const handleRemoteSubmit = React.useCallback(() => {
    document
      .getElementById(SUBSCRIBE_FORM)
      .dispatchEvent(new Event('submit', { cancelable: true }))

    return !isInvalid
  }, [isInvalid])

  return (
    <FormBox isInvalid={isInvalid}>
      <FieldBox isInvalid={isInvalid}>
        <Form
          onSubmit={handleSubmit}
          render={({ handleSubmit, submitting }) => (
            <form id={SUBSCRIBE_FORM} onSubmit={handleSubmit}>
              <TextFieldRounded
                autoComplete='off'
                placeholder={texts.placeholder}
                name='email'
                isDisabled={isSubmitted}
                validate={composeValidators([
                  errorCustom(errorsDict.email_required, required),
                  errorFromDict(errorsDict, isEmail),
                ])}
              />

              <Field
                name='submit'
                type='submit'
                component='input'
                hidden={true}
                disabled={submitting}
              />

              <FormSpy
                subscription={{
                  invalid: true,
                  touched: true,
                }}
                onChange={({ invalid, touched }) => {
                  if (touched.email) {
                    setIsInvalid(invalid)
                  }
                }}
              />
            </form>
          )}
        />
      </FieldBox>

      <ButtonStyled
        onClick={handleRemoteSubmit}
        loading={isLoading}
        color='violet'
        block={isMobile}
      >
        {texts.button}
      </ButtonStyled>
    </FormBox>
  )
}

SubscribeEbookForm.propTypes = {
  isSucceed: PropTypes.bool.isRequired,
  setIsSucceed: PropTypes.func.isRequired,
  texts: PropTypes.object.isRequired,
  errorsDict: PropTypes.object.isRequired,
  onSubmited: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
}

export default SubscribeEbookForm
