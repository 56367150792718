import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { useState } from 'react'

let portalContainer
const portalAttribute = 'sr-portal-container'

const Portal = React.forwardRef((props, ref) => {
  const { children } = props
  const [isContainerMounted, setIsContainerMounted] = useState(false)

  const portal = React.useMemo(() => {
    if (typeof window === 'undefined') {
      return null
    }

    if (portalContainer) {
      ref.current = portalContainer
      return portalContainer
    }

    const el = document.querySelector(`div[${portalAttribute}]`)

    if (el) {
      portalContainer = el
      ref.current = portalContainer
      return portalContainer
    }

    portalContainer = document.createElement('div')
    portalContainer.setAttribute(portalAttribute, '')
    ref.current = portalContainer
    return portalContainer
  }, [])

  React.useEffect(() => {
    if (!portal) return

    document.body.appendChild(portal)

    setIsContainerMounted(true)
  }, [portal])

  if (typeof window === 'undefined') {
    return null
  }

  if (portal && isContainerMounted) {
    return ReactDOM.createPortal(children, portal)
  }

  return null
})

export default React.memo(Portal)
