import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import useMounted from './useMounted'
import { getSales } from 'selectors'

/**
 * @param {string} locale
 * @returns {{goods: null | object, currency: string}}
 */

export default function useGoods(locale) {
  const [goods, setGoods] = useState(null)
  const [currency, setCurrency] = useState('')
  const mounted = useMounted()
  const sales = useSelector(getSales)
  const salesGoods = sales?.goods

  useEffect(() => {
    if (mounted.current && salesGoods) {
      setCurrency(sales?.currency)

      const goods = Object.entries(salesGoods).reduce(
        (result, [name, goodsItem]) => {
          result[goodsItem.code] = {
            id: goodsItem.id,
            name,
            code: goodsItem.code,
            price: goodsItem.price,
            currentPrice: goodsItem.currentPrice,
            discount: goodsItem.discount,
          }
          return result
        },
        {},
      )

      setGoods(goods)
    }
  }, [locale, salesGoods])

  return {
    goods,
    currency,
  }
}
