import { em, math } from 'polished'
import styled from 'styled-components'

import { H3 } from 'styles/components/typography'

export const TitleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: ${em(40)};
  margin-bottom: ${em(30)};

  ${p => p.theme.mq.upMd} {
    margin-top: ${em(50)};
  }
`

export const H3Styled = styled(H3)`
  max-width: 50vw;
  margin-bottom: 0;

  ${p => p.theme.mq.upMd} {
    display: inline;
  }
`

export const LineWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  min-height: 1px;
  flex-grow: 1;
  flex-basis: 1px;

  ${p => p.theme.mq.upMd} {
    order: 1;
  }
`

export const Line = styled.div`
  margin-right: ${em(20)};
  margin-left: ${em(20)};

  ${p => p.theme.mq.upMd} {
    margin-right: 0;
    margin-right: -${p => p.theme.indents.baseIndentMd};
  }

  ${p => p.theme.mq.upLg} {
    margin-right: -${p => math(`${p.theme.indents.baseBoxIndentLg} * 3 / 4`)};
  }

  &:before {
    content: '';
    height: 1px;
    background-color: ${p => p.theme.colors.blackOp07};
    display: block;
  }
`

export const IconWrapper = styled.div`
  ${p => p.theme.mq.upMd} {
    padding-left: ${em(20)};
  }
`

export const IconBox = styled.div`
  width: ${em(60)};
  height: ${em(60)};
`
