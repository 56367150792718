import { em } from 'polished'
import styled from 'styled-components'

const bulletFontSize = 16
const bulletFontSizeMd = 19
export const bulletWidth = 15
export const bulletWidthMd = 18
const bulletWidthEm = em(bulletWidth, bulletFontSize)
const bulletWidthEmMd = em(bulletWidthMd, bulletFontSizeMd)

const chooseColor = function(colorCode, colors) {
  switch (colorCode) {
    case 'white':
      return colors.white
    case 'green':
      return colors.grass
    case 'blue':
      return colors.pictonBlue
    case 'dark-blue':
      return colors.blueDark
    case 'purple':
      return colors.lilacDark
    case 'black':
    default:
      return colors.black
  }
}

export const Li = styled.li`
  position: relative;
  padding-left: ${em(bulletWidth)};

  &:before {
    content: '•';
    display: inline-block;
    position: relative;
    border-radius: 50%;
    line-height: 1;
    color: ${p => chooseColor(p.bulletColor, p.theme.colors)};
    font-size: ${em(bulletFontSize)};
    width: ${bulletWidthEm};
    margin-left: -${bulletWidthEm};
  }

  ${p => p.theme.mq.upMd} {
    padding-left: ${em(bulletWidthMd)};
    &:before {
      font-size: ${em(bulletFontSizeMd)};
      width: ${bulletWidthEmMd};
      margin-left: -${bulletWidthEmMd};
    }
  }

  &:not(:last-child) {
    margin-bottom: ${p => parseFloat(p.theme.indents.paragraphIndent) / 4}em;

    ${p => p.theme.mq.upMd} {
      margin-bottom: ${p => parseFloat(p.theme.indents.paragraphIndent) / 2}em;
    }

    ${p => p.theme.mq.upLg} {
      margin-bottom: ${p => p.theme.indents.paragraphIndent};
    }
  }
`
