import styled from 'styled-components'
import { em } from 'polished'

import { Text } from 'shared/Typography'
import { BoxBase } from 'styles/components/layout'

export const Wrapper = styled.div`
  padding-top: ${em(50)};
  padding-bottom: ${em(60)};

  ${({ theme }) => theme.mq.upLg} {
    padding-top: ${em(80)};
    padding-bottom: ${em(80)};
  }
`

export const Container = styled(BoxBase)`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mq.upLg} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: ${em(60)};
  }
`

export const Image = styled.img`
  display: block;
  margin-bottom: ${em(20)};

  ${({ theme }) => theme.mq.downLg} {
    width: 100%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  ${({ theme }) => theme.mq.upLg} {
    max-width: 47%;
    margin-bottom: 0;
  }
`

export const Content = styled.div`
  ${({ theme }) => theme.mq.upLg} {
    max-width: ${em(537)};
  }
`

export const Title = styled(Text)`
  margin-bottom: 10px;

  span {
    color: var(--color-V50);
  }

  ${({ theme }) => theme.mq.upLg} {
    margin-bottom: ${em(20, 50)};
  }
`

export const Description = styled(Text)`
  margin-bottom: ${em(30, 16)};

  p:first-child {
    margin-bottom: ${em(15, 16)};

    ${({ theme }) => theme.mq.upLg} {
      margin-bottom: ${em(30, 22)};
    }
  }


  ${({ theme }) => theme.mq.upLg} {
    margin-bottom: ${em(50, 22)};
  }
`

export const ButtonBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > button {
    margin-bottom: ${em(20)};

    ${({ theme }) => theme.mq.upLg} {
      margin-right: ${em(17)};
      margin-bottom: 0;
    }
  }

  ${({ theme }) => theme.mq.upLg} {
    flex-direction: row;
    align-items: center;
  }
`

export const ReadMore = styled.div`
  display: flex;
  gap: ${em(7)};
  align-items: center;

  button:first-of-type {
    height: auto;
    padding: 0;
    font-weight: 700;
    text-decoration: underline;
  }
`

export const SubscribeText = styled(Text)`
  span {
    color: var(--color-P50);
  }
`
