import { em, math } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  box-sizing: content-box;
  height: ${({ layout }) => layout === 'promo' ? em(580) : em(498)};
  padding-top: ${p => p.theme.blocks.menuHeight};
  transition: color ease 150ms;

  ${p => p.theme.mq.upMd} {
    height: ${em(830)};
    padding-top: ${({ theme, hasSalesBanner, indentHeight }) =>
      hasSalesBanner
        ? math(
          `${theme.blocks.menuHeightMd} + ${
            indentHeight?.md ? em(indentHeight.md) : theme.blocks.menuHeightMd
          }`,
        )
        : math(`${theme.blocks.menuHeightMd}`)};
  }

  ${p => p.theme.mq.upLg} {
    height: ${em(666)};
    padding-top: ${({ theme, hasSalesBanner, indentHeight }) =>
      hasSalesBanner
        ? math(
          `${theme.blocks.menuHeightMd} + ${
            indentHeight?.lg ? em(indentHeight.lg) : theme.blocks.menuHeightMd
          }`,
        )
        : math(`${theme.blocks.menuHeightMd}`)};
  }

  .swiper-container {
    height: 100%;
  }
`

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

export const PaginationBox = styled.div`
  position: absolute;
  z-index: 11;
  bottom: ${em(20)};
  left: 50%;
  transform: translateX(-50%);

  ${p => p.theme.mq.upMd} {
    bottom: ${em(41)};
  }

  ${p => p.theme.mq.upLg} {
    transform: translateX(0);
    bottom: ${em(60)};
    left: ${p => p.theme.indents.baseBoxIndentLg};
  }
`
