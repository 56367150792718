import PropTypes from 'prop-types'
import React from 'react'

import { Container, Brands } from './styles'
import { brandIcons } from './assets'

const PublicationsNew = ({ publications, className }) => {
  const { list } = publications
  return (
    <Container className={className}>
      <Brands>
        {list.map(({ link, logo }) => (
          <a key={link} href={link} target='_blank' rel='noopener noreferrer'>
            <img src={brandIcons[logo]} alt='image' />
          </a>
        ))}
      </Brands>
    </Container>
  )
}

PublicationsNew.propTypes = {
  publications: PropTypes.object.isRequired,
  className: PropTypes.string,
}

export default PublicationsNew
