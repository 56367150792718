import PropTypes from 'prop-types'
import React, { useState } from 'react'

import Accordion from './components/Accordion'
import { Wrapper, Container, Title, AccordionsContainer } from './styles'

const FaqHome = ({ texts, className }) => {
  const { title, accordion } = texts
  const [openedAccordionIndex, setOpenedAccordionIndex] = useState(null)

  return (
    <Wrapper className={className}>
      <Container>
        <Title size={['50/60', '50/60', '35/40']} weight='medium'>
          {title}
        </Title>

        <AccordionsContainer>
          {accordion.map(({ title, content }, index) => (
            <Accordion
              index={index}
              isOpen={index === openedAccordionIndex}
              setIsOpen={setOpenedAccordionIndex}
              key={title}
              title={title}
              content={content}
            />
          ))}
        </AccordionsContainer>
      </Container>
    </Wrapper>
  )
}

FaqHome.propTypes = {
  texts: PropTypes.object.isRequired,
  className: PropTypes.string,
}

export default FaqHome
