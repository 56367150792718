import styled from 'styled-components'

export const Container = styled.span`
  display: inline-flex;
  animation: rotate 1.2s infinite linear;
`

export const Svg = styled.svg`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  color: var(--color-W30);
  height: 25px;
  fill: var(--color-W100);
  animation: spin 1.5s infinite linear;
`

