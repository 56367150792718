import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import UpsaleCardNarrow from 'components/_special/UpsaleCardNarrow'
import { GOODS_TYPES } from 'constants/sales'
import useSaleCard from 'hooks/useSaleCard'
import { dnaAbout, biomeAbout, wgsAbout } from 'routes'
import { LocalizationContext } from 'contexts/Localization'

const CartSaleNarrowContainer = ({ productType, isVisible }) => {
  const PRODUCTS_LINKS = {
    [GOODS_TYPES.dna]: dnaAbout,
    [GOODS_TYPES.biome]: biomeAbout,
    [GOODS_TYPES.wgs]: wgsAbout,
  }
  const { commonUpsaleProps, dict, locale } = useSaleCard({
    productType,
    isSubscription: false,
  })
  const { sales } = useContext(LocalizationContext)
  const isZeroPrice =
    sales?.goods?.[productType]?.price === 0 ||
    sales?.goods?.[productType]?.currentPrice === 0 ||
    sales?.goods?.[productType]?.priceWebsite === 0

  return (
    <UpsaleCardNarrow
      {...{
        ...commonUpsaleProps,
        isVisible,
        title: dict.kits[productType].title,
        titleLink: PRODUCTS_LINKS[productType].link(locale),
        titleLinkAs: PRODUCTS_LINKS[productType].as(locale),
        isItDna: productType === GOODS_TYPES.dna,
        productType,
        isDisabled: commonUpsaleProps.isDisabled || isZeroPrice,
        buttonTitle: isZeroPrice ? dict.buttons.out_of_stock : commonUpsaleProps.buttonTitle,
        buttonLinkTitle: isZeroPrice ? dict.buttons.out_of_stock : commonUpsaleProps.buttonLinkTitle,
      }}
    />
  )
}

CartSaleNarrowContainer.propTypes = {
  productType: PropTypes.oneOf([
    GOODS_TYPES.dna,
    GOODS_TYPES.biome,
    GOODS_TYPES.wgs,
  ]).isRequired,
  isVisible: PropTypes.bool.isRequired,
}

export default React.memo(CartSaleNarrowContainer)
