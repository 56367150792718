import PropTypes from 'prop-types'
import React from 'react'
import { useInView } from 'react-intersection-observer'

import Sentry from 'utils/sentry'

import { Media } from './styles'

const CuttingMedia = ({ src }) => {
  const mediaRef = React.useRef()
  const playPromise = React.useRef()
  const [ref, inView] = useInView({
    threshold: 0,
  })

  React.useEffect(() => {
    if (inView) {
      try {
        playPromise.current = mediaRef.current?.play()
      } catch (e) {
        Sentry.captureErrorWithScope(e, {
          tip: 'ERROR FROM PLAY VIDEO COMPONENT',
        })
      }
    } else {
      if (playPromise.current) {
        playPromise.current.then(() => {
          playPromise.current = undefined
          mediaRef.current?.pause()
        })
      } else {
        mediaRef.current?.pause()
      }
    }
  }, [inView])

  return (
    <div className="position--full" ref={ref}>
      <Media ref={mediaRef} playsInline={true} muted={true} loop={true}>
        <source src={src} type="video/mp4" />
      </Media>
    </div>
  )
}

CuttingMedia.propTypes = {
  src: PropTypes.string.isRequired,
}

CuttingMedia.defaultProps = {}

export default CuttingMedia
