import { em, math } from 'polished'
import styled from 'styled-components'

const compSize = em(300)
const compSizeMd = em(380)
const compSizeLg = em(420)
const indent = em(20)
const indentMd = em(30)

export const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: ${compSize};
  height: ${compSize};
  max-width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  ${p => p.theme.mq.upMd} {
    width: ${compSizeMd};
    height: ${compSizeMd};
  }

  ${p => p.theme.mq.upLg} {
    width: ${compSizeLg};
    height: ${compSizeLg};
  }
`

export const Circle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: ${p => p.bgColor || p.theme.colors.white};
  width: ${math(`${compSize} - ${indent} * 2`)};
  height: ${math(`${compSize} - ${indent} * 2`)};

  ${p => p.theme.mq.upMd} {
    width: ${math(`${compSizeMd} - ${indentMd} * 2`)};
    height: ${math(`${compSizeMd} - ${indentMd} * 2`)};
  }

  ${p => p.theme.mq.upLg} {
    width: ${math(`${compSizeLg} - ${indentMd} * 2`)};
    height: ${math(`${compSizeLg} - ${indentMd} * 2`)};
  }
`

export const Icon = styled.div`
  background: url(${p => p.background}) center 10% / contain no-repeat;
  transform: translateY(${em(-10)});
  width: ${em(127)};
  height: ${em(180)};

  ${p => p.theme.mq.upMd} {
    transform: translateY(${em(-18)});
    width: ${em(160)};
    height: ${em(228)};
  }

  ${p => p.theme.mq.upLg} {
    transform: translateY(${em(-14)});
    width: ${em(178)};
    height: ${em(253)};
  }
`
