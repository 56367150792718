import PropTypes from 'prop-types'
import React from 'react'

import TraitsListSections from '../TraitsListSections'

import { H1Styled } from './styles'

const TraitsListOfKit = React.forwardRef(
  ({ data, modalRef, titleRef }, ref) => (
    <div ref={ref}>
      <H1Styled
        ref={titleRef}
        dangerouslySetInnerHTML={{ __html: data.title }}
      />
      <TraitsListSections modalRef={modalRef} sections={data.sections} />
    </div>
  ),
)

TraitsListOfKit.propTypes = {
  data: PropTypes.object.isRequired,
  modalRef: PropTypes.object,
  titleRef: PropTypes.object,
}

export default TraitsListOfKit
