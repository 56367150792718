import PropTypes from 'prop-types'
import React from 'react'

import TypedText from 'modules/Home/components/Carousel/components/TypedText'
import { Text } from 'shared/Typography'
import classNames from 'classnames'
import { IT, JP } from 'constants/countries'
import AddAndGoToCart from 'containers/AddAndGoToCart'
import { Button } from 'shared/Button'

import { Content, Header, Description, DiscountBadge, Footer, OldPrice, Price, Buttons } from './styles'

const UsLayout = ({
  description,
  discount,
  scrollToProducts,
  words,
  isBlackColor,
  price,
  currentPrice,
  // buttonDemoText,
  buttonPurchaseText,
  buttonScrollText,
  actionType,
  isDefaultWgs,
  currentGoodsItem,
  locale,
  currentSlide,
  defaultContent,
  mobile,
  tablet,
  isZeroPrice,
}) => {
  return (
    <Content>
      <Header>
        <TypedText
          markColor={currentSlide?.styles.markColor}
          basePhrase={(
            mobile || tablet) ? defaultContent.basePhrase : defaultContent.basePhraseLg + ' '}
          words={words}
          locale={locale}
          currentSlide={currentSlide}
          defaultContent={defaultContent}
          mobile={mobile}
          tablet={tablet}
        />

        {description && (
          <Description
            forwardedAs='p'
            size={['18/28', '18/28', '16/25']}
            linkColor={currentSlide?.styles.markColor}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </Header>

      <Footer buttonBg={currentSlide?.styles?.buttonBg}>
        {actionType === 'purchase' && currentGoodsItem && (
          <>
            {currentGoodsItem.price !== currentGoodsItem.currentPrice && !isZeroPrice && (
              <OldPrice>
                <Text
                  className={classNames({
                    'h-color-D50': isBlackColor || isDefaultWgs,
                    'h-color-W50': !isBlackColor && !isDefaultWgs,
                  })}
                  as='h5'
                  weight='medium'
                  size={mobile ? '18/24' : '22/30'}
                  decoration='line-through'
                >
                  {price}
                </Text>

                {discount !== 0 && (
                  <DiscountBadge
                    size={['14/20', '14/20', '12/14']}
                    weight='medium'
                    bg={currentSlide.styles.badgeBg}
                    color={currentSlide.styles.badgeColor}
                  >
                    -{discount}%
                  </DiscountBadge>
                )}
              </OldPrice>
            )}

            <Price>
              {![JP, IT].includes(locale) && !isZeroPrice && (
                <Text
                  className={classNames({
                    'h-color-D100': isDefaultWgs,
                  })}
                  size={mobile ? '25/30' : '50/60'}
                  weight='medium'
                >
                  {currentPrice}
                </Text>
              )}
            </Price>

            <Buttons>
              {/*<Button variant={'outlined'} color={'white'} size={mobile ? 'mobile' : 'desktop'} href='/demo-link'>*/}
              {/*  {buttonDemoText}*/}
              {/*</Button>*/}

              <AddAndGoToCart
                key={currentSlide?.goodsType}
                goods={[{
                  type: currentSlide?.goodsType === 'microbiome' ? 'biome' : currentSlide?.goodsType,
                  isSubscription: false,
                }]}
                buttonProps={{
                  color: currentSlide?.styles.buttonBg, size: mobile ? 'mobile' : 'desktop',
                  disabled: isZeroPrice,
                }}
                ButtonComponent={Button}
                forceMultipleAdd
              >
                {buttonPurchaseText}
              </AddAndGoToCart>
            </Buttons>
          </>
        )}

        {actionType === 'scroll' && (
          <Button
            color={currentSlide?.styles.buttonBg}
            size={mobile ? 'mobile' : 'desktop'}
            onClick={scrollToProducts}
          >
            {buttonScrollText}
          </Button>
        )}
      </Footer>
    </Content>
  )
}

UsLayout.propTypes = {
  description: PropTypes.string.isRequired,
  discount: PropTypes.number.isRequired,
  price: PropTypes.string.isRequired,
  currentPrice: PropTypes.string.isRequired,
  words: PropTypes.array.isRequired,
  actionType: PropTypes.string.isRequired,
  buttonDemoText: PropTypes.string.isRequired,
  buttonPurchaseText: PropTypes.string.isRequired,
  buttonScrollText: PropTypes.string,
  locale: PropTypes.string.isRequired,
  currentSlide: PropTypes.object.isRequired,
  currentGoodsItem: PropTypes.object,
  defaultContent: PropTypes.object.isRequired,
  isDefaultWgs: PropTypes.bool.isRequired,
  mobile: PropTypes.bool.isRequired,
  tablet: PropTypes.bool.isRequired,
  isBlackColor: PropTypes.bool.isRequired,
  scrollToProducts: PropTypes.func.isRequired,
  isZeroPrice: PropTypes.bool.isRequired,
}

export default UsLayout
