import { em } from 'polished'
import styled from 'styled-components'

import ControlSliderArrows from 'components/ControlSliderArrows'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-height: ${p => p.theme.blocks.heightBlockSquares};
  visibility: hidden;

  ${p => p.theme.mq.upMd} {
    visibility: visible;
    padding-bottom: ${em(80)};
  }

  .swiper-wrapper {
    height: auto !important;
  }
`

export const ControlSliderArrowsStyled = styled(ControlSliderArrows)`
  position: absolute;
  bottom: ${p => p.theme.indents.baseIndentMd};
  left: 50%;
  transform: translateX(-50%);
`

export const Slide = styled.div`
  min-height: ${em(400)};

  ${p => p.theme.mq.upMd} {
    min-height: auto;
    height: auto !important;
  }
`
