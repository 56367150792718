import PropTypes from 'prop-types'
import React from 'react'

import SliderMobile from 'components/SliderMobile'
import { LocalizationContext } from 'contexts/Localization'

import TrustpilotReview from '../TrustpilotReview'

import { TextStyled, Wrapper } from './styles'

const TrustpilotSliderMobile = ({ list }) => {
  const { dict } = React.useContext(LocalizationContext)

  if (!list?.length) {
    return null
  }

  return (
    <Wrapper>
      <SliderMobile swiperArrowsClassName="trustpilot-mobile__arrows">
        {list.map(review => (
          <div key={review.id}>
            <TrustpilotReview {...review} />
          </div>
        ))}
      </SliderMobile>
      <TextStyled
        dangerouslySetInnerHTML={{ __html: dict.trustpilot.subtitle }}
      />
    </Wrapper>
  )
}

TrustpilotSliderMobile.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string,
      createdAt: PropTypes.string,
      stars: PropTypes.number,
      consumer: PropTypes.shape({
        displayName: PropTypes.string,
      }),
    }),
  ),
}

export default React.memo(TrustpilotSliderMobile)
