import { em, math } from 'polished'
import styled, { css } from 'styled-components'

import DynamicImage from 'components/DynamicImage'
import { RU } from 'constants/countries'
import { COUNTRIES_WITHOUT_DNA } from 'constants/sales'
import { TextLHXs } from 'styles/components/typography'
import { blackOp1, catskillWhite, black } from 'styles/theme/colors'

const mobileIndents = em(7)

export const TraitsListHeadTabWrapper = styled.div`
    cursor: pointer;
    transition: background-color ${p => p.theme.animation.timeStandard};
    display: inline-block;
    flex-grow: 1;
    flex-shrink: 0;
    width: calc(33% - ${math(`${mobileIndents} * 2`)});
    height: 100%;
    margin: 0 ${mobileIndents};
    padding-top: ${em(13)};

    ${({ isActive }) =>
      isActive &&
      css`
        font-family: ${p => p.theme.typography.mediumFont};
        border-bottom: 2px solid ${black};

        ${p => p.theme.mq.upMd} {
          font-family: ${p => p.theme.typography.baseFont};
          border-bottom: none;
          background-color: ${catskillWhite};
        }
      `}

    ${({ noHover }) =>
      noHover &&
      css`
        cursor: default;
        pointer-events: none;

        & .button {
          pointer-events: all;
        }
      `}

    ${p => p.theme.mq.upMd} {
      padding: ${em(30)} 0 ${em(20)};
      display: initial;
      flex-grow: 1;
      width: 33%;
      margin: 0;
      padding-left: ${em(40)};
      overflow: hidden;

      ${({ noHover }) =>
        !noHover &&
        css`
          ${p => p.theme.mq.hover} {
            &:hover {
              background-color: ${catskillWhite};

              &:nth-child(n + 2) {
                border-left-color: ${catskillWhite};
              }

              & + :nth-child(n + 2) {
                border-left-color: ${catskillWhite} !important;
              }
            }
          }
        `}

      &:nth-child(n+2) {
        border-left: 1px solid ${blackOp1};
        ${({ isActive }) =>
          isActive &&
          css`
            border-left-color: ${catskillWhite};
          `}
      }

      ${({ isActive }) =>
        isActive &&
        css`
          & + :nth-child(n + 2) {
            border-left-color: ${catskillWhite} !important;
          }
        `}

    }

    ${p => p.theme.mq.upLg} {
      padding: ${em(29)} ${em(20)} 0 ${em(63)};
    }
`

export const TraitsListHeadTabInner = styled.div`
  position: relative;
  text-align: center;

  ${p => p.theme.mq.upMd} {
    text-align: left;
    line-height: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  ${p => p.theme.mq.upLg} {
    line-height: inherit;
  }
`

export const FirstRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${p => p.theme.mq.upMd} {
    justify-content: flex-start;
    align-items: flex-start;
  }

  ${p => p.theme.mq.upLg} {
    flex-direction: row;
    margin-bottom: ${em(30)};
  }
`

export const SecondRow = styled.div`
  display: flex;

  ${p => p.theme.mq.upMd} {
    align-items: flex-end;
    justify-content: space-between;
  }

  ${p => p.theme.mq.upLg} {
    padding-bottom: 0;
    align-items: flex-start;
  }
`

export const ImageBox = styled.div`
  flex-shrink: 0;

  ${p => p.theme.mq.upMd} {
    width: 50%;
  }
`

export const DynamicImageStyled = styled(DynamicImage)`
  display: block;
  position: relative;
  width: ${em(74)};

  ${p => p.theme.mq.upMd} {

    ${p =>
      p.locale === RU &&
      css`
        width: 100%;
        max-width: ${em(132)};
        margin-left: ${em(10)};
      `}

    ${p =>
      p.locale !== RU &&
      css`
        width: 95%;
        max-width: ${em(180)};
        margin-left: auto;
        margin-top: -35%;
        top: ${em(35)};
        left: -${em(20)};
      `}

    ${p =>
      COUNTRIES_WITHOUT_DNA.includes(p.locale) &&
      css`
        margin-top: -25%;
      `}
  }

  ${p => p.theme.mq.upLg} {
    margin-left: auto;
    margin-top: 0;

    ${p =>
      p.locale === RU &&
      css`
        width: 100%;
        max-width: ${em(140)};
        top: -${em(10)};
      `}

    ${p =>
      p.locale !== RU &&
      css`
        top: -${em(50)};
      `}

    ${p =>
      COUNTRIES_WITHOUT_DNA.includes(p.locale) &&
      css`
        margin-right: ${em(169)};
        max-width: ${em(210)};
        top: -${em(70)};
      `}
  }
`

export const TextLHXsStyled = styled(TextLHXs)`
  display: inline-block;
  margin-top: ${p =>
    em(p.locale === RU ? 15 : 5, p.theme.typography.fontSizeXsPx)};
  max-width: ${p => em(83, p.theme.typography.fontSizeXsPx)};

  ${p => p.theme.mq.upMd} {
    margin-top: 0;
    max-width: none;
  }

  ${p => p.theme.mq.upLg} {
    margin-right: ${p => em(11, p.theme.typography.fontSizeXsPx)};
  }
`
