import { round } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import ButtonRoundedSt from 'components/ButtonRoundedSt'
import ButtonSuccessfulSt from 'components/ButtonSuccessfulSt'
import ControlAmount from 'components/ControlAmount'
import CostsStockAndBase from 'components/CostsStockAndBase'
import { RU } from 'constants/countries'

import UpsaleCardNarrowBox from './components/UpsaleCardNarrowBox'
import {
  CardWrapper,
  H4Styled,
  ContentWrapper,
  TitleAndCost,
  ControlsAndButton,
  ControlsAndButtonContent,
} from './styles'

/**
 * Uncontrollable component of Upsale Card with narrow behaviour
 * so you can put two of it near on one row
 *
 * @param {object} props - component's props
 * @return {function} Function — UpsaleCardNarrow
 */
const UpsaleCardNarrow = ({
  isCartInProgress,
  boxesPath,
  buttonHandleClickBegin,
  buttonHandleClickEnd,
  buttonLink,
  buttonAsPath,
  buttonLinkAs,
  buttonLinkTitle,
  buttonLinkClick,
  buttonTitle,
  isFetching,
  costCurrency,
  costLocale,
  costValue,
  costRealValue,
  counterAmount,
  counterAmountIsActive,
  counterHandleDecrease,
  counterHandleIncrease,
  title,
  productType,
  isVisible,
  isDisabled,
  isMobile,
  locale,
}) => {
  const buttonWidth = isMobile || locale === RU ? 130 : 140
  const buttonAnimAlign = isMobile ? 'center' : 'right'

  const controlsBlock =
    counterAmount && counterHandleDecrease && counterHandleIncrease ? (
      <ControlAmount
        amount={counterAmount}
        isActive={counterAmountIsActive && !isDisabled}
        onMinusClick={counterHandleDecrease}
        onPlusClick={counterHandleIncrease}
      />
    ) : null

  const costBlock =
    !isDisabled && costCurrency && costLocale ? (
      <CostsStockAndBase
        {...{
          cost: costValue,
          realCost: costRealValue,
          intlCode: costLocale,
          currency: costCurrency,
          isFetching,
          isRight: true,
          size: 'medium',
          isTransparent: false,
          position: 'bottom',
        }}
      />
    ) : null

  const buttonBlock = React.useMemo(() => {
    if (
      buttonHandleClickBegin &&
      buttonHandleClickEnd &&
      buttonTitle &&
      !isDisabled
    ) {
      return (
        <ButtonSuccessfulSt
          firstClickBeforeCb={buttonHandleClickBegin}
          firstClickCb={buttonHandleClickEnd}
          secondBtnAs={buttonLinkAs}
          secondClickAsPath={buttonAsPath}
          secondClickTo={buttonLink}
          secondClickText={buttonLinkTitle}
          secondClickCb={buttonLinkClick}
          animAlign={buttonAnimAlign}
          btnWidth={buttonWidth}
        >
          {buttonTitle}
        </ButtonSuccessfulSt>
      )
    }

    return (
      <ButtonRoundedSt
        as={buttonLinkAs}
        isDisabled={isDisabled}
        to={buttonLink}
        asPath={buttonAsPath}
      >
        {buttonLinkTitle}
      </ButtonRoundedSt>
    )
  }, [
    buttonHandleClickBegin,
    buttonHandleClickEnd,
    buttonLinkClick,
    buttonLinkAs,
    buttonAsPath,
    buttonLink,
    buttonLinkTitle,
    buttonAnimAlign,
    buttonWidth,
    buttonTitle,
    isDisabled,
  ])

  const discount = React.useMemo(
    () => round(100 - (costRealValue * 100) / costValue),
    [costValue, costRealValue],
  )

  return (
    <CardWrapper>
      <UpsaleCardNarrowBox
        {...{
          boxesPath,
          productType,
          title,
          discount,
          isVisible,
          locale,
        }}
      />
      <ContentWrapper>
        <TitleAndCost>
          <H4Styled dangerouslySetInnerHTML={{ __html: title }} />
          {costBlock}
          {!costLocale && buttonBlock}
        </TitleAndCost>
        <ControlsAndButton>
          <ControlsAndButtonContent isFirst={true}>
            {controlsBlock}
          </ControlsAndButtonContent>
          {!isFetching && !isCartInProgress && !!costLocale && (
            <ControlsAndButtonContent>{buttonBlock}</ControlsAndButtonContent>
          )}
        </ControlsAndButton>
      </ContentWrapper>
    </CardWrapper>
  )
}

UpsaleCardNarrow.propTypes = {
  /** Is cart info loading from server */
  isCartInProgress: PropTypes.bool.isRequired,

  /** Localized path to boxes */
  boxesPath: PropTypes.string.isRequired,

  /** Link of button at the successful state */
  buttonLink: PropTypes.string.isRequired,

  /** String for client-routing for Link component */
  buttonAsPath: PropTypes.string,

  /** Title of button at the successful state */
  buttonLinkTitle: PropTypes.string.isRequired,

  /** Function called right after button was clicked */
  buttonHandleClickBegin: PropTypes.func,

  /** Function called right after button successfully animated */
  buttonHandleClickEnd: PropTypes.func,

  /** Title of a button at the begin state */
  buttonTitle: PropTypes.string,

  /** Type of link button (to work in the styleguide) */
  buttonLinkAs: PropTypes.string,

  /** Function called after redirection **/
  buttonLinkClick: PropTypes.func,

  /** Locale to show right boxes */
  locale: PropTypes.string.isRequired,

  /** Product type */
  productType: PropTypes.string.isRequired,

  /** Title of the card */
  title: PropTypes.string.isRequired,

  /** Link of the title of the card */
  titleLink: PropTypes.string.isRequired,

  /** Path of the title of the card for Next Link component */
  titleLinkAs: PropTypes.string,

  /** Amount of goods */
  counterAmount: PropTypes.number,

  /** Flag to make counter active/blocked */
  counterAmountIsActive: PropTypes.bool,

  /** Description near the counter */
  counterDescription: PropTypes.string,

  /** Decrease amount of products callback */
  counterHandleDecrease: PropTypes.func,

  /** Increase amount of products callback */
  counterHandleIncrease: PropTypes.func,

  /** Code of currency to show cost of product */
  costCurrency: PropTypes.string,

  /** Locale to use internationalization for cost rendering */
  costLocale: PropTypes.string,

  /** Real cost of product */
  costRealValue: PropTypes.number,

  /** Actual cost of product */
  costValue: PropTypes.number,

  /** Do we need to render the bottom button and price */
  isFetching: PropTypes.bool,

  /** Flag to turn on mobile rendering options */
  isMobile: PropTypes.bool,

  /** Is block displayed on the viewport */
  isVisible: PropTypes.bool.isRequired,

  /** Is button disabled **/
  isDisabled: PropTypes.bool.isRequired,

  /** Type of component to render title of card (for styleguide, cause we can't render Link without router component) */
  titleAs: PropTypes.string,
}

UpsaleCardNarrow.defaultProps = {
  buttonLinkAs: 'Link',
  titleAs: 'Link',
  isFetching: true,
  isMobile: false,
}

export default React.memo(UpsaleCardNarrow)
