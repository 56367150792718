import styled from 'styled-components'
import { em } from 'polished'

import { Text } from 'shared/Typography'
import { BoxBase } from 'styles/components/layout'

export const Container = styled(BoxBase)`
`

export const Title = styled(Text)`
  margin-bottom: ${em(20, 35)};
  text-align: center;

  ${({ theme }) => theme.mq.upLg} {
    margin-bottom: ${em(40, 50)};
    text-align: left;
  }
`

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${em(10)};

  & > div:nth-child(2) > h2 {
    @media(min-width: 1350px) {
      max-width: ${em(270, 18)}
    }
  }

  ${({ theme }) => theme.mq.upLg} {
    grid-template-columns: repeat(3, 1fr);
    gap: ${em(40)} ${em(43)};
  }
`
