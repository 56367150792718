import { em } from 'polished'
import styled from 'styled-components'

import { BoxBase, RowIndented } from 'styles/components/layout'
import { Text } from 'shared/Typography'

export const BoxBaseStyled = styled(BoxBase)`
  position: relative;

  ${p => p.theme.mq.upLg} {
    margin-top: 40px;
  }
`

export const RowIndentedStyled = styled(RowIndented)`
  background-color: var(--color-G50);
  padding-bottom: 2.5em;
  padding-top: 2.5em;

  ${p => p.theme.mq.upLg} {
    border-radius: ${p => p.theme.blocks.blockBorderRadiusMedium};
  }
`

export const Title = styled(Text)`
  text-align: left;

  ${p => p.theme.mq.upMd} {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: ${em(42, 50)};
  }
`

export const ButtonBox = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  transform: translateY(50%);
  text-align: center;
  z-index: 1;

  a {
    display: inline-block;
  }
`
