import { connect } from 'react-redux'

import { getIsCartInitializingOrUpdating } from 'selectors'

import Component from './CostContainer'

function mapStateToProps(state) {
  return {
    isCartInProgress: getIsCartInitializingOrUpdating(state),
  }
}

export default connect(mapStateToProps)(Component)
