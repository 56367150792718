import PropTypes from 'prop-types'
import React from 'react'

import { Box, Value, Text } from './styles'

/**
 * DiscountLabel - component to render discount
 *
 * @param {object} props - properties of component
 * @returns {node} DiscountLabel
 */
const DiscountLabel = props => {
  const {
    text,
    value,
    isShortLabel,
    isWithFilling,
    isWithoutShift,
    className,
  } = props

  return (
    <Box as="div" {...{ className, isWithFilling, isWithoutShift }}>
      <Value isShortLabel={isShortLabel}>{value}%</Value>{' '}
      <Text
        isShortLabel={isShortLabel}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </Box>
  )
}

DiscountLabel.propTypes = {
  /** Text for label */
  text: PropTypes.string.isRequired,

  /** Value of discount */
  value: PropTypes.number.isRequired,

  /** Should show short label **/
  isShortLabel: PropTypes.bool,

  /** Always with filled bg **/
  isWithFilling: PropTypes.bool,

  /** Do not shift up on mobile */
  isWithoutShift: PropTypes.bool,

  /** Class name for wrapper */
  className: PropTypes.string,
}

DiscountLabel.defaultProps = {
  className: '',
  isWithFilling: false,
  isWithoutShift: false,
}

export default DiscountLabel
