import PropTypes from 'prop-types'
import React from 'react'

import { ResizeContext } from 'contexts/Resize'

import CardHowItWork from './components/CardHowItWork'
import {
  Container,
  Title,
  CardsContainer,
} from './styles'

export const HowItWorks = ({ texts, className }) => {
  const { mobile } = React.useContext(ResizeContext)
  const { list, title } = texts

  return (
    <Container className={className}>
      <Title
        size={['50/60', '50/60', '35/40']}
        weight={'medium'}
        dangerouslySetInnerHTML={{ __html: title }}
      />

      <CardsContainer>
        {list.map(({ title, link }, index) => (
          <CardHowItWork key={title} title={title} number={index + 1} link={link} mobile={mobile} />
        ))}
      </CardsContainer>
    </Container>
  )
}

HowItWorks.propTypes = {
  texts: PropTypes.object.isRequired,
  className: PropTypes.string,
}
