import React from 'react'

import { COUNTRIES_WITHOUT_DNA } from 'constants/sales'
import { LocalizationContext } from 'contexts/Localization'
import { RowIndented } from 'styles/components/layout'

import BiomeCardWide from './components/BiomeCardWide/BiomeCardWide'
import TestCard from './components/TestCard'
import { BoxBaseStyled, ColStyled } from './styles'

function AboutTests() {
  const {
    dict,
    locale,
    paths: { cdn_global },
  } = React.useContext(LocalizationContext)

  const isCountryWithoutDna = React.useMemo(
    () => COUNTRIES_WITHOUT_DNA.includes(locale),
    [locale],
  )

  return (
    <BoxBaseStyled id="aboutTests">
      {isCountryWithoutDna ? (
        <BiomeCardWide
          {...{ dict, locale, mediaPath: `${cdn_global}/boxes` }}
        />
      ) : (
        <RowIndented indent={{ xs: 0, md: 0, lg: 20 }}>
          <ColStyled width={{ xs: 1, md: 1 / 2 }}>
            <TestCard
              {...{
                dict,
                locale,
                mediaPath: `${cdn_global}/boxes`,
                isItGenetic: true,
              }}
            />
          </ColStyled>

          <ColStyled width={{ xs: 1, md: 1 / 2 }}>
            <TestCard {...{ dict, locale, mediaPath: `${cdn_global}/boxes` }} />
          </ColStyled>
        </RowIndented>
      )}
    </BoxBaseStyled>
  )
}

export default React.memo(AboutTests)
