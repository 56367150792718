import { withRouter } from 'next/router'
import { connect } from 'react-redux'
import { compose } from 'redux'

import {
  HOME,
  PUBLICATIONS_GENERAL,
  STORIES_HOME,
  CAROUSEL,
} from 'constants/textBunchesNames'
import withLocalization from 'containers/withLocalization'

import Component from './Home'

function mapStateToProps(state) {
  return {
    currency: state.localization.currency,
  }
}

const LocalizedComponent = withLocalization(Component, HOME, {
  publications: PUBLICATIONS_GENERAL,
  stories: STORIES_HOME,
  carousel: CAROUSEL,
})

export default compose(withRouter, connect(mapStateToProps))(LocalizedComponent)
