import CSSPlugin from 'gsapAlias/CSSPlugin'
import TimelineLite from 'gsapAlias/TimelineLite'

// eslint-disable-next-line no-unused-vars
const plugins = [CSSPlugin]

export const rollUp = (block, cb) => {
  const { height } = block.getBoundingClientRect()
  const timeline = new TimelineLite({
    onComplete: cb,
  })

  timeline
    .set(block, {
      overflow: 'hidden',
      willChange: 'height, opacity',
      opacity: 1,
      height,
    })
    .to(block, 0.3, {
      opacity: 0,
      height,
    })
    .to(block, 0.6, {
      height: 0,
    })

  return timeline
}
