import styled, { css } from 'styled-components'

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  overflow: hidden;
`

const transitionStyles = {
  entering: css`
    opacity: 1;
  `,
  entered: css`
    opacity: 1;
  `,
  exiting: css`
    opacity: 0;
  `,
  exited: css`
    opacity: 0;
  `,
}

export const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: opacity ${800}ms linear;
  opacity: 1;
  ${p => transitionStyles[p.state]};
`

export const Image = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ backgroundColor: color }) => color};
  background-image: url('${({ path, ext }) => `${path}_mob.${ext}`}'), url('${({
  isBgCustom,
  path,
  ext,
}) => (isBgCustom ? `${path}_bg_mob.${ext}` : null)}');
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: ${({ layout }) =>
    layout === 'promo' ? 'bottom right' : 'bottom left'};
  ${({ theme }) => theme.mq.isRetina} {
    background-image: url('${({ path, ext }) =>
      `${path}_mob@2x.${ext}`}'), url('${({ isBgCustom, path, ext }) =>
  isBgCustom ? `${path}_bg_mob@2x.${ext}` : null}');
  }

  ${({ theme }) => theme.mq.upMd} {
    background-image: url('${({ path, ext }) =>
      `${path}_tab.${ext}`}'), url('${({ isBgCustom, path, ext }) =>
  isBgCustom ? `${path}_bg_tab.${ext}` : null}');
    background-position: ${({ layout }) =>
      layout === 'promo' ? 'bottom right' : 'bottom center'};
  }

  ${({ theme }) => theme.mq.upMd2x} {
    background-image: url('${({ path, ext }) =>
      `${path}_tab@2x.${ext}`}'), url('${({ isBgCustom, path, ext }) =>
  isBgCustom ? `${path}_bg_tab@2x.${ext}` : null}');
  }

  ${({ theme }) => theme.mq.upLg} {
    background-image: url('${({ path, ext }) =>
      `${path}_desktop.${ext}`}'), url('${({ isBgCustom, path, ext }) =>
  isBgCustom ? `${path}_bg_desktop.${ext}` : null}');
    background-position: ${({ layout }) =>
      layout === 'promo' ? 'bottom right' : 'bottom center'};
  }

  ${({ theme }) => theme.mq.upLg2x} {
    background-image: url('${({ path, ext }) =>
      `${path}_desktop@2x.${ext}`}'), url('${({ isBgCustom, path, ext }) =>
  isBgCustom ? `${path}_bg_desktop@2x.${ext}` : null}');
  }
`
