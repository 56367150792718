import { trim } from 'lodash'

/*
 * Translate given error code using dictionary
 */
export const errorFromDict = (dict, validation) => value => {
  const error = validation(value)

  if (error) {
    if (error in dict) {
      return dict[error]
    }
    // eslint-disable-next-line no-console
    console.warn(
      `There is no such mistake as "${error}" in the given dictionary`,
    )

    return error
  }

  return undefined
}

/*
 * If error exists we will show custom error, that give into function
 */
export const errorCustom = (cstmError, validation) => value => {
  const error = validation(value)

  return error && cstmError ? cstmError : undefined
}

/*
 * Compose several validators
 */
export const composeValidators = ([...validators]) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)

/*
 * Rules
 */
const simpleEmailRegExp = new RegExp(/^[^@\s]+@[^@\s]+\.[^@\s]+$/)

export const required = value => (value ? undefined : 'required')

export const maxLength = length => value =>
  value && trim(value, '-_ ').length <= length ? undefined : 'wrong_length'

export const minLength = length => value =>
  value && trim(value, '-_ ').length >= length ? undefined : 'wrong_length'

export const exactLength = length => value =>
  value && trim(value, '-_ ').length === length ? undefined : 'wrong_length'

export const isEmail = value =>
  simpleEmailRegExp.test(value) ? undefined : 'email_incorrect'

const notKatakanaRegExp = new RegExp(/[^\u30a0-\u30ff\uff00-\uffef\s]/gi)

export const onlyKatakana = value =>
  value && value.match(notKatakanaRegExp) ? 'only_katakana' : undefined

const numbersRegExp = new RegExp(/\d/gi)

export const withoutNumbers = value =>
  value && value.match(numbersRegExp) ? 'only_characters' : undefined

export const notEqual = unallowedValue => value =>
  value === unallowedValue ||
  value.toLowerCase() === unallowedValue.toLowerCase()
    ? 'unallowed_value'
    : undefined

export const isQuebec = value => {
  // On this array remove all ', â, é, è, ô, î
  const citiesExp = [
    'alma',
    'amos',
    'asbestos',
    'baie-comeau‎',
    'baie-durfe',
    'baie-saint-paul',
    'beaconsfield',
    'blainville',
    'boisbriand',
    'boucherville',
    'brossard',
    'carleton-sur-mer',
    'chambly',
    'chateauguay',
    'chibougamau',
    'cote-saint-luc',
    'cowansville',
    'dolbeau-mistassini',
    'dollard-des-ormeaux',
    'dorval',
    'drummondville',
    'gaspe',
    'gatineau',
    'granby',
    'joliette',
    'kirkland',
    'la pocatiere',
    'la prairie',
    'la tuque',
    'lachute',
    'laval',
    'levis',
    'longueuil',
    'louiseville',
    'magog',
    'mascouche',
    'matane',
    'mirabel',
    'mont-laurier',
    'montmagny',
    'montreal',
    'montreal west',
    'montreal-est',
    'mount royal',
    'nicolet',
    'pointe-claire',
    'quebec',
    'quebec city‎',
    'repentigny',
    'rimouski',
    'riviere-du-loup',
    'roberval',
    'rouyn-noranda',
    'saguenay',
    'saint-bruno-de-montarville',
    'saint-constant',
    'saint-eustache',
    'saint-felicien',
    'saint-georges',
    'saint-hyacinthe',
    'saint-jean-sur-richelieu',
    'saint-jerome',
    'saint-lambert',
    'sainte-agathe-des-monts',
    'sainte-anne-de-bellevue',
    'sainte-julie',
    'sainte-marie',
    'sainte-therese',
    'salaberry-de-valleyfield',
    'sept-iles',
    'shawinigan',
    'sherbrooke',
    'sorel-tracy',
    'stanstead',
    'terrebonne',
    'thetford mines',
    'trois-rivieres',
    'val-dor',
    'varennes',
    'vaudreuil-dorion',
    'victoriaville',
    'waterloo',
    'westmount',
    'windsor',
  ]

  const preparedInput = trim(value)
    .toLowerCase()
    .replace(/'/g, '')
    .replace(/’/g, '')
    .replace(/é/g, 'e')
    .replace(/è/g, 'e')
    .replace(/â/g, 'a')
    .replace(/î/g, 'i')
    .replace(/ô/g, 'o')

  return citiesExp.includes(preparedInput) ? 'is_quebec' : undefined
}
