import { fill } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import useInViewCommon from 'hooks/useInViewCommon'
import useSwiper from 'hooks/useSwiper'
import useSwiperLoader from 'hooks/useSwiperLoader'


import TrustpilotReview from '../TrustpilotReview'

import { ControlSliderArrowsStyled, Slide, Wrapper } from './styles'

const SLIDER_PER_VIEW = 3
const SLIDER_OPTS = {
  noSwiping: true,
  preloadImages: false,
  slidesPerView: SLIDER_PER_VIEW,
  slidesPerGroup: SLIDER_PER_VIEW,
}

const TrustpilotSlider3Items = ({ list }) => {
  const { swiper, activeIndex, onGoLeft, onGoRight, updateSwiper } = useSwiper()
  const [supplementedData, updateData] = React.useState([])
  const [ref, inView] = useInViewCommon()
  const { Swiper, swiperLoaded } = useSwiperLoader({ inView })

  React.useEffect(() => {
    if (list.length) {
      const remainder = list.length % SLIDER_PER_VIEW
      const remainderAmount =
        remainder === 0 ? list.length : SLIDER_PER_VIEW - remainder
      const supplementedArray = fill(new Array(remainderAmount), {})

      updateData(list.concat(supplementedArray))
    }
  }, [list])

  React.useEffect(() => {
    if (swiper && supplementedData.length) {
      swiper.update()
    }
  }, [swiper, supplementedData])

  return (
    <Wrapper ref={ref}>
      {swiperLoaded ? (
        <Swiper {...SLIDER_OPTS} getSwiper={updateSwiper}>
          {supplementedData.map((review, key) => {
            let content = null

            if ('text' in review) {
              content = <TrustpilotReview {...review} />
            }
            return <Slide key={`review.${key}`}>{content}</Slide>
          })}
        </Swiper>
      ) : (
        supplementedData.slice(0, 3).map((review, key) => {
          let content = null

          if ('text' in review) {
            content = <TrustpilotReview {...review} />
          }
          return <Slide key={`review.${key}`}>{content}</Slide>
        })
      )}
      {list.length > 3 && swiperLoaded ? (
        <ControlSliderArrowsStyled
          amount={Math.ceil(list.length / SLIDER_OPTS.slidesPerGroup)}
          activeIndex={Math.ceil(activeIndex / SLIDER_OPTS.slidesPerGroup)}
          isLightColor={false}
          handleLeft={onGoLeft}
          handleRight={onGoRight}
        />
      ) : null}
    </Wrapper>
  )
}

TrustpilotSlider3Items.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string,
      createdAt: PropTypes.string,
      stars: PropTypes.number,
      consumer: PropTypes.shape({
        displayName: PropTypes.string,
      }),
    }),
  ),
}

export default React.memo(TrustpilotSlider3Items)
