import { em } from 'polished'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: ${em(40)};

  ${p => p.theme.mq.upMd} {
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: ${em(73)};
    align-items: flex-start;
  }
`

export const ScoreBox = styled.div`
  display: flex;
  align-items: flex-end;
`

export const TrustpilotLogo = styled.div`
  width: ${em(110)};
  height: ${em(27)};
  margin-right: ${em(10)};
  flex-shrink: 0;
`

export const StarsBox = styled.div`
  width: ${em(106)};
  flex-shrink: 0;

  & img {
    width: 100%;
    height: auto;
  }
`
