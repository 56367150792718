import { em } from 'polished'
import styled from 'styled-components'

import { Button } from 'shared/Button'

export const FormBox = styled.div`
  margin-bottom: ${em(10)};

  ${p => p.theme.mq.upMd} {
    display: flex;
    margin-bottom: ${({ isInvalid }) => isInvalid ? `${em(20)}` : `${em(10)}`}
  }
`

export const FieldBox = styled.div`
  margin-bottom: ${({ isInvalid }) => isInvalid ? `${em(25)}` : `${em(15)}`};

  ${p => p.theme.mq.downMd} {
    label {
      border-radius: ${em(100)};
    }

    input {
      height: ${em(40, 18)};
    }
  }

  ${p => p.theme.mq.upMd} {
    width: 100%;
    max-width: ${em(280)};
    margin-bottom: 0;
    margin-right: ${em(10)};
  }
`

export const ButtonStyled = styled(Button)`
  ${p => p.theme.mq.downMd} {
    height: ${em(40)} !important;
  }
`
