import PropTypes from 'prop-types'
import React from 'react'

import List from 'components/List'

const TraitsListItems = props => {
  const { items, containerRef, styles } = props

  return (
    <div style={styles}>
      <div ref={containerRef}>
        <List leftAlignment="text">
          {items.map(
            (item, index) =>
              item && (
                <List.Item key={index} bulletColor="black">
                  {item}
                </List.Item>
              ),
          )}
        </List>
      </div>
    </div>
  )
}

TraitsListItems.propTypes = {
  items: PropTypes.array.isRequired,
  containerRef: PropTypes.object,
  styles: PropTypes.object,
}

TraitsListItems.defaultProps = {
  styles: {},
  containerRef: null,
}

export default TraitsListItems
