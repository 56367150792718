import PropTypes from 'prop-types'
import React from 'react'

import Icon from 'components/Icon'

import { ScoreBox, StarsBox, TrustpilotLogo, Wrapper } from './styles'

const TrustpilotHeader = ({ review }) => {
  if (!review) return null

  return (
    <Wrapper>
      <ScoreBox>
        <TrustpilotLogo>
          <Icon id="trustpilot" />
        </TrustpilotLogo>
        <StarsBox>
          <img
            alt={`TrustScore ${review.score.stars} out of 5`}
            src={`https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-${review.score.stars}.svg`}
          />
        </StarsBox>
      </ScoreBox>
    </Wrapper>
  )
}

TrustpilotHeader.propTypes = {
  review: PropTypes.shape({
    score: PropTypes.shape({
      stars: PropTypes.number,
      trustScore: PropTypes.number,
    }),
    numberOfReviews: PropTypes.shape({
      total: PropTypes.number,
    }),
  }),
}

export default React.memo(TrustpilotHeader)
