import styled from 'styled-components'
import { em } from 'polished'

import { BoxBase } from 'styles/components/layout'

export const Content = styled(BoxBase)`
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: ${em(31)};
  padding-bottom: ${em(135)};

  ${p => p.theme.mq.upMd} {
    padding-top: ${em(41)};
    padding-bottom: ${em(178)};
  }

  ${p => p.theme.mq.upThreshold(1024)} {
    flex-direction: row;
    padding-top: 0;
    padding-bottom: ${em(75)};
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;

  ${p => p.theme.mq.upLg} {
    align-self: auto;
  }
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  min-width: ${em(104)};

  ${p => p.theme.mq.upMd} {
    min-width: ${em(230)};
  }

  ${p => p.theme.mq.upThreshold(1024)} {
    min-width: ${em(197)};
  }

  ${p => p.theme.mq.upLg} {
    margin-top: ${em(38)};
    align-self: auto;
  }
`

export const OldPrice = styled.div`
  gap: 8px;
`

export const PriceAndButton = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: ${em(20)};

  ${p => p.theme.mq.downMd} {
    gap: ${em(10)};
  }
`
