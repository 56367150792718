import { em } from 'polished'
import styled from 'styled-components'

import { Col, RowIndented } from 'styles/components/layout'
import { Text } from 'styles/components/typography'

export const RowIndentedStyled = styled(RowIndented)`
  align-items: center;
  background-color: ${p => p.theme.colors.grayOp06NonTransp};
  border-radius: ${p => p.theme.blocks.blockBorderRadiusMedium};
  padding-top: ${em(30)};
  padding-bottom: ${em(20)};
  margin-bottom: ${em(20)};

  ${p => p.theme.mq.upMd} {
    padding-top: ${em(45)};
    padding-bottom: ${em(38)};
    margin-bottom: ${em(40)};
  }

  ${p => p.theme.mq.upLg} {
    justify-content: space-between;
    margin-bottom: 0;
  }
`

export const TextStyled = styled(Text)`
  padding: 0 14%;

  &:not(:last-child) {
    margin-bottom: 0;
  }

  ${p => p.theme.mq.upLg} {
    padding: 0;
    flex-grow: 2;
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  width: 123.33%;
  max-width: ${em(350)};
  margin: 0 auto;
  flex-shrink: 0;
  margin-top: ${em(43)};
  right: -2%;

  ${p => p.theme.mq.upMd} {
    padding: 0;
    width: 105.4%;
    max-width: ${em(782)};
    margin-top: ${em(67)};
    right: -5%;
  }

  ${p => p.theme.mq.upLg} {
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-top: 0;
    right: -20%;
    margin-right: -20px;
  }
`

export const IconWrapper = styled.div`
  position: absolute;
  width: 21%;
  height: 55%;
  bottom: 3%;
`

export const ColStyled = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${p => p.theme.mq.upLg} {
    align-items: flex-start;
    text-align: left;
  }
`

export const ButtonWrapper = styled.div`
  width: 100%;
  max-width: ${em(490)};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${em(40)};

  .button {
    width: 100%;
  }

  ${p => p.theme.mq.upMd} {
    width: ${em(226)};
  }

  ${p => p.theme.mq.upLg} {
    margin-left: 0;
    margin-right: 0;
  }
`
