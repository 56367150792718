import { em } from 'polished'
import styled from 'styled-components'

export const TraitsListCollapserWrapper = styled.div`
  margin-bottom: ${em(20)};
`

export const ButtonBox = styled.div`
  position: relative;
  margin-top: -${em(30)};
  padding-top: ${em(60)};
  margin-bottom: ${em(40)};

  ${({ withBg }) =>
    withBg &&
    `background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 30%);`}

  ${p => p.theme.mq.upMd} {
    margin-bottom: ${em(50)};
  }
`
