import PropTypes from 'prop-types'
import React from 'react'

import Icon from 'components/Icon'

import {
  BtnClose,
  BtnCloseLarge,
  BtnCloseMedium,
  BtnCloseSmall,
} from './styles'

const btnSizes = {
  small: BtnCloseSmall,
  medium: BtnCloseMedium,
  normal: BtnClose,
  big: BtnCloseLarge,
}

/**x
 * Close button component - use it for close something
 *
 * @return {Component} Component - Close
 */
const ButtonClose = ({ handleClick, color, transitionState, size }) => {
  const Button = React.useMemo(() => btnSizes[size], [size])
  return (
    <Button
      transitionState={transitionState}
      onClick={handleClick}
      aria-label="close"
    >
      <Icon id="btn_close" color={color} />
    </Button>
  )
}

ButtonClose.propTypes = {
  /** Function to call when click on icon */
  handleClick: PropTypes.func.isRequired,

  /** Color of icon */
  color: PropTypes.string,

  /** Transition state */
  transitionState: PropTypes.string,

  /** Size of button */
  size: PropTypes.oneOf(['small', 'medium', 'normal', 'big']),
}

ButtonClose.defaultProps = {
  color: 'white',
  transitionState: '',
  size: 'normal',
}

export default ButtonClose
