// import { shuffle } from 'lodash'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import React from 'react'

const StoriesSliderMobile = dynamic(() =>
  import('./components/StoriesSliderMobile'),
)
const StoriesSliderDesktop = dynamic(() =>
  import('./components/StoriesSliderDesktop'),
)

const StoriesSlider = ({ isMobile, path, slides, colorTheme, locale }) => {
  // const shuffleSlides = React.useMemo(() => shuffle(slides), [slides])
  const storiesPath = React.useMemo(() => `${path}/stories_img`, [path])

  const slider = React.useMemo(() => {
    if (isMobile) {
      return (
        <StoriesSliderMobile
          {...{ slides: slides, storiesPath, colorTheme, locale }}
        />
      )
    } else {
      return (
        <StoriesSliderDesktop
          {...{ slides: slides, storiesPath, colorTheme, locale }}
        />
      )
    }
  }, [isMobile, slides, storiesPath, colorTheme, locale])

  return slider
}

StoriesSlider.propTypes = {
  /** Slides list */
  slides: PropTypes.array.isRequired,

  /** Path to images */
  path: PropTypes.string.isRequired,

  /** Flag to control slider type */
  isMobile: PropTypes.bool.isRequired,

  colorTheme: PropTypes.oneOf(['dark', 'light']),
}

StoriesSlider.defaultProps = {
  colorTheme: 'light',
}

export default StoriesSlider
