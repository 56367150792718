import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { ResizeContext } from 'contexts/Resize'
import { Button } from 'shared/Button'
import { Dialog } from 'shared/Dialog'
import AddAndGoToCart from 'containers/AddAndGoToCart'
import { Text } from 'shared/Typography'

import {
  Wrapper,
  Container,
  Image,
  Content,
  Title,
  Description,
  ButtonBlock,
  SubscribeText,
  ReadMore,
} from './styles'
import image from './assets/image.png'
import SubscriptionInfo from 'widgets/SubscriptionInfo'

const Wellbeing = ({ texts, commonTexts, className }) => {
  const { mobile } = React.useContext(ResizeContext)
  const { title, description, text } = texts
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleButtonClick = () => {
    setIsModalOpen(true)
  }

  return (
    <>
      <Wrapper className={className}>
        <Container>
          <Image src={image} alt='image_wellbeing' />

          <Content>
            <Title
              forwardedAs={'h2'} size={['50/60', '50/60', '25/30']} weight={'medium'}
              dangerouslySetInnerHTML={{ __html: title }}
            />

            <Description
              size={['22/30', '22/30', '16/20']}
              dangerouslySetInnerHTML={{ __html: description }}
            />

            <ButtonBlock>
              <AddAndGoToCart
                key={'biome'}
                goods={[{
                  type: 'biome',
                  isSubscription: false,
                }]}
                buttonProps={{
                  color: 'violet',
                  size: mobile ? 'mobile' : 'desktop',
                }}
                ButtonComponent={Button}
                forceMultipleAdd
              >
                Add to cart
              </AddAndGoToCart>

              <ReadMore>
                <SubscribeText
                  size={['18/24', '18/24', '16/20']}
                  dangerouslySetInnerHTML={{ __html: text }}
                />

                <Button variant={'text'} onClick={handleButtonClick} isDangerouslySet={false}>
                  <Text weight={'medium'} size={['18/24', '18/24', '16/20']}>Read more</Text>
                </Button>
              </ReadMore>
            </ButtonBlock>
          </Content>
        </Container>
      </Wrapper>

      {isModalOpen && (
        <Dialog isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} fullscreen={mobile}>
          <SubscriptionInfo texts={commonTexts.subscription_info} />
        </Dialog>
      )}
    </>
  )
}

Wellbeing.propTypes = {
  texts: PropTypes.object.isRequired,
  commonTexts: PropTypes.object.isRequired,
  className: PropTypes.string,
}

export default Wellbeing
