import { useState, useCallback, useEffect, useRef } from 'react'
import useMounted from './useMounted'

/**
 * @typedef {{
 *   duration?: number,
 *   hasProgress?: boolean,
 * }} CarouselConfig
 */

/**
 * Отвечает за логику карусели
 * @param {number} count
 * @param {CarouselConfig} [config]
 * @returns {{
 *   index: number,
 *   progress: number,
 *   nextSlide: () => {},
 *   prevSlide: () => {},
 *   setSlide: (index: number) => {},
 * }}
 */
export default function useCarousel(
  count,
  { duration = 15, hasProgress = false } = {},
) {
  // TODO сделать возможность сброса счетчика на 0 как в ручную так и при count = 0
  // TODO для прогресса можно сделать debounce, чтобы уменьшить частоту обновления.

  const durationMs = duration * 1000
  const [index, setIndex] = useState(0)
  const [nextSlideTime, setNextSlideTime] = useState(+new Date() + durationMs)
  const [progress, setProgress] = useState(0)

  // next slide
  const nextSlide = useCallback(() => {
    setIndex(current => {
      const next = current + 1
      return next >= count ? 0 : next
    })
    setNextSlideTime(+new Date() + durationMs)
  }, [count, durationMs])

  // prev slide
  const prevSlide = useCallback(() => {
    if (count <= 0) return
    setIndex(current => {
      const prevSlide = current - 1
      return prevSlide < 0 ? count - 1 : prevSlide
    })
    setNextSlideTime(+new Date() + durationMs)
  }, [count, durationMs])

  // set slide
  const setSlide = useCallback(
    newIndex => {
      if (count <= 0) return
      if (0 <= newIndex && newIndex < count) {
        setIndex(newIndex)
        setNextSlideTime(+new Date() + durationMs)
      }
    },
    [count, durationMs],
  )

  // Slide rotation logic
  const timer = useRef(null)
  const mounted = useMounted()
  useEffect(() => {
    if (count <= 1) return
    function run() {
      if (!mounted.current)
        return

      const now = +new Date()
      if (now >= nextSlideTime) {
        nextSlide()
        hasProgress && setProgress(0)
        // В этой ветке не запускаем requestAnimationFrame т.к.
        // nextSlide() внутри обновляет nextSlideTime, а его
        // обновление перезапустить весь эффект, который
        // в свою очередь запустит цепочку по новой
      } else {
        const timeLeft = nextSlideTime - now
        // TODO лучше сделать настраиваемый debounce.
        hasProgress &&
          setProgress(
            Math.round(((durationMs - timeLeft) / durationMs) * 100) / 100,
          )
        timer.current = requestAnimationFrame(run)
      }
    }
    requestAnimationFrame(run)
    return () => cancelAnimationFrame(timer.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, nextSlideTime, hasProgress])

  return {
    index,
    progress,
    nextSlide,
    prevSlide,
    setSlide,
  }
}
