import Router from 'next/router'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { addAmount } from 'actions'
import ButtonRounded from 'components/ButtonRounded'
import { cart as cartRoute } from 'routes'
import { getIsCartInitializingOrUpdating } from 'selectors'
import getDistributorLink from 'utils/sales/getDistributorLink'

const AddAndGoToCart = ({
  goods,
  children,
  buttonTitleKey,
  buttonProps = {},
  buttonWrapperProps,
  forceMultipleAdd,
  ButtonComponent,
  className,
  isZeroPrice,
}) => {
  const cart = useSelector(state => state.cart)
  const {
    locale,
    pages: {
      common: { buttons },
    },
    sales: { specialDistribution, withoutSales },
  } = useSelector(state => state.localization)
  const isCartInProgress = useSelector(getIsCartInitializingOrUpdating)
  const dispatch = useDispatch()
  const buttonDefaultProps = {
    as: 'button',
    amount: cart.sum.amount,
  }
  const modifyButtonProps = {}

  const addProduct = React.useCallback(
    product => {
      dispatch(
        addAmount({
          productType: product.type,
          amount: 1,
          isSubscription: product.isSubscription,
        }),
      )
    },
    [dispatch],
  )

  const handleClick = React.useCallback(() => {
    if (
      goods.length &&
      (cart.sum.amount === 0 || (cart.sum.amount > 0 && forceMultipleAdd))
    ) {
      goods.forEach(product => addProduct(product))
    }

    Router.push(cartRoute.link(locale), cartRoute.as(locale))
  }, [goods, locale, cart, forceMultipleAdd, addProduct])

  if (!cart.isInitialized || isCartInProgress) {
    return null
  }

  if (specialDistribution && !isZeroPrice) {
    modifyButtonProps.as = 'a'
    modifyButtonProps.to = getDistributorLink(goods[0].type, locale)
    modifyButtonProps.href = getDistributorLink(goods[0].type, locale)
  } else if (withoutSales) {
    buttonTitleKey = 'out_of_stock'
    modifyButtonProps.isDisabled = true
  } else {
    buttonDefaultProps.wrapperProps = {
      onClick: handleClick,
      ...buttonWrapperProps,
    }
    buttonDefaultProps.onClick = handleClick
  }

  const content =
    children || (buttonTitleKey && buttons[buttonTitleKey]) || null

  return (
    <ButtonComponent
      {...buttonDefaultProps}
      {...buttonProps}
      wrapperClassName={`${buttonProps.wrapperClassName || ''} ${className}`}
      {...modifyButtonProps}
    >
      {content}
    </ButtonComponent>
  )
}

AddAndGoToCart.propTypes = {
  /** Array with goods configs */
  goods: PropTypes.arrayOf(
    PropTypes.shape({
      isSubscription: PropTypes.bool.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,

  /** For multiple goods: add those goods which amount equal to 0 */
  forceMultipleAdd: PropTypes.bool,

  /** Props to pass through to button */
  buttonProps: PropTypes.object,

  /** Key of buttons dict for title of button */
  buttonTitleKey: PropTypes.string,

  /** Props to pass through to button's wrapper */
  buttonWrapperProps: PropTypes.object,

  /** Custom component for button */
  ButtonComponent: PropTypes.func,

  /** Custom content for button (instead of buttonTitleKey) */
  children: PropTypes.node,

  /** Classname for styled component */
  className: PropTypes.string,

  /** Boolean flag for zero prices */
  isZeroPrice: PropTypes.bool,
}

AddAndGoToCart.defaultProps = {
  forceMultipleAdd: false,
  buttonTitleKey: 'buy',
  buttonWrapperProps: {},
  ButtonComponent: ButtonRounded,
}

export default React.memo(AddAndGoToCart)
