import { em } from 'polished'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  min-height: ${p => p.theme.blocks.heightBlock500};

  .publications-mobile__arrows {
    position: absolute;
    bottom: ${em(10)};
    width: 100%;
  }
`
