import animateScrollTo from 'animated-scroll-to'
import PropTypes from 'prop-types'
import React, { useMemo, useState, useEffect, useContext, useCallback } from 'react'
import { useSwipeable } from 'react-swipeable'
import classNames from 'classnames'

import { REGION_US } from 'constants/regions'
import createNumberFormatter from 'utils/createPriceFormatter'
import { MenuMainContext } from 'contexts/MenuMain'
import { ResizeContext } from 'contexts/Resize'

import {
  BackgroundTransition,
  Background,
  BackgroundImage,
} from './components/Background'
import DefaultLayout from './components/DefaultLayout'
import PromoLayout from './components/PromoLayout'
import UsLayout from './components/UsLayout'
import Pagination from './components/Pagination'
import { useSlides, useCarousel, useGoods } from './hooks'
import {
  Wrapper,
  Container,
  PaginationBox,
} from './styles'

const isUsRegion = process.env.region === REGION_US

function Carousel({
  locale,
  language,
  withoutSales,
  // TODO свести все переводы в один проп translations
  translations, // почему-то иногда бывает undefined. Локально отловить сложно, а вот в проде из-за этого все падает
  buttonTranslations,
  defaultContent,
  path,
}) {
  // TODO вынести получение этой инфы в ssr
  const { isSalesBanner: hasSalesBanner } = useContext(MenuMainContext)
  const { mobile, tablet } = React.useContext(ResizeContext)
  const { goods, currency } = useGoods(locale)
  const [discount, setDiscount] = useState(0)
  const slides = useSlides(locale)
  const hasSlides = slides.length > 0

  const {
    index,
    progress,
    setSlide,
    nextSlide,
    prevSlide,
  } = useCarousel(slides.length, { hasProgress: true })

  // Swipe Handlers
  const swipeHandlers = useSwipeable({
    onSwipedLeft: nextSlide,
    onSwipedRight: prevSlide,
    preventDefaultTouchmoveEvent: true,
  })
  const currentSlide = slides[index]
  const actionType = currentSlide?.actionType || 'purchase'
  const isPromoLayout = currentSlide?.layout === 'promo'
  const currentTestName = defaultContent?.testNames[currentSlide?.id]
  const isBlackColor = currentSlide?.styles.color === 'black'
  const isDefaultWgs = currentSlide?.id === 'wgs'
  /**
   *
   * @type {{
   *   code: string,
   *   currentPrice: number,
   *   id: number,
   *   name: string,
   *   price: number,
   * }|null}
   */
  const currentGoodsItem =
    goods &&
    currentSlide &&
    goods[currentSlide.goodsType === 'wgs' ? 'dna_wgs' : currentSlide.goodsType]

  const isZeroPrice =
    currentGoodsItem &&
    currentGoodsItem?.currentPrice === 0 ||
    currentGoodsItem?.price === 0 ||
    currentGoodsItem?.priceWebsite === 0

  const formatter = useMemo(() => {
    if (!currency) return
    return createNumberFormatter({ locale: language, currency })
  }, [language, currency])

  const scrollToProducts = useCallback(() => {
    const target = document.getElementById('aboutTests')
    const bannerBBox = document.getElementById('salesBanner')?.getBoundingClientRect()

    animateScrollTo(target, {
      speed: 600,
      verticalOffset: mobile ? -40 : -bannerBBox?.top || 0 - bannerBBox?.height || 0,
      elementToScroll: window,
    })
  }, [mobile])

  useEffect(() => {
    if (currentSlide?.withDiscountBadge && currentGoodsItem?.currentPrice !== currentGoodsItem?.price) {
      setDiscount(Math.round(100 - 100 * currentGoodsItem?.currentPrice / currentGoodsItem?.price))
    }
  }, [currentGoodsItem, currentSlide])

  return (
    <Container
      hasSalesBanner={hasSalesBanner}
      layout={currentSlide?.layout}
      style={{ color: currentSlide?.styles.color }}
    >
      <Wrapper {...swipeHandlers}>
        {hasSlides && (
          <>
            <BackgroundTransition current={index}>
              {slides.map(slide => (
                <Background key={slide.id}>
                  <BackgroundImage
                    path={`${path}/carousel/${slide.styles.imgPath}/${slide.styles.ignoreLocale ? 'all' : locale}`}
                    isBgCustom={slide.styles.bg === 'custom'}
                    ext={slide.styles.imgFormat}
                    backgroundColor={slide.styles.bg}
                    layout={currentSlide?.layout}
                  />
                </Background>))}
            </BackgroundTransition>

            {!isPromoLayout && (
              <DefaultLayout
                words={currentSlide ? defaultContent[currentSlide.goodsType] : []}
                buttonText={withoutSales || isZeroPrice ? buttonTranslations.outOfStock : translations[currentSlide.id]?.button || buttonTranslations.buy}
                actionType={actionType}
                tablet={tablet}
                currentTestName={currentTestName}
                isDefaultWgs={isDefaultWgs}
                defaultContent={defaultContent}
                price={currentGoodsItem ? formatter.format(currentGoodsItem?.price) : ''}
                currentPrice={currentGoodsItem ? formatter.format(currentGoodsItem.currentPrice) : ''}
                mobile={mobile}
                currentSlide={currentSlide}
                currentGoodsItem={currentGoodsItem}
                locale={locale}
                isZeroPrice={isZeroPrice}
              />
            )}

            {isPromoLayout && !isUsRegion && (
              <PromoLayout
                buttonScrollText={translations && translations[currentSlide.id]?.button}
                currentTestName={currentTestName}
                mobile={mobile}
                description={translations[currentSlide?.id]?.description}
                currentPrice={currentGoodsItem ? formatter.format(currentGoodsItem.currentPrice) : ''}
                price={currentGoodsItem ? formatter.format(currentGoodsItem.price) : ''}
                title={mobile || tablet ? translations?.[currentSlide?.id]?.title_mob : translations?.[currentSlide?.id]?.title}
                currentGoodsItem={currentGoodsItem}
                locale={locale}
                actionType={actionType}
                isDefaultWgs={isDefaultWgs}
                buttonPurchaseText={withoutSales || isZeroPrice  ? buttonTranslations.outOfStock : translations[currentSlide.id]?.button || buttonTranslations.buy}
                currentSlide={currentSlide}
                discount={discount}
                scrollToProducts={scrollToProducts}
                isBlackColor={isBlackColor}
                isZeroPrice={isZeroPrice}
              />
            )}

            {isPromoLayout && isUsRegion && (
              <UsLayout
                words={currentSlide ? defaultContent[currentSlide.goodsType] : []}
                mobile={mobile}
                description={translations[currentSlide?.id]?.description}
                currentPrice={currentGoodsItem ? formatter.format(currentGoodsItem.currentPrice) : ''}
                price={currentGoodsItem ? formatter.format(currentGoodsItem.price) : ''}
                currentGoodsItem={currentGoodsItem}
                locale={locale}
                actionType={actionType}
                tablet={tablet}
                isDefaultWgs={isDefaultWgs}
                defaultContent={defaultContent}
                buttonDemoText={translations[currentSlide.id]?.button_demo}
                buttonPurchaseText={withoutSales ? buttonTranslations.outOfStock : translations[currentSlide.id]?.button || buttonTranslations.buy}
                buttonScrollText={translations && translations[currentSlide.id]?.button}
                currentSlide={currentSlide}
                discount={discount}
                scrollToProducts={scrollToProducts}
                isBlackColor={isBlackColor}
                isZeroPrice={isZeroPrice}
              />
            )}

            {slides.length > 1 && (
              <PaginationBox
                className={classNames({
                  'h-color-D100': isDefaultWgs,
                })}
              >
                <Pagination
                  count={slides.length}
                  current={index}
                  progress={progress}
                  onChange={setSlide}
                />
              </PaginationBox>
            )}
          </>
        )}
      </Wrapper>
    </Container>)
}

Carousel.propTypes = {
  withoutSales: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  translations: PropTypes.object.isRequired,
  buttonTranslations: PropTypes.shape({
    outOfStock: PropTypes.string.isRequired,
    buy: PropTypes.string.isRequired,
  }).isRequired,
  path: PropTypes.string.isRequired,
  defaultContent: PropTypes.shape({
    microbiome: PropTypes.arrayOf(PropTypes.string),
    wgs: PropTypes.arrayOf(PropTypes.string),
    dna: PropTypes.arrayOf(PropTypes.string),
    basePhrase: PropTypes.string,
    basePhraseLg: PropTypes.string,
  }),
}

export default React.memo(Carousel)
