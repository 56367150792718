import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import './Overlay.scss'

/**
 * Overlay component — you can use it locally or in a React Portal
 *
 * Use it with modals, side menu and etc
 * @return {Component} Component - Overlay
 */
class Overlay extends Component {
  static propTypes = {
    /** Flag to control using of React  */
    inPortal: PropTypes.bool,

    /** Flag to control visibility */
    isVisible: PropTypes.bool,

    /** Function which call after toggle */
    onClick: PropTypes.func,
  }

  static defaultProps = {
    inPortal: false,
    isVisible: false,
  }

  constructor(props) {
    super(props)

    this.mountNode = null
  }

  componentDidMount() {
    this.mountNode = document.querySelector('#overlay-root')
  }

  /**
   * ToggleHorizontal component
   * @param {object} event - event of click on portal
   * @return {void} null
   */
  handleClick = event => {
    const { onClick } = this.props

    if (onClick) {
      onClick(event)
    }
  }

  render() {
    const { isVisible, inPortal } = this.props
    const visibleOverlay = isVisible ? 'overlay--visible ' : ''
    const OverlayNode = (
      <div className={`overlay ${visibleOverlay}`} onClick={this.handleClick} />
    )

    if (inPortal) {
      return ReactDOM.createPortal(OverlayNode, this.mountNode)
    }

    return OverlayNode
  }
}

export default Overlay
