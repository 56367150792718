import PropTypes from 'prop-types'
import React, { Fragment } from 'react'

import Icon from 'components/Icon'

import TraitsListBlock from './components/TraitsListBlock'
import {
  H3Styled,
  Line,
  IconBox,
  TitleWrapper,
  LineWrapper,
  IconWrapper,
} from './styles'

const ICONS = {
  health: 'health',
  nutrition: 'nutrition',
  other_health: 'other_health',
  pill: 'pill',
  sports: 'sneaker',
  ancestry_gray: 'ancestry_gray',
  personality: 'person',
}

const TraitsListSections = ({ sections, modalRef }) => {
  return sections.map((item, key) => (
    <Fragment key={key}>
      <TitleWrapper>
        <div>
          <H3Styled dangerouslySetInnerHTML={{ __html: item.title }} />
        </div>

        <LineWrapper>
          <Line />
        </LineWrapper>

        <IconWrapper>
          <IconBox>
            <Icon id={`color_${ICONS[item.code]}`} />
          </IconBox>
        </IconWrapper>
      </TitleWrapper>
      <TraitsListBlock modalRef={modalRef} items={item.list} />
    </Fragment>
  ))
}

TraitsListSections.propTypes = {
  sections: PropTypes.array.isRequired,
  modalRef: PropTypes.object,
}

export default React.memo(TraitsListSections)
