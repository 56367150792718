import PropTypes from 'prop-types'
import React from 'react'

import ButtonCircle from 'components/ButtonCircle'
import LinkInlineArrowShort from 'components/LinkInlineArrowShort'

import { Wrapper, ButtonBox, Content, Logo } from './styles'

/**
 * Publication component with a little bit different view for desktop and mobile
 *
 * @param {object} props — properties of Publication
 * @return {func} Publication - stateless component of Publication
 */
const Publication = ({ data, logo, inView }) => (
  <Wrapper className="px-2">
    <a href={data.link} target="_blank" rel="noopener noreferrer">
      <div className="px-2 mb-2">
        <Logo image={logo} inView={inView} />
      </div>
      <Content className="pb-4 pb-md-0">
        <LinkInlineArrowShort
          isWhite={true}
          as="span"
          to={''}
          isHoverable={false}
          classNameArrow="publication__arrow"
        >
          {data.content}
        </LinkInlineArrowShort>
      </Content>
      <ButtonBox>
        <ButtonCircle
          as="button"
          bgClassName="bg--gray--op-06"
          textClassName="text--white"
          icon="arrow_right"
          iconColor="black"
          isHoverable={false}
        />
      </ButtonBox>
    </a>
  </Wrapper>
)

Publication.propTypes = {
  /** Data for publication rendering */
  data: PropTypes.object.isRequired,

  /** Url to load logo for this publication */
  logo: PropTypes.string.isRequired,

  /** Is in view */
  inView: PropTypes.bool,
}

Publication.defaultProps = {
  inView: true,
}

export default Publication
