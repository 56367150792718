import { em } from 'polished'
import styled, { css } from 'styled-components'

import { BoxBase } from 'styles/components/layout'
import { grassBg, white } from 'styles/theme/colors'
import { setBackground } from 'utils/styled/setBackground'

export const BoxBaseStyled = styled(BoxBase)`
  background: ${grassBg} right bottom no-repeat;
  background-size: 320px auto;
  color: ${white};
  padding-top: ${em(60)};
  padding-bottom: ${em(205)};

  ${p =>
    p.inView &&
    css`
      ${setBackground('png', p.coverUrl, { xs: 598, md: 774, lg: 1150 }, true, {
        xs: 100,
        md: 100,
        lg: 100,
      })};
    `}

  ${p => p.theme.mq.upMd} {
    padding-top: ${em(50)};
    padding-bottom: ${em(67)};
    background-position: right center;
    background-size: auto 100%;
  }

  ${p => p.theme.mq.upLg} {
    background-position: center center;
    background-position: calc(50% + ${em(380)}) center;
    padding-bottom: ${em(58)};
  }
`

export const ContentWrapper = styled.div`
  ${p => p.theme.mq.upLg} {
    display: flex;
  }
`

export const TextCol = styled.div`
  ${p => p.theme.mq.upLg} {
    max-width: ${em(305)};
  }
`

export const ButtonBox = styled.div`
  flex-shrink: 0;
  margin-top: ${em(30)};

  ${p => p.theme.mq.upMd} {
    margin-top: ${em(38)};
  }

  ${p => p.theme.mq.upLg} {
    margin-top: ${em(7)};
    margin-left: ${em(40)};
  }
`
