import PropTypes from 'prop-types'
import React, { useState } from 'react'
import classNames from 'classnames'

import { Container, Title, Number, LinkButton, YoutubeVideo } from './styles'
import { Dialog } from 'shared/Dialog'

const CardHowItWork = ({ number, title, link, mobile }) => {
  const isLast = number === 6
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleClick = () => {
    setIsModalOpen(true)
  }

  return (
    <>
      <Container number={number} isLast={isLast}>
        {!isLast && (
          <Number size={['16/20', '16/20', '14/20']} className='h-color-W100'>
            {number}
          </Number>
        )}

        <Title
          className={classNames({
            'h-color-W100': isLast,
          })}
          number={number}
          isLast={isLast}
          forwardedAs={'h2'}
          size={isLast ? ['22/30', '22/30', '16/20'] : ['18/24', '18/24', '16/20']}
          dangerouslySetInnerHTML={{ __html: title }}
        />

        {link && (
          <LinkButton
            variant={'outlined'}
            color={'white'}
            size={mobile ? 'mobile' : 'desktop'}
            isDangerouslySet={false}
            onClick={handleClick}
          >
          <span>
           <svg width='12' height='15' viewBox='0 0 12 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
             <path d='M0 14.5V0.5L11.375 7.5L0 14.5Z' fill='white' />
           </svg>
           Watch video
          </span>
          </LinkButton>
        )}

      </Container>

      {isModalOpen && (
        <Dialog isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
          <YoutubeVideo>
            <iframe
              width='100%'
              height='100%'
              src='https://www.youtube.com/embed/WFUV6CKLIFY'
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              allowFullScreen
            ></iframe>
          </YoutubeVideo>
        </Dialog>
      )}
    </>
  )
}

CardHowItWork.propTypes = {
  title: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  mobile: PropTypes.bool.isRequired,
  link: PropTypes.object,
}

export default CardHowItWork
