import styled from 'styled-components'

export const Media = styled.video`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
`
