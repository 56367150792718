import styled from 'styled-components'

import SectionWrapper from 'components/SectionWrapper'
import { Text } from 'styles/components/typography'

export const SectionWrapperStyled = styled(SectionWrapper)`
  background: ${p => p.theme.colors.catskillWhite};
    min-height: ${p => p.theme.blocks.heightBlock630Md};
  }

  .inherited-height {
    min-height: inherit;
  }
`

export const TextStyled = styled(Text)`
  max-width: none;

  ${p => p.theme.mq.upMd} {
    max-width: 80%;
  }
`
