import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { css } from 'styled-components'

import ButtonRoundedSt from 'components/ButtonRoundedSt'
import ButtonSuccessfulSt from 'components/ButtonSuccessfulSt'
import ControlAmount from 'components/ControlAmount'
import { GOODS_TYPES } from 'constants/sales'
import { ResizeContext } from 'contexts/Resize'
import createNumberFormatter from 'utils/createPriceFormatter'
import { Text } from 'shared/Typography'
import { Button } from 'shared/Button'
import { BoxBase } from 'styles/components/layout'
import useSaleCard from 'hooks/useSaleCard'

import {
  Wrapper,
  Container,
  ContentWrapper,
  TextBlock,
  Title,
  Description,
  ButtonWrapper,
  PriceAndButtons,
  Prices,
  DiscountBadge,
  OldPrice,
  Price,
  Subscribe,
  IconPig,
  ReadMore,
  SubscribeText,
  ControlAmountContainer,
} from './styles'
import pig from './assets/pig.png'
import { Dialog } from 'shared/Dialog'
import SubscriptionInfo from 'widgets/SubscriptionInfo'

export const OrderTestWidget = ({ className = '', productType }) => {
  const { commonUpsaleProps, dict } = useSaleCard({
    productType,
    isSubscription: false,
  })
  const {
    isCartInProgress,
    boxesPath,
    buttonHandleClickBegin,
    buttonHandleClickEnd,
    buttonLinkClick,
    buttonLink,
    buttonAsPath,
    buttonLinkAs,
    buttonLinkTitle,
    buttonTitle,
    isFetching,
    costCurrency,
    costLocale,
    costValue,
    costRealValue,
    counterAmount,
    counterAmountIsActive,
    counterDescription,
    counterHandleDecrease,
    counterHandleIncrease,
    isDisabled,
  } = commonUpsaleProps
  const { tablet, mobile } = React.useContext(ResizeContext)
  const [discount, setDiscount] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const buttonAnimAlign = tablet ? 'left' : 'center'
  const isWhite = productType === GOODS_TYPES.wgs
  const buttonWidth = tablet ? undefined : mobile ? 200 : 150

  const title = dict.kits[productType].title_long
  const description = dict.kits[productType].description

  const formatter = useMemo(() => {
    if (!costCurrency) return

    return createNumberFormatter({ locale: 'ru', currency: costCurrency })
  }, [costLocale, costCurrency])

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const btnProps = React.useMemo(() => {
    if (isWhite) {
      return {
        bgStyles: css`
          background-color: ${p => p.theme.colors.white};
        `,
        textStyles: css`
          color: ${p => p.theme.colors.black};
        `,
      }
    }

    return {}
  }, [isWhite])

  const showControlsBlock =
    counterAmount &&
    counterHandleDecrease &&
    counterHandleIncrease &&
    counterDescription

  const showCostBlock = !isDisabled && costCurrency && costLocale

  const buttonBlock = React.useMemo(() => {
    if (
      buttonHandleClickBegin &&
      buttonHandleClickEnd &&
      buttonTitle &&
      !isDisabled
    ) {
      return (
        <ButtonSuccessfulSt
          firstClickBeforeCb={buttonHandleClickBegin}
          firstClickCb={buttonHandleClickEnd}
          secondBtnAs={buttonLinkAs}
          secondClickTo={buttonLink}
          secondClickAsPath={buttonAsPath}
          secondClickText={buttonLinkTitle}
          secondClickCb={buttonLinkClick}
          animAlign={buttonAnimAlign}
          btnWidth={buttonWidth}
          {...btnProps}
        >
          {buttonTitle}
        </ButtonSuccessfulSt>
      )
    }

    return (
      <ButtonRoundedSt
        as={buttonLinkAs}
        to={buttonLink}
        isDisabled={isDisabled}
        asPath={buttonAsPath}
        {...btnProps}
      >
        {buttonLinkTitle}
      </ButtonRoundedSt>
    )
  }, [
    btnProps,
    buttonHandleClickBegin,
    buttonHandleClickEnd,
    buttonLinkClick,
    buttonLinkAs,
    buttonAsPath,
    buttonLink,
    buttonLinkTitle,
    buttonAnimAlign,
    buttonWidth,
    buttonTitle,
    isDisabled,
  ])

  useEffect(() => {
    if (costRealValue !== costValue) {
      setDiscount(Math.round(100 - 100 * costValue / costRealValue))
    }
  }, [costValue, costRealValue])

  const text = 'Subscribe and get <span>30%</span> off'

  return (
    <>
      <Wrapper className={className} boxesPath={`${boxesPath}/${costLocale}`}>
        <BoxBase>
          <Container boxesPath={`${boxesPath}/${costLocale}`}>
            <ContentWrapper>
              <TextBlock>
                <Title
                  forwardedAs='h2'
                  size={['50/60', '50/60', '35/40']}
                  weight={'medium'}
                  dangerouslySetInnerHTML={{ __html: title }}
                />

                <Description
                  size={['18/24', '18/24', '16/25']}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </TextBlock>

              <PriceAndButtons>
                {showControlsBlock && (
                  <ControlAmountContainer>
                    <ControlAmount
                      isWhite={isWhite}
                      amount={counterAmount}
                      isActive={counterAmountIsActive && !isDisabled}
                      onMinusClick={counterHandleDecrease}
                      onPlusClick={counterHandleIncrease}
                      description={counterDescription}
                      descriptionPosition='bottom'
                    />

                    {!isFetching && !isCartInProgress && buttonBlock && mobile && (
                      <ButtonWrapper>
                        {buttonBlock}
                      </ButtonWrapper>
                    )}
                  </ControlAmountContainer>
                )}

                {showCostBlock && (
                  <Prices>
                    {costRealValue !== costValue && (
                      <OldPrice>
                        <Text
                          as='h5'
                          weight='medium'
                          size={mobile ? '18/24' : '22/30'}
                          decoration='line-through'
                        >
                          {formatter.format(costRealValue)}
                        </Text>

                        {discount !== 0 && (
                          <DiscountBadge
                            size={['14/20', '14/20', '12/14']}
                            weight='medium'
                          >
                            -{discount}%
                          </DiscountBadge>
                        )}
                      </OldPrice>
                    )}

                    <Price
                      size={mobile ? '25/30' : '50/60'}
                      weight='medium'
                    >
                      {formatter.format(costValue)}
                    </Price>
                  </Prices>
                )}

                {!isFetching && !isCartInProgress && buttonBlock && !mobile && (
                  <ButtonWrapper>
                    {buttonBlock}
                  </ButtonWrapper>
                )}
              </PriceAndButtons>
            </ContentWrapper>

            <Subscribe>
              <IconPig src={pig} alt={'pig_icon'} />

              <ReadMore>
                <SubscribeText
                  size={['18/24', '18/24', '16/20']}
                  dangerouslySetInnerHTML={{ __html: text }}
                />

                <Button
                  variant={'text'}
                  onClick={handleOpenModal}
                >
                  Read more
                </Button>
              </ReadMore>
            </Subscribe>
          </Container>
        </BoxBase>
      </Wrapper>

      {isModalOpen && (
        <Dialog isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} fullscreen={mobile}>
          <SubscriptionInfo texts={dict.subscription_info} />
        </Dialog>
      )}
    </>
  )
}

OrderTestWidget.propTypes = {
  className: PropTypes.string,
  productType: PropTypes.oneOf([
    GOODS_TYPES.dna,
    GOODS_TYPES.biome,
    GOODS_TYPES.wgs,
  ]).isRequired,
}
