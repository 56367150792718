import PropTypes from 'prop-types'
import React from 'react'

import SliderMobile from 'components/SliderMobile'
import useInViewCommon from 'hooks/useInViewCommon'

import Publication from '../Publication'

import { Wrapper } from './styles'

const PublicationsMobile = ({ list, logos }) => {
  const [ref, inView] = useInViewCommon()

  if (!list?.length) {
    return null
  }

  return (
    <Wrapper className="pt-6 pb-6" ref={ref}>
      <SliderMobile
        isWhite={true}
        swiperArrowsClassName="publications-mobile__arrows"
      >
        {list.map((item, key) => (
          <div key={`publication.${key}`}>
            <Publication data={item} logo={logos[key]} inView={inView} />
          </div>
        ))}
      </SliderMobile>
    </Wrapper>
  )
}

PublicationsMobile.propTypes = {
  list: PropTypes.array.isRequired,
  logos: PropTypes.array.isRequired,
}

export default PublicationsMobile
