import styled from 'styled-components'
import { em } from 'polished'

import { Text } from 'shared/Typography'
import { BoxBase } from 'styles/components/layout'
import { Button } from 'shared/Button'

import bg from './assets/bg.svg'

export const Wrapper = styled.div`
  background-color: var(--color-V50);
  overflow: hidden;
`

export const Container = styled(BoxBase)`
  display: flex;
  flex-direction: column;
  padding-top: ${em(40)};
  padding-bottom: ${em(104)};
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-position: 99% bottom;

  ${({ theme }) => theme.mq.upLg} {
    flex-direction: row;
    justify-content: space-between;
    padding-top: ${em(60)};
    padding-bottom: ${em(77)};
    background-position: 26% bottom;
  }
`

export const Title = styled(Text)`
  overflow: hidden;
  margin-bottom: 10px;
  color: var(--color-W100);
  text-align: center;

  span {
    display: inline-block;
    padding: ${em(0)} ${em(3)} ${em(2)} ${em(3)};
    color: var(--color-D100);
    line-height: 1;
  }

  span:first-child {
    border-radius: ${em(5)} ${em(5)} ${em(5)} 0px;
    background-color: #f5bde2;
  }

  span:last-child {
    border-radius: ${em(5)} ${em(5)} 0 ${em(5)};
    background-color: #fffdbb;
  }

  ${({ theme }) => theme.mq.upMd} {
    max-width: ${em(434, 35)};
    margin-bottom: ${em(20, 35)};
    text-align: left;
  }
`

export const Description = styled(Text)`
  margin-bottom: ${em(25, 16)};
  color: var(--color-W100);
  text-align: center;

  ${({ theme }) => theme.mq.upMd} {
    max-width: ${em(368, 18)};
    margin-bottom: ${em(30, 18)};
    text-align: left;
  }
`

export const Texts = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  ${({ theme }) => theme.mq.upMd} {
  }

  ${({ theme }) => theme.mq.upLg} {
    min-width: ${em(450)}
  }
`

export const Cards = styled.div`
  margin-top: ${em(30)};
  margin-left: -${em(20)};
  margin-right: -${em(20)};
  padding-left: ${em(20)};

  .swiper-container {
    padding-bottom: ${em(35)};

    .swiper-pagination-bullet-active {
      background-color: var(--color-W100);
    }

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      transform: none;
      color: var(--color-W10);
    }
  }

  ${({ theme }) => theme.mq.upMd} {
    display: flex;
    gap: ${em(20)};
    margin: 0;
    padding: 0;

    .swiper-container {
      padding-bottom: ${em(0)};
    }
  }
`

export const ButtonStyled = styled(Button)`
  align-self: center;
  width: ${em(206)};
  height: ${em(40)};

  button {
    height: ${em(40)} !important;

    ${({ theme }) => theme.mq.upMd} {
      height: auto;
    }
  }

  ${({ theme }) => theme.mq.upMd} {
    width: auto;
    height: auto;
    align-self: flex-start;
  }
`
