import PropTypes from 'prop-types'
import React, { useRef } from 'react'

import { JP, TR } from 'constants/countries'
import { Text } from 'shared/Typography'

import { useTyped } from '../../hooks'
import { Mark } from './styles'

const TypedText = ({ locale, markColor, basePhrase, words, mobile }) => {
  const typedDesktop = useRef()
  const typedMob = useRef()

  useTyped(words, typedDesktop)
  useTyped(words, typedMob)

  return (
    <>
      <Text
        forwardedAs='h1'
        weight='medium'
        size={['80/90', '80/90', '35/40']}
      >
        {[JP, TR].includes(locale) && (
          <Mark color={markColor}>
            <span ref={typedMob ? typedMob : typedDesktop} />
          </Mark>
        )}

        <span dangerouslySetInnerHTML={{ __html: basePhrase }} />

        {![JP, TR].includes(locale) && (
          <Mark color={markColor}>
            <span ref={mobile ? typedMob : typedDesktop} />
          </Mark>
        )}
      </Text>
    </>
  )
}

TypedText.propTypes = {
  markColor: PropTypes.string.isRequired,
  basePhrase: PropTypes.string.isRequired,
  words: PropTypes.array.isRequired,
  locale: PropTypes.string.isRequired,
  mobile: PropTypes.bool.isRequired,
}

export default TypedText
