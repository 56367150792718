import styled from 'styled-components'
import { em } from 'polished'

import { Text } from 'shared/Typography'

export const Container = styled.div`
  max-width: ${em(760)};
  background: var(--color-G50);
  border-radius: ${em(15)};

  ${({ theme }) => theme.mq.upMd} {
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: ${em(20)};
  padding-right: ${em(20)};
  overflow: hidden;
  cursor: pointer;
  user-select: none;

  svg {
    flex-shrink: 0;
    width: ${em(26)};
    height: ${em(26)};

    ${({ theme }) => theme.mq.upMd} {
      width: ${em(32)};
      height: ${em(32)};
    }
  }

  ${({ theme }) => theme.mq.upMd} {
    padding-left: ${em(30)};
    padding-right: ${em(30)};
  }
`

export const HeaderTitle = styled(Text)`
  display: flex;
  margin-top: ${em(20, 16)};
  margin-bottom: ${em(20, 16)};

  ${({ theme }) => theme.mq.upMd} {
    margin-top: ${em(28, 22)};
    margin-bottom: ${em(32, 22)};
  }
`

export const ContentContainer = styled.div`
  max-height: ${p => p.isOpen ? '1000px' : '0px'};
  transition: max-height ease-in-out 0.3s;
  overflow: hidden;
`

export const Content = styled(Text)`
  padding: ${em(0)} ${em(20)} ${em(20)};

  ${({ theme }) => theme.mq.upMd} {
    padding: ${em(0)} ${em(30)} ${em(29)};
  }
`

export const Title = styled(Text)`
  text-align: center;
`
