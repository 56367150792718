import { em } from 'polished'
import styled from 'styled-components'

import { Col } from 'styles/components/layout'

export const ColStyled = styled(Col)`
  padding-bottom: ${em(60)};
`

export const ButtonBox = styled.div`
  position: fixed;
  @supports (position: sticky) {
    position: sticky;
  }
  left: 0;
  right: 0;
  bottom: ${p => p.theme.indents.baseIndentXs};
  margin: auto;
  text-align: center;

  & .button {
    width: 100%;
  }
`
