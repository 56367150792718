import { em } from 'polished'
import styled, { css } from 'styled-components'

export const Label = styled.label`
  display: block;
  background: ${p => p.theme.colors.white};
  border-radius: ${em(10)};
  position: relative;
  padding: 0 ${em(20)};
  box-sizing: content-box;
  border: 1px solid transparent;

  ${p =>
    p.isError &&
    css`
      border-color: ${p.theme.colors.materialRed};
    `}
`

export const Input = styled.input`
  color: ${p => p.theme.colors.black};
  background-color: transparent;
  border: none;
  font-size: ${p => p.theme.typography.fontSize};
  line-height: ${p => em(50, p.theme.typography.fontSizeBasePx)};
  width: 100%;
  padding: 0;
  &:focus {
    outline: none;
  }
`

export const ErrorMsg = styled.span`
  position: absolute;
  top: 100%;
  left: 0;
  color: ${p => p.theme.colors.materialRed};
  font-size: ${em(12)};
`
