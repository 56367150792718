import styled from 'styled-components'
import { em } from 'polished'

import { BoxBase } from 'styles/components/layout'
import { Text } from 'shared/Typography'

export const Content = styled(BoxBase)`
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: ${em(40)};
  padding-bottom: ${em(135)};

  ${p => p.theme.mq.upMd} {
    padding-top: ${em(60)};
    padding-bottom: ${em(178)};
  }

  ${p => p.theme.mq.downLg} {
    padding-right: 20px;
    padding-left: 20px;
  }

    ${p => p.theme.mq.upThreshold(1024)} {
    flex-direction: column;
    padding-top: ${em(70)};
    padding-bottom: ${em(130)};
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`

export const Title = styled(Text)`
  margin-bottom: 10px;

  & span,
  & mark {
    color: ${({ markColor }) => markColor};
  }

  ${p => p.theme.mq.upMd} {
    margin-bottom: 20px;
  }

  ${p => p.theme.mq.upThreshold(1024)} {
    margin-bottom: 30px;
  }
`

export const Description = styled(Text)`
  max-width: 100%;

  a {
    color: ${({ linkColor }) => linkColor};
  }

  ${p => p.theme.mq.upThreshold(1024)} {
    max-width: ${em(405)};
  }
}
`

export const PriceAndButton = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${em(20)};

  ${p => p.theme.mq.downMd} {
    gap: ${em(20)};
  }
`

export const OldPrice = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const DiscountBadge = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${em(25.5)};
  padding: 0 ${em(5)};
  background-color: ${({ theme, bg }) => theme.colors[bg] || bg};
  border: 1px solid ${({ theme, badgeBorderColor }) => theme.colors[badgeBorderColor] || badgeBorderColor};
  border-radius: ${em(4)};
  color: ${({ theme, badgeColor }) => theme.colors[badgeColor] || badgeColor};
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  min-width: ${em(104)};
  margin-top: 20px;

  ${p => p.theme.mq.upThreshold(1024)} {
    min-width: ${em(197)};
  }

  ${p => p.theme.mq.upMd} {
    min-width: ${em(230)};
    margin-top: 30px;
  }

  ${p => p.theme.mq.upLg} {
    margin-top: auto;
    align-self: auto;
  }
`
