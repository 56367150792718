import React from 'react'
import PropTypes from 'prop-types'

import Icon from 'components/Icon'

import { Container, HeaderContainer, HeaderTitle, ContentContainer, Content } from './styles'

const Accordion = ({ title, content, isOpen, setIsOpen, index }) => {

  const handleToggle = () => {
    if (isOpen) {
      setIsOpen(null)
    } else {
      setIsOpen(index)
    }
  }

  return (
    <Container>
      <HeaderContainer onClick={handleToggle}>
        <HeaderTitle
          size={['22/30', '22/30', '16/20']}
          weight={'medium'}
          dangerouslySetInnerHTML={{ __html: title }}
          isOpen={isOpen}
        />

        {isOpen
          ? <Icon id={'btn_minus'} color={'black'} strokeWidth={'2'} />
          : <Icon id={'btn_plus'} color={'black'} strokeWidth={'2'} />
        }
      </HeaderContainer>

      <ContentContainer isOpen={isOpen}>
        <Content
          size={['18/28', '16/25', '16/25']}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </ContentContainer>

    </Container>)
}

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
}

export default Accordion
