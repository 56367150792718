import PropTypes from 'prop-types'
import React from 'react'

import { LocalizationContext } from 'contexts/Localization'
import { US } from 'constants/countries'
import Icon from 'components/Icon'

import { TextP, Article, IconWrapper, ImageIcon, BoldStyled, TextStyled, ReportsCount } from './styles'

const FeaturesPlateItem = ({ item, icon, itemsTotal, isTextWhite }) => {
  const { locale, paths: { shared } } = React.useContext(LocalizationContext)
  const isUs = locale === US

  return (
    <Article {...{ itemsTotal }}>
      {item.amount && (
        <div className='pb-4'>
          <TextStyled
            {...{ isTextWhite }}
            dangerouslySetInnerHTML={{ __html: item.amount }}
          />
        </div>
      )}
      {isUs ? (
        <ImageIcon src={`${shared}/icons/${item.code}.png`} alt={`${icon}_img`} />
      ) : (
        <IconWrapper className='mb-2'>
          <Icon id={icon} />
        </IconWrapper>
      )}
      <BoldStyled
        {...{ isTextWhite }}
        dangerouslySetInnerHTML={{ __html: item.title }}
      />
      <TextP
        {...{ isTextWhite }}
        dangerouslySetInnerHTML={{ __html: item.description }}
      />

      {isUs && (
        <ReportsCount
          className='h-color-D30' size={['18/28', '18/28', '18/28']}
          dangerouslySetInnerHTML={{ __html: item.reports_count }}
        />
      )}
    </Article>
  )
}

FeaturesPlateItem.propTypes = {
  icon: PropTypes.string,
  item: PropTypes.object.isRequired,
  itemsTotal: PropTypes.number,
  isTextWhite: PropTypes.bool,
}

FeaturesPlateItem.defaultProps = {
  isTitleHidden: false,
  mobile: false,
  itemsTotal: 0,
}

export default FeaturesPlateItem
