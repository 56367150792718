export const getGETQueries = url => {
  const obj = {}
  const queryStringWithHashes = url
    ? url.split('?')[1]
    : window.location.search.slice(1)

  // if query string exists
  if (queryStringWithHashes) {
    // stuff after # is not part of query string, so get rid of it
    const [queryString] = queryStringWithHashes.split('#')

    // split our query string into its component parts
    const arr = queryString.split('&')

    for (let i = 0; i < arr.length; i++) {
      // separate the keys and the values
      const a = arr[i].split('=')

      // in case params look like: list[]=thing1&list[]=thing2
      let paramName = a[0].replace(/\[\d*]/, '')
      let [, paramValue] = a

      if (typeof paramValue !== 'undefined') {
        // (optional) keep case consistent
        paramName = paramName.toLowerCase()
        paramValue = paramValue.toLowerCase()

        // We save last value - if parameter already exists we rewrite it
        obj[paramName] = paramValue
      }
    }
  }

  return obj
}
