import React from 'react'

import { home, modalTraitsList } from 'routes'

export const useTraitsList = ({ queryParams, router, locale }) => {
  const [isTraitsOpened, setIsTraitsOpened] = React.useState(false)
  const newParamsInJSON = React.useMemo(() => {
    const newParams = queryParams || router.query
    return JSON.stringify(newParams)
  }, [queryParams, router.query])
  const closeTraitsModal = React.useCallback(() => {
    router.replace(home.link(locale), home.as(locale))
  }, [router, locale])
  React.useEffect(() => {
    const params = queryParams || router.query
    setIsTraitsOpened(params?.modal === modalTraitsList.name)
  }, [newParamsInJSON, queryParams, router.query])

  return {
    isTraitsOpened,
    closeTraitsModal,
  }
}
