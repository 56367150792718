import { em } from 'polished'
import styled from 'styled-components'

import { H1Small } from 'styles/components/typography'

export const H1Styled = styled(H1Small)`
  text-align: center;

  ${p => p.theme.mq.upMd} {
    padding-top: ${p => em(60, p.theme.typography.h1MdSmallPx)};
  }

  ${p => p.theme.mq.upLg} {
    padding-top: ${p => em(90, p.theme.typography.h1MdSmallPx)};
  }
`
