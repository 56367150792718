import PropTypes from 'prop-types'
import React from 'react'

import ButtonClose from 'components/ButtonClose'
import DynamicImage from 'components/DynamicImage'
import RenderInView from 'components/RenderInView'
import { ResizeContext } from 'contexts/Resize'
import SubscribeEbookForm from 'forms/SubscribeEbookForm'
import { Col } from 'styles/components/layout'
import { gtmEvent } from 'utils/client/analitics'
import LocalStorage from 'utils/client/localStorage'

import { rollUp } from './animations'
import {
  RowIndentedStyled,
  TitleBox,
  ImageBox,
  CloseBox,
  BoxBaseStyled,
  Subtitle,
  H2LikeStyled,
} from './styles'

const blockLSId = 'abg_ebook_subscription_closed'

const SubscribeEbook = ({ texts, errorsDict, sharedPath, countryCode }) => {
  const { mobile, tablet } = React.useContext(ResizeContext)
  const boxRef = React.useRef()
  const lsRef = React.useRef()
  const [isClose, setClose] = React.useState(null)
  const [isNodeRemoved, removeNode] = React.useState(false)

  const closeForNextTime = React.useCallback(() => {
    lsRef.current?.setItem(blockLSId, true)
  }, [])

  const handleClose = React.useCallback(() => {
    closeForNextTime()
    setClose(true)
    gtmEvent({
      event: 'Block Ebook Subscribe — Closed',
    })
  }, [closeForNextTime])

  React.useEffect(() => {
    lsRef.current = new LocalStorage()
    const isOpened = !lsRef.current.getItem(blockLSId)

    if (isOpened) {
      setClose(false)
      gtmEvent({
        event: 'Block Ebook Subscribe — Showed',
      })
    }
  }, [])

  React.useEffect(() => {
    if (isClose && boxRef.current) {
      const tm = rollUp(boxRef.current, () => {
        removeNode(true)
      })

      return () => tm.kill()
    }
  }, [isClose])

  if (isClose === null || isNodeRemoved) {
    return null
  }

  return (
    <div ref={boxRef}>
      <BoxBaseStyled>
        <RowIndentedStyled>
          <Col
            display="flex"
            flexDirection="column"
            flexShrink="0"
            width={{ xs: 1, md: 1, lg: 7 / 12 }}
          >
            <TitleBox>
              <H2LikeStyled
                as="h2"
                dangerouslySetInnerHTML={{ __html: texts.title }}
              />
              <Subtitle dangerouslySetInnerHTML={{ __html: texts.subtitle }} />
            </TitleBox>

            <SubscribeEbookForm
              key={countryCode}
              {...{
                texts,
                errorsDict,
                countryCode,
                onSubmited: closeForNextTime,
                isMobile: mobile || tablet,
              }}
            />
          </Col>
          <Col
            display="flex"
            alignItems={{ xs: 'flex-start', md: 'center' }}
            justifyContent="center"
            width={{ xs: 1, md: 1, lg: 5 / 12 }}
            height={{ lg: '100%' }}
          >
            <ImageBox>
              <RenderInView display="block">
                <DynamicImage
                  imageSizes={{
                    xs: 156,
                    md: 338,
                    lg: 477,
                  }}
                  image={`${sharedPath}/ebook/ebook`}
                  imageType={'png'}
                  alt={'the secret life of gut bacteria book'}
                  namesByBreakpoints={true}
                />
              </RenderInView>
            </ImageBox>
          </Col>

          <CloseBox>
            <ButtonClose {...{ handleClick: handleClose, size: 'big' }} />
          </CloseBox>
        </RowIndentedStyled>
      </BoxBaseStyled>
    </div>
  )
}

SubscribeEbook.propTypes = {
  texts: PropTypes.object.isRequired,
  errorsDict: PropTypes.object.isRequired,
  sharedPath: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
}

export default SubscribeEbook
