import { em } from 'polished'
import styled from 'styled-components'

import TextFieldRounded from 'components/_form/TextFieldRounded'
import ButtonAsyncActionSt from 'components/ButtonAsyncActionSt'
import { TextLH } from 'styles/components/typography'

export const ButtonAsyncActionStStyled = styled(ButtonAsyncActionSt)`
  display: block;
  width: 100%;
  max-width: ${em(250)};
  margin: 0 auto;

  ${p => p.theme.mq.upMd} {
    width: auto;
    max-width: none;
    margin: 0 auto;
  }

  ${p => p.theme.mq.upLg} {
    width: auto;
    margin: 0;
  }
`

export const FormBox = styled.div`
  width: 100%;
  flex-shrink: 0;

  ${p => p.theme.mq.upMd} {
    max-width: none;
  }

  ${p => p.theme.mq.upLg} {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: ${em(40)};
  }
`

export const FieldBox = styled.div`
  width: 100%;
  margin-bottom: ${em(25)};
  box-sizing: border-box;

  ${p => p.theme.mq.upMd} {
    width: 100%;
    font-size: inherit;
  }

  ${p => p.theme.mq.upLg} {
    width: 100%;
    max-width: ${em(300)};
    margin-bottom: 0;
    margin-right: ${em(20)};
  }
`

export const TextFieldRoundedStyled = styled(TextFieldRounded)`
  font-size: ${p => p.theme.typography.cartFontSize};

  ${p => p.theme.mq.upMd} {
    font-size: ${p => p.theme.typography.fontSize};
    line-height: 1;
    padding-top: ${p => em(11, p.theme.typography.fontSizeBasePx)};
    padding-bottom: ${p => em(14, p.theme.typography.fontSizeBasePx)};
  }
`

export const BtnContent = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${em(25)};
  margin-top: -${em(3)};
`

export const BtnTitle = styled(TextLH)`
  width: 100%;
  flex-shrink: 0;
  text-align: center;

  ${p => p.theme.mq.upMd} {
    width: auto;
  }
`
