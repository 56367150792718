import PropTypes from 'prop-types'
import React from 'react'

import ButtonClose from 'components/ButtonClose'
import { ResizeContext } from 'contexts/Resize'
import SubscribeEbookFormUs from 'forms/SubscribeEbookFormUs'
import { gtmEvent } from 'utils/client/analitics'
import LocalStorage from 'utils/client/localStorage'
import { Text } from 'shared/Typography'

import { rollUp } from './animations'
import { Wrapper, Container, TitleBox, CloseBox, Subtitle, Title, ButtonSendAgain, Disclaimer } from './styles'

const blockLSId = 'abg_ebook_subscription_closed'

const UsSubscribeEbook = ({ texts, errorsDict, sharedPath, countryCode, className }) => {
  const { mobile } = React.useContext(ResizeContext)
  const boxRef = React.useRef()
  const lsRef = React.useRef()
  const [isClose, setClose] = React.useState(null)
  const [isNodeRemoved, removeNode] = React.useState(false)
  const [isSucceed, setIsSucceed] = React.useState(false)

  const closeForNextTime = React.useCallback(() => {
    lsRef.current?.setItem(blockLSId, true)
  }, [])

  const handleClose = React.useCallback(() => {
    closeForNextTime()
    setClose(true)
    gtmEvent({
      event: 'Block Ebook Subscribe — Closed',
    })
  }, [closeForNextTime])

  React.useEffect(() => {
    lsRef.current = new LocalStorage()
    const isOpened = !lsRef.current.getItem(blockLSId)

    if (isOpened) {
      setClose(false)
      gtmEvent({
        event: 'Block Ebook Subscribe — Showed',
      })
    }
  }, [])

  React.useEffect(() => {
    if (isClose && boxRef.current) {
      const tm = rollUp(boxRef.current, () => {
        removeNode(true)
      })

      return () => tm.kill()
    }
  }, [isClose])

  if (isClose === null || isNodeRemoved) {
    return null
  }

  return (
    <Wrapper ref={boxRef} className={className}>
      <Container
        isUs={countryCode}
        imagePath={`${sharedPath}/ebook`}
        countryCode={countryCode}
      >
        <TitleBox>
          <Title
            size={['35/40', '35/40', '25/30']}
            weight={'medium'}
            forwardedAs='h2'
            dangerouslySetInnerHTML={{ __html: isSucceed ? texts.success_title : texts.title }}
          />

          <Subtitle
            isSucceed={isSucceed}
            size={['18/24', '18/24', '16/20']}
            dangerouslySetInnerHTML={{ __html: isSucceed ? texts.success_description : texts.subtitle }}
          />
        </TitleBox>

        {!isSucceed && (
          <SubscribeEbookFormUs
            key={countryCode}
            isSucceed={isSucceed}
            setIsSucceed={setIsSucceed}
            texts={texts}
            errorsDict={errorsDict}
            countryCode={countryCode}
            onSubmited={closeForNextTime}
            isMobile={mobile}
          />
        )}

        {isSucceed && (
          <ButtonSendAgain
            onClick={() => setIsSucceed(false)}
          >
            <Text
              className='h-color-W100'
              decoration='underline'
              size={['18/24', '18/24', '16/20']}
              dangerouslySetInnerHTML={{ __html: texts.send_again }}
            />
          </ButtonSendAgain>
        )}

        {!isSucceed && (
          <Disclaimer
            size='12/14'
            className=''
            dangerouslySetInnerHTML={{ __html: texts.disclaimer }}
          />
        )}

        <CloseBox>
          <ButtonClose handleClick={handleClose} size={'big'} />
        </CloseBox>
      </Container>
    </Wrapper>
  )
}

UsSubscribeEbook.propTypes = {
  texts: PropTypes.object.isRequired,
  errorsDict: PropTypes.object.isRequired,
  sharedPath: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default UsSubscribeEbook
