import { em } from 'polished'
import styled, { css } from 'styled-components'

export const Navigation = styled.div`
  padding-left: ${p => p.theme.indents.baseIndentXs};
  margin-bottom: ${em(60)};
  overflow: hidden;

  ${p => p.theme.mq.upMd} {
    display: none;
  }
`

export const NavigationWrapper = styled.div`
  display: flex;
  transition: transform 0.3s;
`

export const NavigationItem = styled.span`
  font-family: ${p => p.theme.typography.mediumFont};
  font-size: ${p => p.theme.typography.textUppercase};
  line-height: ${p => p.theme.typography.textUppercaseLh};
  font-weight: 400;
  text-transform: uppercase;
  margin-right: ${em(20)};
  user-select: none;
  flex-shrink: 0;
  opacity: 0.4;
  transition: opacity 0.3s;

  ${p =>
    p.isWhite &&
    css`
      color: ${p => p.theme.colors.white};
    `}

  ${p =>
    p.isActive &&
    css`
      opacity: 1;
    `}
`
