import { em } from 'polished'
import styled, { css } from 'styled-components'

import DynamicImage from 'components/DynamicImage'
import { BoxBase, Col } from 'styles/components/layout'
import { H2 } from 'styles/components/typography'

export const BoxBaseStyled = styled(BoxBase)`
  padding-top: ${em(27)};
  padding-bottom: ${em(20)};

  ${p => p.theme.mq.upMd} {
    padding-top: ${em(47)};
    padding-bottom: ${em(40)};
  }

  ${p => p.theme.mq.upLg} {
    padding-top: ${em(60)};
  }
`

export const ColStyled = styled(Col)`
  ${p => p.theme.mq.upMd} {
    display: inline-flex;
    flex-grow: 1;
    min-width: ${em(470)};
  }

  ${p => p.theme.mq.upLg} {
    display: block;
  }
`

export const ContentWrapper = styled.div`
  position: relative;
  background-color: ${p => p.theme.colors.grayOp06NonTransp};
  border-radius: ${p => p.theme.blocks.blockBorderRadiusMedium};
  text-align: center;
  padding-top: ${em(49)};

  picture {
    margin-top: auto;
  }

  div span {
    font-size: 16px;
  }

  ${p => p.theme.mq.downMd} {
    h3 {
      font-size: 25px;
      line-height: 32px;
    }
  }

  ${p => p.theme.mq.upMd} {
    padding-top: ${em(60)};
    margin-top: ${em(40)};
    display: flex;
    flex-direction: column;
  }

  ${p => p.theme.mq.upLg} {
    margin-top: 0;
  }

  ${({ isSolo }) =>
    isSolo &&
    css`
      background-color: ${p => p.theme.colors.blue};
      color: ${p => p.theme.colors.white};
      margin-top: ${em(20)};

      ${p => p.theme.mq.upMd} {
        margin-top: ${em(40)};
      }

      ${p => p.theme.mq.upLg} {
        margin-top: 0;
      }
    `}

  ${({ isPro }) =>
    isPro &&
    css`
      padding-bottom: 175px;
      background-image: linear-gradient(180deg, #F7F7F7 0%, rgba(247, 247, 247, 0) 19.48%), url('${({ path, ext }) => `${path}_mob.${ext}`}');
      background-repeat: no-repeat;
      background-position: center 100%;
      background-size: contain;

      ${p => p.theme.mq.upMd} {
        padding-bottom: 250px;
        background-image: linear-gradient(180deg, #F7F7F7 0%, rgba(247, 247, 247, 0) 19.48%), url('${({ path, ext }) => `${path}_tab.${ext}`}');
        background-size: 100% 70%;
      }

      @media (min-width: 1024px) {
        padding-bottom: 0;
        background-image: linear-gradient(180deg, #F7F7F7 4.43%, rgba(247, 247, 247, 0) 15.52%), linear-gradient(106.5deg, #F8F8F8 13.68%, rgba(248, 248, 248, 0) 43.24%), url('${({ path, ext }) => `${path}.${ext}`}');
        background-size: 100% 50%;
      }

      ${p => p.theme.mq.upLg} {
        height: 100%;
        background-size: auto;
      }
    `}
`

export const TextBox = styled.div`
  width: 80%;
  margin: 0 auto ${em(20)};

  ${p => p.theme.mq.downMd} {
    p {
      font-size: 16px;
      line-height: 24px;
    }
  }

  ${p => p.theme.mq.upLg} {
    padding: 0;
    max-width: ${em(485)};
  }
`

export const H2Styled = styled(H2)`
  text-align: center;
  margin-bottom: ${p => em(44, p.theme.typography.h2Px)};

  ${p => p.theme.mq.upMd} {
    margin-bottom: ${p => em(16, p.theme.typography.h2MdPx)};
  }

  ${p => p.theme.mq.upLg} {
    margin-bottom: ${p => em(56, p.theme.typography.h2MdPx)};
  }
`

export const DynamicImageStyled = styled(DynamicImage)`
  display: block;
  margin: 0 auto;
  max-width: ${em(540)};

  ${({ isSolo }) =>
    isSolo &&
    css`
      ${p => p.theme.mq.upLg} {
        min-height: 260px;
      }
    `}
`

export const IconBox = styled.div`
  position: absolute;
  width: ${em(65)};
  height: ${em(18)};
  top: ${em(20)};
  right: ${em(20)};

  ${p => p.theme.mq.upMd} {
    width: ${em(80)};
    height: ${em(23)};
    top: ${em(30)};
    right: ${em(30)};
  }

  ${p => p.theme.mq.upLg} {
    right: ${em(40)};
  }
`
