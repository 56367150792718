export const TOKEN = 'token'
export const LS_CART_CODE = 'abg_cart'
export const LS_ORDER_CODE = 'abg_order'
export const PRIVACY_BANNER_SHOW = 'privacyBannerShow'

export default class LocalStorage {
  constructor() {
    if (process.browser && window.localStorage) {
      try {
        const x = 'storageTest'
        localStorage.setItem(x, x)
        localStorage.removeItem(x)
        this.store = window.localStorage
      } catch (e) {
        this.store = null
      }
    } else {
      this.store = null
    }
  }

  getItem = (key, parse = false) => {
    let item = {}

    if (this.store) {
      item = this.store.getItem(key)
      if (item) {
        item = parse ? JSON.parse(item) : item
      }

      return item
    }

    return null
  }

  setItem = (key, value) => {
    if (this.store) {
      return this.store.setItem(key, JSON.stringify(value))
    }

    return null
  }

  removeItem = key => {
    if (this.store) {
      return this.store.removeItem(key)
    }

    return null
  }

  clear = () => {
    if (this.store) {
      return this.store.clear()
    }

    return null
  }
}
