import PropTypes from 'prop-types'
import React from 'react'
import { css } from 'styled-components'

import ButtonRoundedSt from 'components/ButtonRoundedSt'
import DynamicImage from 'components/DynamicImage'
import { RU } from 'constants/countries'
import useInViewCommon from 'hooks/useInViewCommon'
import { biome, dna, wgs } from 'routes'
import { H2 } from 'styles/components/typography'

import {
  ButtonBox,
  ButtonWrapper,
  ColInner,
  ImageWrapper,
  TextStyled,
  HeaderBox,
} from './styles'

function TestCard({ locale, mediaPath, isItGenetic, dict }) {
  const [ref, inView] = useInViewCommon()

  const testProps = React.useMemo(
    () => ({
      title: isItGenetic ? dict.kits.dna.title : dict.kits.biome.title,
      text: isItGenetic
        ? dict.kits.about_tests.genetic.text
        : dict.kits.about_tests.biome.text,
      image: {
        path: isItGenetic
          ? `${mediaPath}/dna-about-test-v1`
          : `${mediaPath}/biome-about-test-v1`,
        size: locale === RU ? { xs: 253, md: 440 } : { xs: 350, md: 420 },
      },
      button: {
        text: isItGenetic ? dict.buttons.about_dna : dict.buttons.about_biome,
        link: {
          as: isItGenetic ? dna.as(locale) : biome.as(locale),
          to: isItGenetic ? dna.link(locale) : biome.link(locale),
        },
      },
    }),
    [
      dict.buttons.about_biome,
      dict.buttons.about_dna,
      dict.kits.about_tests.biome.text,
      dict.kits.about_tests.genetic.text,
      dict.kits.biome.title,
      dict.kits.dna.title,
      isItGenetic,
      locale,
      mediaPath,
    ],
  )

  return (
    <ColInner ref={ref}>
      <HeaderBox>
        <H2 as={'h3'} dangerouslySetInnerHTML={{ __html: testProps.title }} />
      </HeaderBox>
      <TextStyled dangerouslySetInnerHTML={{ __html: testProps.text }} />
      <ImageWrapper {...{ isItGenetic, locale }}>
        {inView && (
          <DynamicImage
            imageSizes={testProps.image.size}
            image={testProps.image.path}
            imageType="png"
            alt={testProps.title}
            namesByBreakpoints={false}
          />
        )}
      </ImageWrapper>

      <ButtonWrapper {...{ locale }}>
        {locale === RU && isItGenetic && (
          <ButtonBox>
            <ButtonRoundedSt
              as="Link"
              asPath={wgs.as(locale)}
              to={wgs.link(locale)}
              bgStyles={css`
                background-color: ${p => p.theme.colors.white};
              `}
              blackoutStyles={css`
                background-color: ${p => p.theme.colors.lilacDark};
              `}
              textStyles={css`
                color: ${p => p.theme.colors.black};

                ${p => p.theme.mq.hover} {
                  &:hover {
                    color: ${p => p.theme.colors.white};
                  }
                }
              `}
            >
              {dict.buttons.about_wgs}
            </ButtonRoundedSt>
          </ButtonBox>
        )}

        <ButtonBox withoutMargin={true}>
          <ButtonRoundedSt
            as="a"
            asPath={testProps.button.link.as}
            to={testProps.button.link.as}
            bgStyles={css`
              background-color: ${p => p.theme.colors.white};
            `}
            blackoutStyles={css`
              background-color: ${p => p.theme.colors.lilacDark};
            `}
            textStyles={css`
              color: ${p => p.theme.colors.black};

              ${p => p.theme.mq.hover} {
                &:hover {
                  color: ${p => p.theme.colors.white};
                }
              }
            `}
            target="_self"
          >
            {testProps.button.text}
          </ButtonRoundedSt>
        </ButtonBox>
      </ButtonWrapper>
    </ColInner>
  )
}

TestCard.propTypes = {
  locale: PropTypes.string.isRequired,
  mediaPath: PropTypes.string.isRequired,
  dict: PropTypes.object.isRequired,
  isItGenetic: PropTypes.bool,
}

TestCard.defaultProps = {
  isItGenetic: false,
}

export default React.memo(TestCard)
