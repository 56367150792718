import PropTypes from 'prop-types'
import React, { cloneElement, forwardRef } from 'react'
import { TransitionGroup, Transition } from 'react-transition-group'

import { Container } from './styles'

const BackgroundTransition = forwardRef(({ children, current }, ref) => {
  const activeBackground = children[current]

  return (
    <Container ref={ref}>
      <TransitionGroup component={null}>
        <Transition key={current} timeout={1000}>
          {state => cloneElement(activeBackground, { state })}
        </Transition>
      </TransitionGroup>
    </Container>
  )
})

BackgroundTransition.propTypes = {
  children: PropTypes.node,
  current: PropTypes.number,
}

export default BackgroundTransition
