import PropTypes from 'prop-types'
import React from 'react'

import { Text } from 'shared/Typography'

import { Container, Title, List, ListItem, Links } from './styles'
import google_pay from './assets/google_pay.png'
import app_store from './assets/app_store.png'

function CardAdvertApp({
  // title,
  // description,
  // reportText,
  // icon,
}) {
  const title = 'AI based food <br>tracking app'
  const list = ['Find out if your meal is gut-friendly', '24/7 access to your kits, reports, and recommendations', 'A "nutritionist" in your pocket will help make healthier choices when grocery shopping', 'Food diary to track and score your meals']
  return (
    <Container>
      <Title
        size={['35/40', '35/40', '25/30']}
        weight={'medium'}
        dangerouslySetInnerHTML={{ __html: title }}
      />

      <List>
        {list.map(text => (
          <ListItem key={text}>
            <Text size={['18/24', '18/24', '14/20']}>
              {text}
            </Text>
          </ListItem>))}
      </List>

      <Links>
        <a href='https://apps.apple.com/app/atlas-health/id1372132260' target='_blank'>
          <img src={app_store} />
        </a>

        <a href='https://play.google.com/store/apps/details?id=ru.atlas.health_android&hl=en_US&gl=US&pli=1' target='_blank'>
          <img src={google_pay} />
        </a>
      </Links>
    </Container>)
}

CardAdvertApp.propTypes = {
  // title: PropTypes.string.isRequired,
  // description: PropTypes.string.isRequired,
  // reportText: PropTypes.string.isRequired,
  // icon: PropTypes.string.isRequired,
}

export default CardAdvertApp
