import PropTypes from 'prop-types'
import React from 'react'

import { TextLH } from 'styles/components/typography'

import { Li } from './styles'

/**
 * Component of list's item. It is using within List component
 * @return {function} function — ListItem
 */
const ListItem = ({ children, bulletColor, className }) => {
  if (typeof children === 'object') {
    return <Li {...{ bulletColor, className }}>{children}</Li>
  }

  return (
    <Li {...{ bulletColor, className }}>
      <TextLH dangerouslySetInnerHTML={{ __html: children }} />
    </Li>
  )
}

ListItem.propTypes = {
  /** Color of bullet */
  bulletColor: PropTypes.oneOf([
    'white',
    'green',
    'blue',
    'dark-blue',
    'black',
    'purple',
  ]),

  /** Content of Item */
  children: PropTypes.node,

  /** Class name for list item */
  className: PropTypes.string,
}
ListItem.defaultProps = {
  bulletColor: 'blue',
  children: '',
  className: '',
}

export default ListItem
