import axios, { CancelToken } from 'axios'
import { forEach } from 'lodash'
import { CANCEL } from 'redux-saga'

import { apiPath } from 'constants/paths'
import { GOODS_TYPES } from 'constants/sales'
import { getCookie } from 'utils/client/cookie'
// import { gtmPushUserData } from './client/analitics'

/*
 * =========================
 * CONSTANTS
 * =========================
 */
const { DADATA_TOKEN, TEST_ORDER, API_URL } = process.env.server

/*
 * =========================
 * HTTP DEFAULTS CONFIG
 * =========================
 */

axios.defaults.baseURL = process.browser
  ? ''
  : `http://localhost:${process.env.PORT}`
const api2 = axios.create({
  baseURL: API_URL,
})

/*
 * =========================
 * INTERFACE
 * =========================
 */
export const getPage = ({ language, page }) => {
  const source = CancelToken.source()
  const request = axios
    .get(`${apiPath(language)}/${page}.json`, {
      cancelToken: source.token,
    })
    .then(r => ({
      response: {
        pages: {
          [page]: r.data,
        },
      },
    }))
    .catch(error => ({ error }))

  request[CANCEL] = () => source.cancel()

  return request
}

/*
 * =========================
 * ORDER PROCESS
 * =========================
 */
export const getSalesData = ({ locale, promocode }) => {
  const source = CancelToken.source()
  const request = api2
    .get(`/prices/current`, {
      params: {
        country: locale,
        promocode,
      },
      cancelToken: source.token,
    })
    .then(r => ({
      response: r.data,
    }))
    .catch(error => ({ error: error.response }))

  request[CANCEL] = () => source.cancel()

  return request
}

export const saveUnfinishedOrder = ({ cart, sales }) => {
  const source = CancelToken.source()
  const request = api2
    .post(
      '/order/unfinished',
      {
        ga_cid: cart.userGA,
        unfinished_id: cart.orderIdUnfinished,
        amountDna: cart.goods[GOODS_TYPES.dna].amount,
        amountGut: cart.goods[GOODS_TYPES.biome].amount,
        amount_wgs: cart.goods[GOODS_TYPES.wgs].amount,
        biome_subscribe: cart.goods[GOODS_TYPES.biome].isSubscription,
        country: cart.personal.country,
        phoneCode: cart.personal.phone_code,
        city: cart.personal.city,
        addr: cart.personal.address,
        phone: cart.personal.phone,
        email: cart.personal.email,
        promo: {
          id: cart.promo.id,
          name: cart.promo.name,
          discount: cart.promo.value,
        },
        cookies: {
          SOURCE: getCookie('SOURCE'),
          REFERRER: getCookie('REFERRER'),
          cl_uid: getCookie('cl_uid'),
        },
        fi: cart.personal.name,
        postcode: cart.personal.postcode,
        currencyCode: sales.currency,
        is_test: TEST_ORDER,
        is_offer_accepted: !!cart.personal.is_offer_accepted,
        is_marketing_accepted: !!cart.personal.is_marketing_accepted,
        is_research_accepted: !!cart.personal.is_research_accepted,
      },
      {
        cancelToken: source.token,
      },
    )
    .then(r => ({
      saveResponse: r.data,
    }))
    .catch(error => ({ saveError: error }))

  request[CANCEL] = () => source.cancel()

  return request
}

// yandex object, may contain token for bank card
// or payment_data and type property for apple pay
export const order = orderPayload => {
  const source = CancelToken.source()

  const request = api2
    .post(
      '/order/create',
      {
        order: orderPayload,
      },
      {
        cancelToken: source.token,
      },
    )
    .then(r => ({
      orderResponse: r.data,
    }))
    .catch(error => ({ orderError: error }))

  request[CANCEL] = () => source.cancel()

  return request
}

export const getUserIP = () => {
  return new Promise(res => {
    const timeout = setTimeout(() => {
      res({ ip: null })
    }, 15000)

    api2
      .get(`/ipdetect`)
      .then(response => {
        clearTimeout(timeout)
        res(response.data)
      })
      .catch(() => {})
  })
}

export const collectEuropeData = async ({ cart, sales }) => {
  const { personal, userGA } = cart
  const products = []
  const source = CancelToken.source()

  const fbp = getCookie('_fbp')
  const fbc = getCookie('_fbc')
  const client_user_agent = window.navigator.userAgent
  const { ip } = await getUserIP()

  forEach(cart.goods, (value, key) => {
    if (value.isSubscription && value.amount) {
      products.push({
        id: sales.goods[key].subscription.id,
        quantity: cart.goods[key].amount,
      })
    } else if (!value.isSubscription && value.amount) {
      products.push({
        id: sales.goods[key].id,
        quantity: cart.goods[key].amount,
      })
    }
  })

  /*const [firstName, lastName] = personal.name.split(' ')
  await gtmPushUserData({
    firstName: firstName,
    lastName: lastName,
    country: personal.country,
    city: personal.city,
    postCode: personal.postcode,
  })*/

  const request = api2
    .post(
      '/order/collect',
      {
        order: {
          products,
          contact_info: {
            name: personal.name,
            name2: personal.name2,
            email: personal.email,
            phone_code: personal.phone_code,
            phone: personal.phone,
            company: personal.company,
            country: personal.country,
          },
          delivery_info: {
            region: personal.region,
            city: personal.city,
            district: personal.district,
            address: personal.address,
            address2: personal.address2,
            address3: personal.address3,
            postcode: personal.postcode,
            delivery_type: cart.deliveryType,
          },
          promo: cart.promo.errors?.errorCode ? '' : cart.promo.name,
          charge_info: {
            currency: sales.currency,
          },
          cookies: {
            SOURCE: getCookie('SOURCE'),
            REFERRER: getCookie('REFERRER'),
            cl_uid: getCookie('cl_uid'),
          },
          ga_cid: userGA,
          fbc,
          fbp,
          client_user_agent,
          client_ip_address: ip,
          is_test: TEST_ORDER,
          check_cost: cart.sum.cost,
          unfinished_id: cart.orderIdUnfinished,
        },
      },
      {
        cancelToken: source.token,
      },
    )
    .then(r => ({
      orderResponse: r.data,
    }))
    .catch(error => ({ orderError: error }))

  request[CANCEL] = () => source.cancel()

  return request
}

export const checkOrderStatus = ({ id }) => {
  const source = CancelToken.source()

  const request = api2
    .get(`/order/check/${id}`, {
      cancelToken: source.token,
    })
    .then(r => ({
      orderStatus: r.data,
    }))
    .catch(error => error.response)

  request[CANCEL] = () => source.cancel()

  return request
}

export const getDeliveryPrice = ({ country, city }) => {
  const source = CancelToken.source()

  const request = api2
    .post(
      '/order/delivery-zone',
      {
        country,
        city,
      },
      {
        cancelToken: source.token,
      },
    )
    .then(r => r.data)
    .catch(error => error.response)

  request[CANCEL] = () => source.cancel()

  return request
}

export const getJapaneseAddress = ({ query, count = 20 }) => {
  const source = CancelToken.source()
  const request = api2
    .get(`/order/getJapaneseAddress?keyword=${query}&limit=${count}`, {
      cancelToken: source.token,
    })
    .then(r => r.data)
    .catch(error => ({ orderError: error }))

  request[CANCEL] = () => source.cancel()

  return request
}

export const getDadataAddress = ({ query, count = 20 }) => {
  const source = CancelToken.source()
  const request = api2
    .post(
      'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
      {
        query,
        count,
      },
      {
        cancelToken: source.token,
        headers: {
          Authorization: `Token ${DADATA_TOKEN}`,
        },
      },
    )
    .then(r => r.data)
    .catch(error => ({ orderError: error }))

  request[CANCEL] = () => source.cancel()

  return request
}

export const validateApplePayMerchant = ({ validationURL }) => {
  const source = CancelToken.source()
  const request = api2
    .post(
      '/applepay/validate',
      {
        validationURL,
      },
      {
        cancelToken: source.token,
      },
    )
    .then(r => r.data)
    .catch(error => ({ orderError: error }))

  request[CANCEL] = () => source.cancel()

  return request
}

/*
 * =========================
 * USER PROFILE
 * =========================
 */
export const getUserProfile = ({ token }) => {
  const source = CancelToken.source()
  const request = api2
    .get('/lk/profile', {
      cancelToken: source.token,
      headers: {
        'X-Access-Token': token,
      },
    })
    .then(r => ({
      response: {
        avatar: r.data.avatar ? r.data.avatar : '',
        gender: r.data.gender,
      },
    }))
    .catch(error => ({ error: error.response }))

  request[CANCEL] = () => source.cancel()

  return request
}

export const getUserInfo = () => {
  const source = CancelToken.source()
  const request = api2
    .get('/public/userId', {
      cancelToken: source.token,
    })
    .then(r => ({
      response: r.data,
    }))
    .catch(error => ({ error: error.response }))

  request[CANCEL] = () => source.cancel()

  return request
}

/*
 * =========================
 * Healthy eating subscription
 * =========================
 */
export const subscribeHealthyEating = ({ email, country }) => {
  const source = CancelToken.source()
  const request = api2
    .post(
      '/healthy/eating/subscribe',
      {
        email,
        country,
      },
      {
        cancelToken: source.token,
      },
    )
    .then(r => r.data)
    .catch(error => ({ error: error.response }))

  request[CANCEL] = () => source.cancel()

  return request
}

/*
 * =========================
 * EMAIL SUBSCRIPTION
 * =========================
 */
export const subscribeEmail = ({ email, list, tags }) => {
  const source = CancelToken.source()
  const request = api2
    .post(
      '/subscribe',
      {
        email,
        list,
        tags,
      },
      {
        cancelToken: source.token,
      },
    )
    .then(r => r.data)
    .catch(error => ({ error: error.response }))

  request[CANCEL] = () => source.cancel()

  return request
}

/*
 * =========================
 * Get trustpilot's overall review
 * =========================
 */
export const getTrustpilotOverallReview = () => {
  const source = CancelToken.source()
  const request = api2
    .get(
      'https://api.trustpilot.com/v1/business-units/find?apikey=xQchjuyaFQssFgwTSbqqzwQ352umw3kL&name=atlasbiomed.co.uk',
      {
        cancelToken: source.token,
      },
    )
    .then(r => r.data)
    .catch(error => Promise.reject({ error: error.response.data }))

  request[CANCEL] = () => source.cancel()

  return request
}

/*
 * =========================
 * Get trustpilot's reviews
 * =========================
 */
export const getTrustpilotReviews = () => {
  const source = CancelToken.source()
  const request = api2
    .get(
      'https://api.trustpilot.com/v1/business-units/5c6697b99e0d730001dac700/reviews?apikey=xQchjuyaFQssFgwTSbqqzwQ352umw3kL&tagValue=favorite',
      {
        cancelToken: source.token,
      },
    )
    .then(r => r.data.reviews)
    .catch(error => Promise.reject({ error: error.response }))

  request[CANCEL] = () => source.cancel()

  return request
}
