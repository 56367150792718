import PropTypes from 'prop-types'
import React from 'react'

import { ResizeContext } from 'contexts/Resize'
import { Row, Col } from 'styles/components/layout'
import { getTrustpilotReviews } from 'utils/api'

import TrustpilotHeader from './components/TrustpilotHeader'
import TrustpilotSlider3Items from './components/TrustpilotSlider3Items'
import TrustpilotSliderMobile from './components/TrustpilotSliderMobile'
import { BoxBaseStyled } from './styles'

const TrustpilotReviews = ({ review, ...restProps }) => {
  const [reviews, setReviews] = React.useState([])
  const { mobile } = React.useContext(ResizeContext)

  React.useEffect(() => {
    getTrustpilotReviews()
      .then(receivedReviews => {
        if (receivedReviews && receivedReviews.length > 0) {
          setReviews(receivedReviews);
        }
      })
      .catch(() => {})
  }, [])

  return reviews.length ? (
    <BoxBaseStyled {...restProps}>
      <Row>
        <Col width={{ xs: 1 }}>
          <TrustpilotHeader review={review} />
          {mobile ? (
            <TrustpilotSliderMobile list={reviews} />
          ) : (
            <TrustpilotSlider3Items list={reviews} />
          )}
        </Col>
      </Row>
    </BoxBaseStyled>
  ) : null
}

TrustpilotReviews.propTypes = {
  review: PropTypes.shape({
    score: PropTypes.shape({
      stars: PropTypes.number,
      trustScore: PropTypes.number,
    }),
    numberOfReviews: PropTypes.shape({
      total: PropTypes.number,
    }),
  }),
}

export default React.memo(TrustpilotReviews)
