import animatedScrollTo from 'animated-scroll-to'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { RU } from 'constants/countries'
import { COUNTRIES_WITHOUT_DNA, GOODS_TYPES } from 'constants/sales'
import AddAndGoToCart from 'containers/AddAndGoToCart'
import { ResizeContext } from 'contexts/Resize'
import { Row } from 'styles/components/layout'

import TraitsListHeadTab from '../TraitsListHeadTab'
import TraitsListMobile from '../TraitsListMobile'
import TraitsListOfKit from '../TraitsListOfKit'

import {
  TraitsListOuter,
  TraitsListTabs,
  TraitsListTabsInner,
  TraitsListContent,
  ColStyled,
} from './styles'

let ScrollMagic

class TraitsListPopupContent extends Component {
  constructor(props) {
    super(props)

    this.traitsRef = React.createRef()
    this.tabsRef = React.createRef()
    this.productsRefs = {
      [GOODS_TYPES.dna]: React.createRef(),
      [GOODS_TYPES.biome]: React.createRef(),
      [GOODS_TYPES.wgs]: React.createRef(),
    }
    this.prevMobile = null
    this.state = {
      type: COUNTRIES_WITHOUT_DNA.includes(props.locale)
        ? GOODS_TYPES.biome
        : GOODS_TYPES.dna,
      isScrolling: false,
    }

    this.listOfProducts = COUNTRIES_WITHOUT_DNA.includes(props.locale)
      ? [GOODS_TYPES.biome]
      : props.locale === RU
      ? [GOODS_TYPES.dna, GOODS_TYPES.biome, GOODS_TYPES.wgs]
      : [GOODS_TYPES.dna, GOODS_TYPES.biome]

    this.scrollController = null
    this.scenes = []
    this.listsOfKitRefs = this.listOfProducts.map(() => React.createRef())
    this.tabsHeight = 0

    this.debouncedResizeHandler = debounce(this.handleResize, 200)
  }

  componentDidMount() {
    const viewport = this.context
    if (!viewport.mobile) {
      this.calculateTabsHeight()
      this.initScrollScene()
    }
  }

  initScrollScene = async () => {
    if (!ScrollMagic)
      // eslint-disable-next-line
      ScrollMagic = (await import('ScrollMagic')).default

    this.scrollController = new ScrollMagic.Controller()

    this.listOfProducts.forEach((productType, i) => {
      if (this.listsOfKitRefs[i].current) {
        const { height } = this.listsOfKitRefs[
          i
        ].current.getBoundingClientRect()

        const scene = new ScrollMagic.Scene({
          triggerElement: this.listsOfKitRefs[i].current,
          duration: height,
          triggerHook: 0,
          offset: -this.tabsHeight,
        })
          .on('enter', () => this.handleEnter(productType))
          .addTo(this.scrollController)
        this.scenes.push(scene)
      }
    })

    window.addEventListener('resize', this.debouncedResizeHandler)
  }

  calculateTabsHeight = () => {
    if (this.tabsRef.current) {
      const tabsTop = window
        .getComputedStyle(this.tabsRef.current)
        .getPropertyValue('top')
      const tabsTopPx = parseInt(tabsTop)
      const {
        height: tabsHeight,
        top,
      } = this.tabsRef.current.getBoundingClientRect()
      this.tabsHeight = tabsHeight + tabsTopPx
      this.tabsOffset = -tabsHeight + top + tabsTopPx
    }
  }

  componentDidUpdate() {
    const { isOpen } = this.props
    const viewport = this.context

    if (
      isOpen &&
      !viewport.mobile &&
      viewport.mobile !== this.prevMobile &&
      !!this.productsRefs[GOODS_TYPES.dna].current
    ) {
      this.prevMobile = viewport.mobile
      this.changeTraits({ type: this.state.type })
    }

    if (this.tabsRef.current && !this.tabsHeight) {
      this.calculateTabsHeight()
    }

    if (viewport.mobile && this.scenes.length) {
      this.scenes.forEach(scene => scene.destroy())
      this.scenes = []
    }

    if (
      isOpen &&
      !viewport.mobile &&
      !!this.listsOfKitRefs[0].current &&
      !this.scenes.length
    )
      this.initScrollScene()
  }

  componentWillUnmount() {
    if (this.scrollController) this.scrollController.destroy()

    if (this.scenes.length) {
      this.scenes.forEach(scene => scene.destroy())
      this.scenes = []
    }

    window.removeEventListener('resize', this.debouncedResizeHandler)
  }

  changeTraits = ({ type }) => {
    const { modalRef } = this.props
    const viewport = this.context
    const { current: goodsType } = this.productsRefs[type]

    if (viewport.mobile) {
      modalRef.current.scrollTo(0, 0)
    } else {
      this.setState({ isScrolling: true }, () => {
        animatedScrollTo(goodsType, {
          verticalOffset: this.tabsOffset,
          elementToScroll: modalRef.current,
        }).then(() => {
          this.setState({ isScrolling: false })
        })
      })
    }

    return this.setState({ type })
  }

  changeActiveTabByScrolling = ({ type }) => {
    if (this.state.type !== type) this.setState({ type })
  }

  handleResize = () => {
    this.calculateTabsHeight()

    this.scenes.forEach((scene, i) => {
      if (this.listsOfKitRefs[i].current) {
        const { height } = this.listsOfKitRefs[
          i
        ].current.getBoundingClientRect()

        scene.duration(height)
        scene.offset(-this.tabsHeight)
        scene.refresh()
      }
    })
  }

  handleEnter = productType => {
    const { isScrolling } = this.state
    if (!isScrolling) this.changeActiveTabByScrolling({ type: productType })
  }

  getProductProps = productType => {
    const { texts, modalRef } = this.props

    return {
      modalRef: modalRef,
      titleRef: this.productsRefs[productType],
      data: texts[productType],
    }
  }

  cartButton = (productType, buttonProps) => (
    <AddAndGoToCart
      goods={[
        {
          type: productType,
          isSubscription: false,
        },
      ]}
      buttonProps={{
        textClassName: 'text text-white',
        withTopMarginOnMobile: true,
        ...buttonProps,
      }}
      forceMultipleAdd={true}
    />
  )

  render() {
    const { modalRef, texts, locale, paths } = this.props
    const { type } = this.state
    const viewport = this.context

    const commonTabProps = {
      viewport,
      locale,
      boxesPath: `${paths.cdn_global}/boxes/`,
      handleClick: this.changeTraits,
    }

    if (this.listOfProducts.length === 1) {
      commonTabProps.isActive = false
      commonTabProps.noHover = true
    }

    return (
      <TraitsListOuter ref={this.traitsRef}>
        <TraitsListTabs ref={this.tabsRef}>
          <TraitsListTabsInner>
            {this.listOfProducts.map(productType => (
              <TraitsListHeadTab
                key={productType}
                isActive={type === productType}
                title={texts[productType].title}
                type={productType}
                cartButton={this.cartButton(productType, {
                  bgClassName: `bg--border-gray bg--black`,
                  size: 'slim',
                })}
                {...commonTabProps}
              />
            ))}
          </TraitsListTabsInner>
        </TraitsListTabs>
        <TraitsListContent>
          {viewport.mobile ? (
            <TraitsListMobile
              modalRef={modalRef}
              data={texts}
              type={type}
              cartButton={this.cartButton(type, {
                bgClassName: 'bg--purple',
              })}
            />
          ) : (
            <Row>
              <ColStyled width={{ xs: 1 }}>
                {this.listOfProducts.map((productType, i) => (
                  <TraitsListOfKit
                    ref={this.listsOfKitRefs[i]}
                    key={productType}
                    {...this.getProductProps(productType)}
                  />
                ))}
              </ColStyled>
            </Row>
          )}
        </TraitsListContent>
      </TraitsListOuter>
    )
  }
}

TraitsListPopupContent.contextType = ResizeContext

TraitsListPopupContent.propTypes = {
  texts: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  modalRef: PropTypes.object.isRequired,
  paths: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
}

export default TraitsListPopupContent
