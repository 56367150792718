import { em } from 'polished'
import styled from 'styled-components'

import { RowIndented, BoxBase } from 'styles/components/layout'
import { Text } from 'shared/Typography'

export const RowIndentedStyled = styled(RowIndented)`
  position: relative;
  overflow: hidden;
  border-radius: ${p => p.theme.blocks.blockBorderRadiusMedium};
  padding: ${em(38)} ${em(20)} 0;
  background-color: ${p => p.theme.colors.grayishBlue};
  min-height: ${em(510)};

  & > div {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  ${p => p.theme.mq.upMd} {
    padding: ${em(30)} ${em(20)} ${em(30)} ${em(40)};
    min-height:${em(272)};
  }
`

export const BoxBaseStyled = styled(BoxBase)`
  padding-top: ${p => em(p.theme.indents.baseIndentXs)};

  ${p => p.theme.mq.upMd} {
    padding-top: ${p => em(p.theme.indents.baseIndentMd)};
  }
`

export const Title = styled(Text)`
  font-family: ${({ theme }) => theme.typography.fontDruk};
  max-width: 100%;
  margin-top: ${p => p.isSucceed ? '62px' : '0'};
  margin-bottom: 10px;
  text-align: center;
  letter-spacing: 0.03em;

  ${p => p.theme.mq.upMd} {
    margin-top: 0;
    max-width: ${em(300)};
    text-align: left;
  }
`

export const Description = styled(Text)`
  margin-bottom: 20px;
  color: ${p => p.theme.colors.white};
  text-align: center;

  ${p => p.theme.mq.upMd} {
    max-width: ${em(350)};
    margin-bottom: ${em(30)};
    text-align: left;
  }
`

export const Disclaimer = styled(Text)`
  color: ${p => p.theme.colors.blackOp3};
  text-align: center;

  a {
    text-decoration: underline;
  }

  ${p => p.theme.mq.upMd} {
    text-align: left;
    max-width: ${em(550)};
  }
`

export const ButtonSendAgain = styled.button`
  text-align: center;
  cursor: pointer;
  margin-right: auto;
  margin-left: auto;
  margin-top: 10px;

  ${p => p.theme.mq.upMd} {
    margin: auto 0 0;
    text-align: left;
  }
`

export const CloseBox = styled.div`
  position: absolute;
  top: ${em(15)};
  right: ${em(15)};
  width: ${em(26)};
  height: ${em(26)};

  & > button {
    max-width: 100%;
    max-height: 100%;
  }

  ${p => p.theme.mq.upMd} {
    top: ${em(20)};
    right: ${em(20)};
  }
`
export const ImageDesktop = styled.img`
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;

  ${p => p.theme.mq.downMd} {
    display: none;
  }

  ${p => p.theme.mq.upThreshold(768)} {
    max-width: ${em(200)};
  }

  ${p => p.theme.mq.upThreshold(1024)} {
    max-width: ${em(400)};
  }

  ${p => p.theme.mq.upThreshold(1200)} {
    max-width: ${em(458)};
  }
`

export const ImageMob = styled.img`
  display: none;

  ${p => p.theme.mq.downMd} {
    display: block;
    position: absolute;
    bottom: 0;
    max-width: 100%;
  }
`
