import { em } from 'polished'
import styled from 'styled-components'

export const Logo = styled.div`
  width: 100%;
  max-width: ${em(300)};
  height: ${em(120)};
  background: ${p =>
    p.inView ? `url(${p.image}) center/contain no-repeat` : 'none'};
  transition: ${p => p.theme.animation.logoLinkTransition};
  margin: 0 auto;
`

export const Content = styled.article`
  opacity: 1;
  transition: ${p => p.theme.animation.linkTransition};
  max-width: ${em(400)};
  margin: 0 auto;

  .publication__arrow {
    display: none;
  }

  ${p => p.theme.mq.upMd} {
    max-width: none;
    margin: 0;

    .publication__arrow {
      display: inline-block;
    }
  }
`

export const ButtonBox = styled.div`
  display: block;

  ${p => p.theme.mq.upMd} {
    display: none;
  }
`

export const Wrapper = styled.div`
  display: block;
  text-align: center;
  color: ${p => p.theme.colors.white};
  min-height: ${em(450)};

  ${p => p.theme.mq.upMd} {
    min-height: auto;

    ${p => p.theme.mq.hover} {
      &:hover {
        .publication__arrow {
          transform: translate(
            ${p => p.theme.arrows.arrowShortOnHoverTransformMd},
            0
          );
        }
      }
    }
  }

  ${p => p.theme.mq.hover} {
    &:hover {
      ${Content} {
        opacity: ${p => p.theme.typography.linkHoveredOp};
      }
    }
  }
`
