import { useEffect, useRef } from 'react'
import Typed from 'typed.js'

export default function useTyped(strings, container) {
  const typed = useRef(null)

  useEffect(() => {
    if (container.current) {
      typed.current = new Typed(container.current, {
        strings,
        typeSpeed: 40,
        backSpeed: 40,
        startDelay: 700,
        backDelay: 2000,
        loop: true,
      })

      return () => typed.current.destroy()
    }
    return () => {}
  }, [strings, container])
}
