import PropTypes from 'prop-types'
import React from 'react'

import { Container, Title, DateAndTime, Image, ImageContainer, ImageText, Views } from './styles'
import { imageMap } from './assets'

const BlogCard = ({ title, section, time, views, date, link }) => {
  const dateAndTime = date + '<span class="h-color-D10">•</span>' + time

  return (
    <Container href={link} target='_blank'>
      <ImageContainer>
        <Image src={imageMap[section]} />

        <ImageText size={['12/14', '12/14', '12/14']} transform={'uppercase'}>
          {section}
        </ImageText>
      </ImageContainer>

      <Title
        forwardedAs={'h2'} size={['18/24', '18/24', '22/30']}
        weight={'medium'}
        dangerouslySetInnerHTML={{ __html: title }}
      />

      <DateAndTime
        size={['14/22', '14/22', '16/20']}
        dangerouslySetInnerHTML={{ __html: dateAndTime }}
      />

      <Views size={['14/20', '14/20', '14/20']}>
        <img src={imageMap['iconViews']} alt='icon-views' />
        {views}
      </Views>
    </Container>
  )
}

BlogCard.propTypes = {
  title: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  views: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  link: PropTypes.string,
}

export default BlogCard
