import { H1, H2, TextLH, TextMedium } from 'styles/components/typography'

export default function(code) {
  switch (code) {
    case 'xl':
      return H1
    case 'lg':
      return H2
    case 'cart':
      return TextMedium
    case 'base':
    default:
      return TextLH
  }
}
