import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Transition } from 'react-transition-group'

import ButtonClose from 'components/ButtonClose'
import Overlay from 'components/Overlay'

import {
  ModalInner,
  ModalOutter,
  ModalInnerWrapper,
  BtnCloseWrapper,
  BtnCloseWrapperInside,
  BtnCloseInner,
} from './styles'

const SELECTOR = 'modal-root'
const ANIMATION_ENTER = 300
const ANIMATION_EXIT = 200

/**
 * Modal component that render modal window and it's content in the portal
 *
 * @returns { Modal } Modal — Component of Modal
 */
class Modal extends Component {
  static propTypes = {
    /** Content of the Modal */
    children: PropTypes.node.isRequired,

    /** Flag to control Modal state */
    modalOpen: PropTypes.bool.isRequired,

    /** Flag to turn on rounded corners */
    isRounded: PropTypes.bool,

    /** Close modal function */
    handleCloseModal: PropTypes.func,

    /** Flag to turn on mobile rendering options */
    isMobile: PropTypes.bool,

    /** Reference to modal container */
    modalRef: PropTypes.object,

    position: PropTypes.string,

    closeOnOverlayClick: PropTypes.bool,
  }

  static defaultProps = {
    isMobile: false,
    isRounded: false,
    position: 'centered',
  }

  constructor(props) {
    super(props)

    this.timer = null
    this.modalRef = React.createRef()

    this.state = {
      rootNode: null,
    }
  }

  componentDidMount() {
    this.setState({
      rootNode: document.getElementById(SELECTOR),
    })
  }

  componentDidUpdate() {
    const { modalOpen } = this.props

    if (modalOpen) {
      this.modalWillOpen()
    } else {
      this.modalWillClose()
    }
  }

  componentWillUnmount() {
    this.modalWillClose()
  }

  modalWillOpen = () => {
    const reactRoot = document.getElementById('__next')
    const { modalRef } = this.props
    const safeRef = modalRef || this.modalRef

    window.addEventListener('keydown', this.keyboardCloseModal)
    disableBodyScroll(safeRef.current)
    if (reactRoot) {
      reactRoot.style.overflow = 'hidden'
    }
  }

  modalWillClose = () => {
    const reactRoot = document.getElementById('__next')

    clearAllBodyScrollLocks()
    window.removeEventListener('keydown', this.keyboardCloseModal)

    if (this.timer) {
      clearTimeout(this.timer)
    }

    if (reactRoot) {
      reactRoot.removeAttribute('style')
    }
  }

  /**
   * Function that close Modal on Esc key up event
   *
   * @param {e} e - event
   * @return {void} void
   */
  keyboardCloseModal = e => {
    const { modalRef, modalOpen } = this.props
    const safeRef = modalRef || this.modalRef

    if (modalOpen && e.keyCode === 27) {
      this.props.handleCloseModal()
    }
    safeRef.current.focus()
  }

  renderModal = () => {
    const {
      isRounded,
      children,
      isMobile,
      modalOpen,
      modalRef,
      position,
      closeOnOverlayClick,
    } = this.props
    const safeRef = modalRef || this.modalRef
    const closeBtnColor = isMobile ? 'black' : 'white'

    return (
      <Transition
        unmountOnExit={true}
        appear={true}
        in={modalOpen}
        timeout={{ enter: ANIMATION_ENTER, exit: ANIMATION_EXIT }}
      >
        {state => (
          <ModalOutter ref={safeRef} transitionState={state} tabIndex="-1">
            {position === 'centered' && (
              <BtnCloseWrapper>
                <BtnCloseInner>
                  <ButtonClose
                    transitionState={state}
                    handleClick={this.props.handleCloseModal}
                    color={closeBtnColor}
                  />
                </BtnCloseInner>
              </BtnCloseWrapper>
            )}
            <ModalInnerWrapper position={position}>
              <Overlay
                isVisible={true}
                onClick={
                  closeOnOverlayClick ? this.props.handleCloseModal : null
                }
              />
              <ModalInner isRounded={isRounded} position={position}>
                {children}
              </ModalInner>
              {position !== 'centered' && (
                <BtnCloseWrapperInside position={position}>
                  <BtnCloseInner>
                    <ButtonClose
                      transitionState={state}
                      handleClick={this.props.handleCloseModal}
                      color={closeBtnColor}
                    />
                  </BtnCloseInner>
                </BtnCloseWrapperInside>
              )}
            </ModalInnerWrapper>
          </ModalOutter>
        )}
      </Transition>
    )
  }

  render() {
    const { rootNode } = this.state

    if (!rootNode) {
      return null
    }

    return ReactDOM.createPortal(this.renderModal(), rootNode)
  }
}

export default Modal
