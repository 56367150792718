import PropTypes from 'prop-types'
import React from 'react'

import useInViewCommon from 'hooks/useInViewCommon'
import useSwiper from 'hooks/useSwiper'
import useSwiperLoader from 'hooks/useSwiperLoader'

import TopNavigation from './components/TopNavigation'
import { ControlSliderArrowsStyled } from './styles'

/**
 * SliderMobile component — this slider was created with main purpose to use in on the mobile devices.
 * ATTENTION: this component 'eats' side indents of sliding content to use whole width of viewport space
 *
 * Just put bunch of slides wrapped with `<div>` blocks inside the component and customize (if you need)
 * wrapper behaviour with custom class name
 *
 * @return {Component} Component - SliderMobile
 */

const SliderMobile = ({
  children,
  swiperArrowsClassName,
  className,
  swiperArrowsPosition,
  isWhite,
  descriptions,
  autoHeight,
  topNavigation,
}) => {
  const { activeIndex, onGoLeft, onGoRight, updateSwiper } = useSwiper()
  const [ref, inView] = useInViewCommon()
  const { Swiper, swiperLoaded } = useSwiperLoader({ inView })
  const [sliderConfig] = React.useState(() => ({
    slidesPerView: 1,
    preloadImages: false,
  }))

  const filteredChildren = React.useMemo(() => children.filter(ch => !!ch), [
    children,
  ])
  return (
    <>
      {swiperArrowsPosition === 'top' && (
        <ControlSliderArrowsStyled
          descriptions={descriptions}
          isLightColor={isWhite}
          isWide={true}
          amount={filteredChildren.length}
          activeIndex={activeIndex}
          className={`mt-1 ${swiperArrowsClassName}`}
          handleLeft={onGoLeft}
          handleRight={onGoRight}
        />
      )}
      <div ref={ref} {...{ className }}>
        {!!topNavigation && (
          <TopNavigation
            isWhite={isWhite}
            activeIndex={activeIndex}
            data={topNavigation}
          />
        )}
        {swiperLoaded ? (
          <Swiper
            {...sliderConfig}
            {...{ autoHeight, getSwiper: updateSwiper }}
          >
            {filteredChildren}
          </Swiper>
        ) : (
          filteredChildren[0]
        )}
      </div>
      {swiperArrowsPosition === 'bottom' && swiperLoaded && (
        <ControlSliderArrowsStyled
          descriptions={descriptions}
          isLightColor={isWhite}
          isWide={true}
          amount={filteredChildren.length}
          activeIndex={activeIndex}
          className={`mt-1 ${swiperArrowsClassName}`}
          handleLeft={onGoLeft}
          handleRight={onGoRight}
        />
      )}
    </>
  )
}

SliderMobile.propTypes = {
  /** Array of slides where each slide wrapped with `div` */
  children: PropTypes.array,

  topNavigation: PropTypes.arrayOf(PropTypes.string),

  /** Array of descriptions to show it instead of number counter */
  descriptions: PropTypes.array,

  /** Is white style */
  isWhite: PropTypes.bool,

  /** Class name for swiper arrows (you can control arrow behaviour from parent component.
   * For example: for outer hover control) */
  swiperArrowsClassName: PropTypes.string,

  /** Swiper controls position */
  swiperArrowsPosition: PropTypes.oneOf(['top', 'bottom']),

  /** Swiper container class name */
  className: PropTypes.string,

  /** Auto height slides */
  autoHeight: PropTypes.bool,
}

SliderMobile.defaultProps = {
  descriptions: [],
  isWhite: false,
  swiperArrowsClassName: '',
  className: '',
  swiperArrowsPosition: 'bottom',
  autoHeight: true,
}

export default SliderMobile
