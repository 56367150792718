import axios from 'axios'
import { once, compact } from 'lodash'

import { GOODS_TYPES } from 'constants/sales'
import { getCookie } from 'utils/client/cookie'
//import encodeToSHA256 from '../encodeToSHA256';

const server = process.env.server

const BRAND = 'AtlasBiomed'
const CATEGORY = 'Test'
const EV_PAGE_VIEW = 'pageView'
const EV_ADD_TO_CART = 'addToCart'
const EV_REMOVE_FROM_CART = 'removeFromCart'
const EV_CHECKOUT = 'checkout'
const EV_PURCHASE = 'purchase'
const EV_USER_INIT = 'userInit'
const EV_FIRST_INTERACTION = 'start_filling_cart_form'

const ZAPIER_HOOK_URL = 'https://hooks.zapier.com/hooks/catch/1348859/o1zjdvu/'

const getDnaInfo = ({ amount, cart, salesConfig }) => {
  const type = GOODS_TYPES.dna
  const productConfig = salesConfig.goods[type]
  const productCart = cart.goods[type]
  const safeAmount = amount ? amount : productCart.amount

  if (safeAmount) {
    return {
      name: productConfig.name,
      id: productConfig.id,
      price: productConfig.price,
      brand: BRAND,
      category: CATEGORY,
      variant: null,
      quantity: safeAmount,
      coupon: null,
      list: window.location.pathname,
    }
  }

  return null
}

const getBiomeInfo = ({ amount, cart, salesConfig }) => {
  const type = GOODS_TYPES.biome
  const productConfig = salesConfig.goods[type]
  const productCart = cart.goods[type]
  const quantity = productCart.isSubscription ? 0 : productCart.amount
  const safeAmount = amount ? amount : quantity

  if (safeAmount) {
    return {
      name: productConfig.name,
      id: productConfig.id,
      price: productConfig.price,
      brand: BRAND,
      category: CATEGORY,
      variant: null,
      quantity: safeAmount,
      coupon: null,
      list: window.location.pathname,
    }
  }

  return null
}

const getBiomeSubscrInfo = ({ amount, cart, salesConfig }) => {
  const type = GOODS_TYPES.biome
  const productConfig = salesConfig.goods[type]
  const productCart = cart.goods[type]
  const quantity = productCart.isSubscription ? productCart.amount : 0
  const safeAmount = amount ? amount : quantity

  if (safeAmount) {
    return {
      name: productConfig.name,
      id: productConfig.id,
      price: productConfig.price,
      brand: BRAND,
      category: CATEGORY,
      variant: null,
      quantity: safeAmount,
      coupon: null,
      list: window.location.pathname,
    }
  }

  return null
}

const getWgsInfo = ({ amount, cart, salesConfig }) => {
  const type = GOODS_TYPES.wgs
  const productConfig = salesConfig.goods[type]
  const productCart = cart.goods[type]
  const quantity = productCart.isSubscription ? 0 : productCart.amount
  const safeAmount = amount ? amount : quantity

  if (safeAmount) {
    return {
      name: productConfig.name,
      id: productConfig.id,
      price: productConfig.price,
      brand: BRAND,
      category: CATEGORY,
      variant: null,
      quantity: safeAmount,
      coupon: null,
      list: window.location.pathname,
    }
  }

  return null
}

const getAllExistedProducts = ({ cart, salesConfig }) => {
  const products = [
    getDnaInfo({ cart, salesConfig }),
    getBiomeInfo({ cart, salesConfig }),
    getBiomeSubscrInfo({ cart, salesConfig }),
    getWgsInfo({ cart, salesConfig }),
  ]

  return compact(products)
}

const getProductInfoByType = ({
  amount,
  productType,
  isSubscription,
  cart,
  salesConfig,
}) => {
  switch (productType) {
    case GOODS_TYPES.dna:
      return getDnaInfo({ amount, cart, salesConfig })
    case GOODS_TYPES.wgs:
      return getWgsInfo({ amount, cart, salesConfig })
    case GOODS_TYPES.biome:
      if (isSubscription) {
        return getBiomeSubscrInfo({ amount, cart, salesConfig })
      }

      return getBiomeInfo({ amount, cart, salesConfig })
    default:
      return null
  }
}

export const gtmTitleChanged = (url, title) => {
  if (process.browser && window.dataLayer) {
    window.dataLayer.push({
      event: EV_PAGE_VIEW,
      page_data: {
        url: url,
        title: title,
      },
    })
  }
}

export const gtmUserInfoPush = userData => {
  if (process.browser && window.dataLayer) {
    const user = userData.id ? { ...userData } : null

    if (user) {
      delete user.isFetching
      delete user.error

      for (const key in user) {
        if (Array.isArray(user[key])) {
          user[key] = { ...[...user[key]] }
        }
      }
    }

    window.dataLayer.push({
      event: EV_USER_INIT,
      user_data: user,
    })
  }
}

const gtmCheckout = ({ step }) => ({ cart, salesConfig }) => {
  if (process.browser) {
    const payload = {
      event: EV_CHECKOUT,
      currencyCode: salesConfig.currency,
      ecommerce: {
        checkout: {
          actionField: {
            step,
          },
          products: getAllExistedProducts({ cart, salesConfig }),
        },
      },
    }

    if (window.dataLayer) {
      window.dataLayer.push(payload)
    }
  }

  return null
}

export const gtmCheckoutUserInfo = once(gtmCheckout({ step: 1 }))

export const gtmCheckoutCardInfo = once(gtmCheckout({ step: 2 }))

export const gtmPurchaseSucceed = ({ cart, salesConfig, orderId }) => {
  if (process.browser) {
    const payload = {
      event: EV_PURCHASE,
      currencyCode: salesConfig.currency,
      ecommerce: {
        purchase: {
          actionField: {
            id: orderId,
            affiliation: location.host,
            revenue: cart.sum.cost,
            tax: 0,
            shipping: cart.sum.delivery,
            coupon: cart.promo.name,
          },
          products: getAllExistedProducts({ cart, salesConfig }),
        },
      },
    }

    window.dataLayer?.push(payload)
  }

  return null
}

/**
 * Encode and push user data to gtm
 *
 * @param {{
 *   firstName: string,
 *   lastName: string,
 *   country: string,
 *   city: string,
 *   postCode: string,
 * }} payload
 *
 * @return Promise<void>
 */
/*export async function gtmPushUserData(payload) { // eslint-disable-line no-unused-vars
  if (process.browser && location.protocol === 'https:') {
    try {
      const [
        firstName,
        lastName,
        country,
        city,
        postCode,
      ] = await Promise.all(
        encodeToSHA256(payload.firstName),
        encodeToSHA256(payload.lastName),
        encodeToSHA256(payload.country),
        encodeToSHA256(payload.city),
        encodeToSHA256(payload.postCode),
      );
      const payload = {
        event: 'user_data',
        firstName,
        lastName,
        country,
        city,
        postCode,
      }

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(payload)
    } catch (e) {
      return null;
    }
  }
}*/

export const gtmEvent = ({ event, description = '' }) => {
  if (process.browser) {
    const payload = {
      event: 'data_to_gtm',
      data_event: event,
      data_description: description,
    }

    if (window.dataLayer) {
      window.dataLayer.push(payload)
    }
  }

  return null
}

export const gtmFirstInteraction = () => {
  if (process.browser) {
    const payload = {
      event: EV_FIRST_INTERACTION,
    }
    window.dataLayer?.push(payload)
  }

  return null
}

export const gtmAddToCart = ({
  amount,
  cart,
  salesConfig,
  productType,
  isSubscription,
}) => {
  if (process.browser) {
    const products = getProductInfoByType({
      amount,
      cart,
      salesConfig,
      productType,
      isSubscription,
    })

    if (products === null) {
      return null
    }

    const payload = {
      event: EV_ADD_TO_CART,
      ecommerce: {
        currencyCode: salesConfig.currency,
        add: {
          products: [products],
        },
      },
    }

    if (window.dataLayer) {
      window.dataLayer.push(payload)
    }
  }

  return null
}

export const gtmRemoveFromCart = ({
  amount,
  cart,
  salesConfig,
  productType,
  isSubscription,
}) => {
  if (process.browser) {
    const products = getProductInfoByType({
      amount,
      cart,
      salesConfig,
      productType,
      isSubscription,
    })

    if (products === null) {
      return null
    }

    const payload = {
      event: EV_REMOVE_FROM_CART,
      ecommerce: {
        currencyCode: salesConfig.currency,
        remove: {
          products: [products],
        },
      },
    }

    if (window.dataLayer) {
      window.dataLayer.push(payload)
    }
  }

  return null
}

export const sendAlert = ({ statusCode, path }) => {
  const id = getCookie('_ga')

  try {
    axios(ZAPIER_HOOK_URL, {
      params: {
        user_ga: id,
        host: process.browser ? window.location.origin : server.WEBSITE_URL,
        path,
        status_code: statusCode,
        message: 'Something goes wrong on the cart page',
      },
    })
  } catch (e) {} // eslint-disable-line no-empty
}
