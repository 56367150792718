import PropTypes from 'prop-types'
import React from 'react'
import Link from 'components/Link'

import FeaturesPlate from 'components/_special/FeaturesPlate'
import { Col } from 'styles/components/layout'
import { Button } from 'shared/Button'

import { BoxBaseStyled, ButtonBox, RowIndentedStyled, Title } from './styles'

/*
 * We are going to use this component on Home page and on About Microbiome page.
 */
const WillLearn = ({ data, mobile, tablet, link }) => {
  const tabletText = React.useMemo(() => data.title.replace('<br/>', ' '), [
    data.title,
  ])

  return (
    <BoxBaseStyled>
      <RowIndentedStyled indent={{ xs: 0, md: 0 }}>
        <Col width={{ xs: 1 }}>
          <Title
            weight={'medium'}
            size={['50/60', '50/60', '50/60']}
            dangerouslySetInnerHTML={{
              __html: tablet ? tabletText : data.title,
            }}
          />
          <FeaturesPlate data={data} mobile={mobile} />
        </Col>
      </RowIndentedStyled>
      {link ? (
        <ButtonBox>
          <Link to={link}>
            <Button>
              {data.button}
            </Button>
          </Link>
        </ButtonBox>
      ) : null}
    </BoxBaseStyled>
  )
}

WillLearn.propTypes = {
  data: PropTypes.object.isRequired,
  mobile: PropTypes.bool.isRequired,
  tablet: PropTypes.bool.isRequired,
  link: PropTypes.string,
}

WillLearn.defaultProps = {
  link: '',
}

export default WillLearn
