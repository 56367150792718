import PropTypes from 'prop-types'
import React from 'react'

import Cost from 'components/Cost'
import { RU } from 'constants/countries'
import { GOODS_TYPES } from 'constants/sales'
import CostContainer from 'containers/CostContainer'

import {
  DynamicImageStyled,
  TextLHXsStyled,
  TraitsListHeadTabInner,
  TraitsListHeadTabWrapper,
  FirstRow,
  SecondRow,
  ImageBox,
} from './styles'

const TraitsListHeadTab = ({
  type,
  isActive,
  title,
  handleClick,
  boxesPath,
  viewport,
  noHover,
  locale,
  cartButton,
}) => {
  const isMobile = viewport.mobile && viewport.breakpoint === 'mobile'

  const dynamicImage = React.useMemo(
    () => (
      <DynamicImageStyled
        image={`${boxesPath}${type}-${locale === RU ? 'left-new' : 'cart-new'}`}
        imageSizes={{ xs: 100, md: 150, lg: 450 }}
        locale={locale}
        imageType="png"
        fitOption="none"
        alt="atlas kits"
      />
    ),
    [locale, type, boxesPath],
  )

  const notMobile = React.useMemo(
    () => (
      <SecondRow>
        {cartButton}
        <ImageBox>{dynamicImage}</ImageBox>
      </SecondRow>
    ),
    [cartButton, dynamicImage],
  )

  return (
    <TraitsListHeadTabWrapper
      onClick={() => handleClick({ type })}
      isActive={isActive}
      noHover={noHover}
    >
      <TraitsListHeadTabInner>
        <FirstRow>
          {isMobile && dynamicImage}
          <TextLHXsStyled
            locale={locale}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          {!isMobile && (
            <CostContainer productType={type}>
              {costProps => (
                <Cost
                  {...{
                    ...costProps,
                    size: 'baseBold',
                    value: costProps.cost,
                  }}
                />
              )}
            </CostContainer>
          )}
        </FirstRow>

        {!isMobile && notMobile}
      </TraitsListHeadTabInner>
    </TraitsListHeadTabWrapper>
  )
}

TraitsListHeadTab.propTypes = {
  handleClick: PropTypes.func.isRequired,
  boxesPath: PropTypes.string,
  isActive: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  type: PropTypes.oneOf([GOODS_TYPES.dna, GOODS_TYPES.biome, GOODS_TYPES.wgs])
    .isRequired,
  title: PropTypes.string,
  viewport: PropTypes.object,
  noHover: PropTypes.bool,
  cartButton: PropTypes.element,
}

TraitsListHeadTab.defaultProps = {
  boxesPath: '',
  isActive: false,
  title: '',
  viewport: null,
}

export default React.memo(TraitsListHeadTab)
