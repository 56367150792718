import styled from 'styled-components'
import { em } from 'polished'

import { BoxBase } from 'styles/components/layout'
import { Text } from 'shared/Typography'

export const Content = styled(BoxBase)`
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: ${em(40)};
  padding-bottom: ${em(135)};

  ${p => p.theme.mq.upMd} {
    padding-top: ${em(60)};
    padding-bottom: ${em(178)};
  }

  ${p => p.theme.mq.upThreshold(1024)} {
    flex-direction: column;
    padding-top: ${em(70)};
    padding-bottom: ${em(130)};
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-bottom: ${em(20)};
  gap:  ${em(10)};;
`

export const Description = styled(Text)`
  max-width: 100%;

  a {
    color: ${({ linkColor }) => linkColor};
  }

  ${p => p.theme.mq.upThreshold(1024)} {
    max-width: ${em(405)};
  }
}
`

export const Price = styled.div`
  margin-bottom: ${em(20)};
`

export const OldPrice = styled.div`
  display: flex;
  align-items: center;
  gap: ${em(10)};
`
export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${em(10)};

  ${p => p.theme.mq.upMd} {
    flex-direction: row;
  }
`

export const DiscountBadge = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${em(25.5)};
  padding: 0 ${em(5)};
  background-color: ${({ theme, bg }) => theme.colors[bg] || theme.colors.pink_svd};
  border-radius: ${em(4)};
  color: ${({ theme, color }) => theme.colors[color] || 'currentColor'};
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  min-width: ${em(104)};

  ${p => p.theme.mq.upThreshold(1024)} {
    min-width: ${em(197)};
  }

  ${p => p.theme.mq.upMd} {
    min-width: ${em(230)};
  }

  ${p => p.theme.mq.upLg} {
    align-self: auto;
  }
`
