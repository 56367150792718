import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

import { DK, IT, JP } from 'constants/countries'
import { Button } from 'shared/Button'
import { Text } from 'shared/Typography'
import AddAndGoToCart from 'containers/AddAndGoToCart'

import TypedText from '../../components/TypedText'
import { Content, Header, Footer, OldPrice, PriceAndButton } from './styles'

const DefaultLayout = ({
  words,
  price,
  currentPrice,
  buttonText,
  actionType,
  currentTestName,
  isDefaultWgs,
  currentGoodsItem,
  locale,
  currentSlide,
  defaultContent,
  mobile,
  tablet,
  isZeroPrice,
}) => {
  const isBlackColor = currentSlide?.styles.color === 'black'

  return (
    <Content>
      <Header>
        <TypedText
          words={words}
          markColor={currentSlide?.styles.markColor}
          basePhrase={(
            mobile || tablet) ? defaultContent.basePhrase : defaultContent.basePhraseLg + ' '}
          locale={locale}
          currentSlide={currentSlide}
          defaultContent={defaultContent}
          mobile={mobile}
          tablet={tablet}
        />
      </Header>

      <Footer buttonBg={currentSlide?.styles?.buttonBg}>
        {actionType === 'purchase' && currentGoodsItem && (
          <>
            {currentTestName && !mobile && (
              <Text
                className={classNames({
                  'h-color-D100': isDefaultWgs,
                })}
                weight='medium'
                size='18/28'
              >
                {currentTestName}
              </Text>
            )}

            {currentGoodsItem.price !== currentGoodsItem.currentPrice && !isZeroPrice && (
              <OldPrice>
                <Text
                  className={classNames({
                    'h-color-D50': isBlackColor || isDefaultWgs, 'h-color-W50': !isBlackColor && !isDefaultWgs,
                  })}
                  as='h5'
                  weight='medium'
                  size={mobile ? '18/24' : '22/30'}
                  decoration='line-through'
                >
                  {price}
                </Text>
              </OldPrice>
            )}

            <PriceAndButton layout={currentSlide?.layout}>
              {![JP, IT].includes(locale) && !isZeroPrice && (
                <Text
                  className={classNames({
                    'h-color-D100': isDefaultWgs,
                  })}
                  size={mobile ? '25/30' : '50/60'}
                  weight='medium'
                  layout={currentSlide?.layout}
                  withMargin={locale === DK}
                >
                  {currentPrice}
                </Text>
              )}

              <AddAndGoToCart
                key={currentSlide?.goodsType}
                goods={[{
                  type: currentSlide?.goodsType === 'microbiome' ? 'biome' : currentSlide?.goodsType,
                  isSubscription: false,
                }]}
                buttonProps={{
                  color: currentSlide?.styles.buttonBg, size: mobile ? 'mobile' : 'desktop',
                  disabled: isZeroPrice,
                }}
                ButtonComponent={Button}
                forceMultipleAdd
                isZeroPrice={isZeroPrice}
              >
                {buttonText}
              </AddAndGoToCart>
            </PriceAndButton>
          </>
        )}
      </Footer>
    </Content>
  )
}

DefaultLayout.propTypes = {
  actionType: PropTypes.string.isRequired,
  currentTestName: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  words: PropTypes.array.isRequired,
  currentPrice: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  currentSlide: PropTypes.object.isRequired,
  currentGoodsItem: PropTypes.object.isRequired,
  defaultContent: PropTypes.object.isRequired,
  isDefaultWgs: PropTypes.bool.isRequired,
  mobile: PropTypes.bool.isRequired,
  tablet: PropTypes.bool.isRequired,
  isZeroPrice: PropTypes.bool.isRequired,
}

export default DefaultLayout
