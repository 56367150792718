import PropTypes from 'prop-types'
import React from 'react'

import { RU } from 'constants/countries'
import { COUNTRIES_WITHOUT_DNA, GOODS_TYPES } from 'constants/sales'
import { LocalizationContext } from 'contexts/Localization'
import useInViewCommon from 'hooks/useInViewCommon'
import { BoxBase, Row, Col } from 'styles/components/layout'
import { Text, H1 } from 'styles/components/typography'

import UpsaleCards from './components/UpsaleCards'
import { TitleBox, TextBox } from './styles'

const TakeCare = ({ data }) => {
  const [ref, inView] = useInViewCommon()
  const {
    locale,
    sales: { specialDistribution },
  } = React.useContext(LocalizationContext)

  const productsList = React.useMemo(() => {
    if (locale === RU) {
      return [GOODS_TYPES.dna, GOODS_TYPES.wgs, GOODS_TYPES.biome]
    } else if (COUNTRIES_WITHOUT_DNA.includes(locale)) {
      return [GOODS_TYPES.biome]
    } else {
      return [GOODS_TYPES.dna, GOODS_TYPES.biome]
    }
  }, [locale])

  return (
    <>
      <BoxBase className="pt-6" overflow={'hidden'} ref={ref}>
        <Row>
          <Col width={{ xs: 1 }} className="text-center">
            <TitleBox>
              <H1 as="h2" dangerouslySetInnerHTML={{ __html: data.title }} />
            </TitleBox>
            <br />
            <TextBox>
              <Text dangerouslySetInnerHTML={{ __html: data.description }} />
            </TextBox>
          </Col>
        </Row>
      </BoxBase>
      <UpsaleCards
        isVisible={inView}
        specialDistribution={specialDistribution}
        productsList={productsList}
      />
    </>
  )
}

TakeCare.propTypes = {
  data: PropTypes.object.isRequired,
}

TakeCare.defaultProps = {}

export default TakeCare
